export default {
  buttonContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  hide: {
    display: 'none',
  },
  padding5px: {
    padding: '5px',
  },
  padding5TopBottom: {
    paddingTop: '5px',
    paddingBottom: '5px',
  },
  textAlignRight: {
    textAlign: 'right',
  },
  iconStyling: {
    color: 'rgba(0, 0, 0, .85)',
    fontSize: '15px',
  },
  inputTable: {
    width: '100%',
    '& div.ant-form-item': {
      margin: '0 !important',
    },
    '& div.ant-select-selector': {
      padding: '0 0 0 2px !important',
    },
    '& span.ant-select-selection-item': {
      paddingRight: '0 !important',
    },
    // '& input.ant-input': {
    //   padding: '4px 2px !important',
    // },
    '& div.ant-picker': {
      padding: '4px 2px !important',
    },
    // '& input.ant-input-number-input': {
    //   padding: '4px 2px !important',
    // },
    tableLayout: 'fixed',
    textAlign: 'left',
    borderCollapse: 'separate;',
    borderRadius: '2px 2px 0 0',
    borderSpacing: 0,
  },
  inputThead: {
    overflowWrap: 'break-word',
    textAlign: 'center',
    backgroundColor: '#fafafa',
  },
  inputTh: {
    border: '1px solid #dcdcdc',
    fontWeight: 400,
    backgroundColor: '#ebebeb',
    color: 'rgb(0, 0, 0)',
    padding: '2px',
    borderCollapse: 'collapse',
  },
  inputTd: {
    border: '1px solid #dcdcdc',
  },
  inputTdText: {
    border: '1px solid #dcdcdc',
    paddingLeft: 5,
  },
  breadcrumbStyle: {
    padding: '5px 0',
    background: '#f0f2f5 !important',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  centerHeading: {
    textAlign: 'center',
    fontWeight: 500,
    fontSize: '15px',
  },
  avatar: {},
  '@media (max-width: 767px)': {
    avatar: {
      display: 'none',
    },
  },
  colorRed: {
    color: 'Red !important',
  },
  colorGreen: {
    color: 'Green !important',
  },
};
