import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Dropdown, Avatar } from 'antd';
import { useNavigate } from 'react-router-dom';

import { logout } from '../../redux/actions';
import ChangePassModal from './ChangePassword-Modal';

const AvatarMenu = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const user = useSelector(state => state.loginInfo.user);

  const [ChangePassword, SetChangePassword] = useState(false);

  const showModal = () => {
    SetChangePassword(true);
  };

  const onLogout = () => {
    dispatch(logout());
    navigate('/login');
  };

  const items = [
    {
      key: 'userInfo',
      label: `${user.name} | ${user.role}`,
      disabled: true,
    },
    {
      key: 'changepassword',
      onClick: showModal,
      label: 'Change Password',
    },
    {
      key: 'logout',
      onClick: onLogout,
      label: 'Logout',
    },
  ];

  return (
    <>
      <Dropdown menu={{ items }} trigger={['click']}>
        <Avatar alt="User" size={32}>
          {String(user.name).slice(0, 1)}
        </Avatar>
      </Dropdown>
      {ChangePassword && (
        <ChangePassModal
          ChangePassword={ChangePassword}
          SetChangePassword={SetChangePassword}
        />
      )}
    </>
  );
};

AvatarMenu.propTypes = {};

export default AvatarMenu;
