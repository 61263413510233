import { errorNotification } from '../../components/notifications';
import api from '../../libs/api';

const { createSlice, createAsyncThunk, isAnyOf } = require('@reduxjs/toolkit');

const initialState = {
  materials: {
    list: [],
    page: 1,
    totalRows: 0,
    totalAmount: 0,
    totalPages: 1,
    query: { page: 1 },
  },
  materialsToOrder: {
    list: [],
    page: 1,
    totalRows: 0,
    totalPages: 1,
    query: { page: 1 },
  },
  materialAdjustments: {
    list: [],
    page: 1,
    totalRows: 0,
    totalPages: 1,
    query: { page: 1 },
  },
  materialTransfers: {
    list: [],
    page: 1,
    totalRows: 0,
    totalPages: 1,
    query: { page: 1 },
  },
  products: {
    list: [],
    page: 1,
    totalRows: 0,
    totalAmount: 0,
    totalPages: 1,
    query: { page: 1 },
  },
  productAdjustments: {
    list: [],
    page: 1,
    totalRows: 0,
    totalPages: 1,
    query: { page: 1 },
  },
  productTransfers: {
    list: [],
    page: 1,
    totalRows: 0,
    totalPages: 1,
    query: { page: 1 },
  },
  formattedProducts: {
    products: [],
    info: {},
  },
  materialInventoryForDispense: [],
  materialBatchIds: [],
  productsToOrder: {
    list: [],
    page: 1,
    totalRows: 0,
    totalPages: 1,
    query: { page: 1 },
  },
};

export const getMaterialBatchIds = createAsyncThunk(
  'inventory/getMaterialBatchIds',
  async consignorId => {
    const { data } = await api.get('/inventory/materials/batch/ids', {
      consignorId,
    });

    return data;
  },
);

export const getMaterialInventory = createAsyncThunk(
  'inventory/getMaterialInventory',
  async (query, { getState }) => {
    const currentQuery = getState().inventory.materials.query;

    const effectiveQuery = query || currentQuery;

    const { data } = await api.get('/inventory/materials', effectiveQuery);

    return { data, query: effectiveQuery };
  },
);

export const getMaterialsToOrder = createAsyncThunk(
  'inventory/getMaterialsToOrder',
  async ({ consignorId, query }, { getState }) => {
    const currentQuery = getState().inventory.materialsToOrder.query;

    const effectiveQuery = query || currentQuery;

    const { data } = await api.get(
      `/inventory/materialstoorder/${consignorId}`,
      effectiveQuery,
    );

    return { data, query: effectiveQuery };
  },
);

export const getProductsToOrder = createAsyncThunk(
  'inventory/getProductsToOrder',
  async (query, { getState }) => {
    const currentQuery = getState().inventory.productsToOrder.query;

    const effectiveQuery = query || currentQuery;

    const { data } = await api.get(
      `/inventory/products-to-order/`,
      effectiveQuery,
    );

    return { data, query: effectiveQuery };
  },
);

export const getMaterialInventoryAdjustments = createAsyncThunk(
  'inventory/getMaterialInventoryAdjustments',
  async (query, { getState }) => {
    const currentQuery = getState().inventory.materialAdjustments.query;

    const effectiveQuery = query || currentQuery;

    const { data } = await api.get(
      '/inventory/materials/adjustments',
      effectiveQuery,
    );

    return { data, query: effectiveQuery };
  },
);

export const getMaterialInventoryTransfers = createAsyncThunk(
  'inventory/getMaterialInventoryTransfers',
  async (query, { getState }) => {
    const currentQuery = getState().inventory.materialAdjustments.query;

    const effectiveQuery = query || currentQuery;

    const { data } = await api.get(
      '/inventory/materials/transfers',
      effectiveQuery,
    );

    return { data, query: effectiveQuery };
  },
);

export const getMaterialInventoryForDispense = createAsyncThunk(
  'inventory/getMaterialInventoryForDispense',
  async query => {
    const { data } = await api.get('/inventory/materials/todispense', query);

    return data;
  },
);

export const getProductInventory = createAsyncThunk(
  'inventory/getProductInventory',
  async (query, { getState }) => {
    const currentQuery = getState().inventory.products.query;

    const effectiveQuery = query || currentQuery;

    const { data } = await api.get('/inventory/products', effectiveQuery);

    return { data, query: effectiveQuery };
  },
);

export const getFormattedProductInventory = createAsyncThunk(
  'inventory/getFormattedProductInventory',
  async query => {
    const { data } = await api.get('/inventory/products/formatted', query);

    return { data };
  },
);

export const getProductInventoryAdjustments = createAsyncThunk(
  'inventory/getProductInventoryAdjustments',
  async (query, { getState }) => {
    const currentQuery = getState().inventory.productAdjustments.query;

    const effectiveQuery = query || currentQuery;

    const { data } = await api.get(
      '/inventory/products/adjustments',
      effectiveQuery,
    );

    return { data, query: effectiveQuery };
  },
);

export const getProductInventoryTransfers = createAsyncThunk(
  'inventory/getProductInventoryTransfers',
  async (query, { getState }) => {
    const currentQuery = getState().inventory.productTransfers.query;

    const effectiveQuery = query || currentQuery;

    const { data } = await api.get(
      '/inventory/products/transfers',
      effectiveQuery,
    );

    return { data, query: effectiveQuery };
  },
);

const inventory = createSlice({
  name: 'inventory',

  initialState,

  reducers: {
    clearMaterialInventory: draft => {
      draft.materials = initialState.materials;
    },

    clearMaterialInventoryAdjustments: draft => {
      draft.materialAdjustments = initialState.materialAdjustments;
    },

    clearMaterialInventoryTransfers: draft => {
      draft.materialTransfers = initialState.materialTransfers;
    },

    clearProductInventory: draft => {
      draft.products = initialState.products;
    },

    clearProductInventoryAdjustments: draft => {
      draft.productAdjustments = initialState.productAdjustments;
    },

    clearProductInventoryTransfers: draft => {
      draft.productTransfers = initialState.productTransfers;
    },

    clearFormattedProductInventory: draft => {
      draft.formattedProducts = initialState.formattedProducts;
    },

    clearMaterialInventoryForDispense: draft => {
      draft.materialInventoryForDispense =
        initialState.materialInventoryForDispense;
    },

    clearMaterialsToOrder: draft => {
      draft.materialsToOrder = initialState.materialsToOrder;
    },

    clearMaterialBatchIds: draft => {
      draft.materialBatchIds = initialState.materialBatchIds;
    },

    clearProductsToOrder: draft => {
      draft.productsToOrder = initialState.productsToOrder;
    },
  },

  extraReducers: builder => {
    builder.addCase(getMaterialBatchIds.fulfilled, (draft, { payload }) => {
      draft.materialBatchIds = payload;
    });

    builder.addCase(getMaterialInventory.fulfilled, (draft, { payload }) => {
      draft.materials.list = payload.data.inventory;
      draft.materials.page = payload.data.page;
      draft.materials.totalRows = payload.data.totalRows;
      draft.materials.totalAmount = payload.data.totalAmount;
      draft.materials.totalPages = payload.data.totalPages;
      draft.materials.query = payload.query;
    });

    builder.addCase(getMaterialsToOrder.fulfilled, (draft, { payload }) => {
      draft.materialsToOrder.list = payload.data.materialsToOrder;
      draft.materialsToOrder.page = payload.data.page;
      draft.materialsToOrder.totalRows = payload.data.totalRows;
      draft.materialsToOrder.totalPages = payload.data.totalPages;
      draft.materialsToOrder.query = payload.query;
    });

    builder.addCase(getProductsToOrder.fulfilled, (draft, { payload }) => {
      draft.productsToOrder.list = payload.data.products;
      draft.productsToOrder.page = payload.data.page;
      draft.productsToOrder.totalRows = payload.data.totalRows;
      draft.productsToOrder.totalPages = payload.data.totalPages;
      draft.productsToOrder.query = payload.query;
    });

    builder.addCase(
      getMaterialInventoryAdjustments.fulfilled,
      (draft, { payload }) => {
        draft.materialAdjustments.list = payload.data.materialAdjustments;
        draft.materialAdjustments.page = payload.data.page;
        draft.materialAdjustments.totalRows = payload.data.totalRows;
        draft.materialAdjustments.totalPages = payload.data.totalPages;
        draft.materialAdjustments.query = payload.query;
      },
    );

    builder.addCase(
      getMaterialInventoryTransfers.fulfilled,
      (draft, { payload }) => {
        draft.materialTransfers.list = payload.data.materialTransfers;
        draft.materialTransfers.page = payload.data.page;
        draft.materialTransfers.totalRows = payload.data.totalRows;
        draft.materialTransfers.totalPages = payload.data.totalPages;
        draft.materialTransfers.query = payload.query;
      },
    );

    builder.addCase(getProductInventory.fulfilled, (draft, { payload }) => {
      draft.products.list = payload.data.inventory;
      draft.products.page = payload.data.page;
      draft.products.totalRows = payload.data.totalRows;
      draft.products.totalAmount = payload.data.totalAmount;
      draft.products.totalPages = payload.data.totalPages;
      draft.products.query = payload.query;
    });

    builder.addCase(
      getProductInventoryAdjustments.fulfilled,
      (draft, { payload }) => {
        draft.productAdjustments.list = payload.data.productAdjustments;
        draft.productAdjustments.page = payload.data.page;
        draft.productAdjustments.totalRows = payload.data.totalRows;
        draft.productAdjustments.totalPages = payload.data.totalPages;
        draft.productAdjustments.query = payload.query;
      },
    );

    builder.addCase(
      getProductInventoryTransfers.fulfilled,
      (draft, { payload }) => {
        draft.productTransfers.list = payload.data.productTransfers;
        draft.productTransfers.page = payload.data.page;
        draft.productTransfers.totalRows = payload.data.totalRows;
        draft.productTransfers.totalPages = payload.data.totalPages;
        draft.productTransfers.query = payload.query;
      },
    );

    builder.addCase(
      getFormattedProductInventory.fulfilled,
      (draft, { payload }) => {
        draft.formattedProducts = payload.data;
      },
    );

    builder.addCase(
      getMaterialInventoryForDispense.fulfilled,
      (draft, { payload }) => {
        draft.materialInventoryForDispense = payload;
      },
    );

    builder.addMatcher(
      isAnyOf(
        getMaterialInventory.rejected,
        getMaterialsToOrder.rejected,
        getMaterialInventoryAdjustments.rejected,
        getMaterialInventoryTransfers.rejected,
        getProductInventory.rejected,
        getProductInventoryAdjustments.rejected,
        getProductInventoryTransfers.rejected,
        getFormattedProductInventory.rejected,
        getMaterialInventoryForDispense.rejected,
        getMaterialBatchIds.rejected,
        getProductsToOrder.rejected,
      ),
      (_draft, { error }) => {
        errorNotification(error);
      },
    );
  },
});

export const {
  clearMaterialInventory,
  clearMaterialInventoryAdjustments,
  clearMaterialInventoryTransfers,
  clearProductInventory,
  clearProductInventoryAdjustments,
  clearProductInventoryTransfers,
  clearFormattedProductInventory,
  clearMaterialInventoryForDispense,
  clearMaterialsToOrder,
  clearMaterialBatchIds,
  clearProductsToOrder,
} = inventory.actions;

export default inventory.reducer;
