import { createAsyncThunk, createSlice, isAnyOf } from '@reduxjs/toolkit';

import { errorNotification } from '../../components/notifications';
import api from '../../libs/api';

const initialState = {
  rfqs: {
    list: [],
    page: 1,
    query: { page: 1 },
    totalPages: 1,
    totalRfqs: 0,
  },
  rfq: { materials: [], parties: [] },
};

export const getRfqs = createAsyncThunk(
  'rfqs/getRfqs',
  async (query, { getState }) => {
    const effectiveQuery = query || getState().rfqs.rfqs.query;

    const { data } = await api.get('/rfqs', effectiveQuery);

    return { data, query: effectiveQuery };
  },
);

export const getRfq = createAsyncThunk('rfqs/getRfq', async id => {
  const { data } = await api.get(`/rfqs/${id}`);

  return data;
});

const rfqs = createSlice({
  name: 'rfqs',

  initialState,

  reducers: {
    clearRfqs: draft => {
      draft.rfqs = initialState.rfqs;
    },

    clearRfq: draft => {
      draft.rfq = initialState.rfq;
    },
  },

  extraReducers: builder => {
    builder.addCase(getRfqs.fulfilled, (draft, { payload }) => {
      draft.rfqs.list = payload.data.rfqs;
      draft.rfqs.page = payload.data.page;
      draft.rfqs.query = payload.query;
      draft.rfqs.totalRfqs = payload.data.totalRfqs;
      draft.rfqs.totalPages = payload.data.totalPages;
    });

    builder.addCase(getRfq.fulfilled, (draft, { payload }) => {
      draft.rfq = payload;
    });

    builder.addMatcher(
      isAnyOf(getRfqs.rejected, getRfq.rejected),
      (_Draft, { error }) => {
        errorNotification(error);
      },
    );
  },
});

export const { clearRfqs, clearRfq } = rfqs.actions;

export default rfqs.reducer;
