import { createAsyncThunk, createSlice, isAnyOf } from '@reduxjs/toolkit';

import { errorNotification } from '../../components/notifications';
import api from '../../libs/api';
import socket from '../../libs/socket';

// Initial state redux.loginInfo
const initialState = {
  user: null,
  permissions: {},
  permissionsArray: [],
};

// Async thunk Actions
export const login = createAsyncThunk(
  'loginInfo/login',
  async ({ email, password } = {}) => {
    const { data } = await api.post('/auth/login', { email, password });

    socket.emit('join_rooms', { token: data.token });

    return data;
  },
);

export const setAccountPassword = createAsyncThunk(
  'loginInfo/setAccountPassword',
  async ({ email, otp, password, repeatPassword } = {}) => {
    await api.post('/auth/set', { email, otp, password, repeatPassword });

    const { data } = await api.post('/auth/login', { email, password });

    return data;
  },
);

export const changePassword = createAsyncThunk(
  'loginInfo/changePassword',
  async ({ password, newPassword, repeatPassword } = {}) => {
    const { data } = await api.post('/auth/change', {
      password,
      newPassword,
      repeatPassword,
    });

    return data;
  },
);

// Redux Toolkit Slice (actions + reducers)
const loginInfo = createSlice({
  name: 'loginInfo',
  initialState,

  reducers: {
    logout: draft => {
      localStorage.setItem('token', '');

      draft.user = initialState.user;
      draft.permissions = initialState.permissions;
      draft.permissionsArray = initialState.permissionsArray;
    },

    updateRolePermissions: (draft, { payload }) => {
      const { role, permissions } = payload.role;

      draft.user.role = role;
      draft.permissions = permissions;

      const permissionsArray = [];

      for (const permission of Object.keys(permissions)) {
        if (
          permissions[permission] === true ||
          permissions[permission].length
        ) {
          permissionsArray.push(permission);
        }
      }

      draft.permissionsArray = permissionsArray;
    },

    updateLoginInfo: (draft, { payload }) => {
      draft.user = payload.user;
      draft.permissions = payload.permissions;

      const permissionsArray = [];

      for (const permission of Object.keys(payload.permissions)) {
        if (
          payload.permissions[permission] === true ||
          payload.permissions[permission].length
        ) {
          permissionsArray.push(permission);
        }
      }

      draft.permissionsArray = permissionsArray;
    },
  },

  extraReducers: builder => {
    builder.addMatcher(
      isAnyOf(
        login.fulfilled,
        setAccountPassword.fulfilled,
        changePassword.fulfilled,
      ),
      (draft, { payload }) => {
        localStorage.setItem('token', payload.token);

        draft.user = payload.user;
        draft.permissions = payload.permissions;

        const permissionsArray = [];

        for (const permission of Object.keys(payload.permissions)) {
          if (
            payload.permissions[permission] === true ||
            payload.permissions[permission].length
          ) {
            permissionsArray.push(permission);
          }
        }

        draft.permissionsArray = permissionsArray;
      },
    );

    builder.addMatcher(
      isAnyOf(
        login.rejected,
        setAccountPassword.rejected,
        changePassword.rejected,
      ),
      (_Proxy, { error }) => {
        errorNotification(error);
      },
    );
  },
});

export const { logout, updateRolePermissions, updateLoginInfo } =
  loginInfo.actions;

export default loginInfo.reducer;
