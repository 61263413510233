import { createAsyncThunk, createSlice, isAnyOf } from '@reduxjs/toolkit';

import { errorNotification } from '../../../components/notifications';
import api from '../../../libs/api';

const initialState = {
  hsns: [],
  hsn: {},
  hsnIds: [],
};

export const getHsns = createAsyncThunk('dropdowns/hsns/getHsns', async () => {
  const { data } = await api.get('/dropdowns/hsns');

  return data;
});

export const getHsn = createAsyncThunk('dropdowns/hsns/getHsn', async id => {
  const { data } = await api.get(`/dropdowns/hsns/${id}`);

  return data;
});

export const deleteHsn = createAsyncThunk(
  'dropdowns/hsns/deleteHsn',
  async id => {
    await api.delete(`/dropdowns/hsns/${id}`);

    return id;
  },
);

export const addHsn = createAsyncThunk('dropdowns/hsns/addHsn', async data => {
  const { data: hsn } = await api.post('/dropdowns/hsns', data);

  return hsn;
});

export const editHsn = createAsyncThunk(
  'dropdowns/hsns/editHsn',
  async ({ id, data }) => {
    const { data: hsn } = await api.put(`/dropdowns/hsns/${id}`, data);

    return hsn;
  },
);

export const getHsnIds = createAsyncThunk(
  'dropdowns/hsns/getHsnIds',
  async () => {
    const { data } = await api.get('/dropdowns/hsns/ids');

    return data;
  },
);

const hsns = createSlice({
  name: 'hsns',

  initialState,

  reducers: {
    clearHsns: draft => {
      draft.hsns = initialState.hsns;
    },

    clearHsn: draft => {
      draft.hsn = initialState.hsn;
    },

    clearHsnIds: draft => {
      draft.hsnIds = initialState.hsnIds;
    },
  },

  extraReducers: builder => {
    builder.addCase(getHsns.fulfilled, (draft, { payload }) => {
      draft.hsns = payload;
    });

    builder.addCase(getHsn.fulfilled, (draft, { payload }) => {
      draft.hsn = payload;
    });

    builder.addCase(deleteHsn.fulfilled, (draft, { payload }) => {
      draft.hsns = draft.hsns.filter(hsn => hsn.id !== payload);
    });

    builder.addCase(addHsn.fulfilled, (draft, { payload }) => {
      draft.hsns.push(payload);
    });

    builder.addCase(editHsn.fulfilled, (draft, { payload }) => {
      const index = draft.hsns.findIndex(el => el.id === payload.id);

      draft.hsns[index] = payload;
    });

    builder.addCase(getHsnIds.fulfilled, (draft, { payload }) => {
      draft.hsnIds = payload;
    });

    builder.addMatcher(
      isAnyOf(
        getHsns.rejected,
        getHsn.rejected,
        deleteHsn.rejected,
        addHsn.rejected,
        editHsn.rejected,
        getHsnIds.rejected,
      ),
      (_Proxy, { error }) => {
        errorNotification(error);
      },
    );
  },
});

export const { clearHsn, clearHsns, clearHsnIds } = hsns.actions;

export default hsns.reducer;
