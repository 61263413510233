import { createAsyncThunk, createSlice, isAnyOf } from '@reduxjs/toolkit';

import { errorNotification } from '../../components/notifications';
import api from '../../libs/api';

const initialState = {
  sales: {
    list: [],
    page: 1,
    query: { page: 1 },
    totalPages: 1,
    totalSales: 0,
    totalWithoutTax: 0,
    total: 0,
    totalTax: 0,
  },
  sale: { charges: [], materials: [], products: [] },
  saleIds: [],
  files: [],
  reports: {
    saleFigures: { total: 0, parties: [] },
    yearlySaleFigures: { total: 0, parties: [] },
    mis: { users: [] },
    salesWithoutTax: [],
  },
};

export const getSalesWithoutTax = createAsyncThunk(
  'sales/getSalesWithoutTax',
  async query => {
    const { data } = await api.get(`/sales/reports/invoiceswithouttax`, query);

    return data;
  },
);

export const getSaleFigures = createAsyncThunk(
  'sales/getSaleFigures',
  async query => {
    const { data } = await api.get(`/sales/reports/figures`, query);

    return data;
  },
);

export const getSalesMis = createAsyncThunk(
  'sales/getSalesMis',
  async query => {
    const { data } = await api.get(`/sales/reports/mis`, query);

    return data;
  },
);

export const getYearlySaleFigures = createAsyncThunk(
  'sales/getYearlySaleFigures',
  async query => {
    const { data } = await api.get(`/sales/reports/figures/yearly`, query);

    return data;
  },
);

export const getSales = createAsyncThunk(
  'sales/getSales',
  async (query, { getState }) => {
    const currentQuery = getState().sales.sales.query;

    const effectiveQuery = query || currentQuery;

    const { data } = await api.get('/sales', effectiveQuery);

    return { data, query: effectiveQuery };
  },
);

export const getSale = createAsyncThunk('sales/getSale', async id => {
  const { data } = await api.get(`/sales/${id}`);

  return data;
});

export const deleteSale = createAsyncThunk(
  'sales/deleteSale',
  async (id, { getState }) => {
    const userName = getState().loginInfo.user.name;

    await api.delete(`/sales/${id}`);

    return { id, userName };
  },
);

export const getSaleIds = createAsyncThunk('sales/getSaleIds', async () => {
  const { data } = await api.get('/sales/ids');

  return data;
});

export const getSaleFiles = createAsyncThunk(
  'sales/getSaleFiles',
  async saleId => {
    const { data } = await api.get(`/sales/files/${saleId}`);

    return data;
  },
);

export const deleteSaleFile = createAsyncThunk(
  'sales/deleteSaleFiles',
  async ({ fileId, saleId }) => {
    await api.delete(`/sales/files/${fileId}`);

    return { fileId, saleId };
  },
);

export const addSaleFiles = createAsyncThunk(
  'sales/addSaleFiles',
  async ({ saleId, files, type }) => {
    if (!files || !files.length) {
      throw new Error('Please select files first');
    }

    const formData = new FormData();

    formData.append('type', type);

    files.forEach((file, index) => {
      formData.append(`files[${index}]`, file);
    });

    const { data } = await api.post(`/sales/files/${saleId}`, formData, {
      headers: {
        'content-type': 'multipart/form-data',
      },
    });

    return { data, saleId };
  },
);

const sales = createSlice({
  name: 'sales',

  initialState,

  reducers: {
    clearSales: draft => {
      draft.sales = initialState.sales;
    },

    clearSale: draft => {
      draft.sale = initialState.sale;
    },

    clearSaleIds: draft => {
      draft.saleIds = initialState.saleIds;
    },

    clearSaleFiles: draft => {
      draft.files = initialState.files;
    },

    clearSalesWithoutTax: draft => {
      draft.reports.salesWithoutTax = initialState.reports.salesWithoutTax;
    },

    clearSaleFigures: draft => {
      draft.reports.saleFigures = initialState.reports.saleFigures;
    },

    clearYearlySaleFigures: draft => {
      draft.reports.yearlySaleFigures = initialState.reports.yearlySaleFigures;
    },

    clearSalesMis: draft => {
      draft.reports.mis = initialState.reports.mis;
    },
  },

  extraReducers: builder => {
    builder.addCase(getSales.fulfilled, (draft, { payload }) => {
      draft.sales.list = payload.data.sales;
      draft.sales.page = payload.data.page;
      draft.sales.query = payload.query;
      draft.sales.totalSales = payload.data.totalSales;
      draft.sales.totalPages = payload.data.totalPages;
      draft.sales.totalWithoutTax = payload.data.totalWithoutTax;
      draft.sales.total = payload.data.total;
      draft.sales.totalTax = payload.data.totalTax;
    });

    builder.addCase(getSale.fulfilled, (draft, { payload }) => {
      draft.sale = payload;
    });

    builder.addCase(deleteSale.fulfilled, (draft, { payload }) => {
      const sale = draft.sales.list.find(el => el.id === payload.id);

      if (sale) {
        sale.deletedAt = new Date().toISOString();
        sale.deletedBy = payload.userName;
      }
    });

    builder.addCase(getSaleIds.fulfilled, (draft, { payload }) => {
      draft.saleIds = payload;
    });

    builder.addCase(getSaleFiles.fulfilled, (draft, { payload }) => {
      draft.files = payload;
    });

    builder.addCase(deleteSaleFile.fulfilled, (draft, { payload }) => {
      draft.files = draft.files.filter(file => file.id !== payload.fileId);

      const sale = draft.sales.list.find(el => el.id === payload.saleId);

      if (sale) {
        sale.noOfFiles = +sale.noOfFiles - 1;
      }
    });

    builder.addCase(addSaleFiles.fulfilled, (draft, { payload }) => {
      draft.files.push(...payload.data.uploaded);

      const sale = draft.sales.list.find(el => el.id === payload.saleId);

      if (sale) {
        sale.noOfFiles = +sale.noOfFiles + payload.data.uploaded.length;
      }

      if (payload.data.failed.length) {
        errorNotification(
          new Error(`${payload.data.failed.join(', ')} failed to upload`),
        );
      }
    });

    builder.addCase(getSalesWithoutTax.fulfilled, (draft, { payload }) => {
      draft.reports.salesWithoutTax = payload;
    });

    builder.addCase(getSaleFigures.fulfilled, (draft, { payload }) => {
      draft.reports.saleFigures = payload;
    });

    builder.addCase(getYearlySaleFigures.fulfilled, (draft, { payload }) => {
      draft.reports.yearlySaleFigures = payload;
    });

    builder.addCase(getSalesMis.fulfilled, (draft, { payload }) => {
      draft.reports.mis = payload;
    });

    builder.addMatcher(
      isAnyOf(
        getSales.rejected,
        getSale.rejected,
        deleteSale.rejected,
        getSaleIds.rejected,
        getSaleFiles.rejected,
        deleteSaleFile.rejected,
        addSaleFiles.rejected,
        getSalesWithoutTax.rejected,
        getSaleFigures.rejected,
        getSalesMis.rejected,
      ),
      (_Draft, { error }) => {
        errorNotification(error);
      },
    );
  },
});

export const {
  clearSales,
  clearSale,
  clearSaleIds,
  clearSaleFiles,
  clearSalesWithoutTax,
  clearSaleFigures,
  clearYearlySaleFigures,
  clearSalesMis,
} = sales.actions;

export default sales.reducer;
