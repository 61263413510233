import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { Input, Modal, Button } from 'antd';
import { useDispatch } from 'react-redux';
import { useForm, Controller } from 'react-hook-form';

import GetField from '../common/GetField';
import { changePassword } from '../../redux/actions';
import { successNotification } from '../notifications';

const AntPassword = GetField(Input.Password);

const ChangePassModal = props => {
  const { ChangePassword, SetChangePassword } = props;
  const dispatch = useDispatch();

  const { control, handleSubmit } = useForm();

  const [ConfirmLoading, SetConfirmLoading] = useState(false);

  const hideModal = useCallback(() => {
    SetChangePassword(false);
  }, []);

  const onSubmit = useCallback(async formValues => {
    try {
      SetConfirmLoading(true);

      const { password, newPassword, repeatPassword } = formValues;

      await dispatch(
        changePassword({ password, newPassword, repeatPassword }),
      ).unwrap();

      successNotification('Password changed');
      SetConfirmLoading(false);
      hideModal();
    } finally {
      SetConfirmLoading(false);
    }
  }, []);

  return (
    <Modal
      open={ChangePassword}
      onCancel={hideModal}
      centered
      title="Change password"
      confirmLoading={ConfirmLoading}
      maskClosable={false}
      footer={false}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <Controller
          name="password"
          control={control}
          render={({ field }) => (
            <AntPassword
              required
              allowClear
              placeholder="Current Password"
              {...field}
            />
          )}
        />
        <Controller
          name="newPassword"
          control={control}
          render={({ field }) => (
            <AntPassword
              required
              allowClear
              placeholder="New Password"
              {...field}
            />
          )}
        />
        <Controller
          name="repeatPassword"
          control={control}
          render={({ field }) => (
            <AntPassword
              required
              allowClear
              placeholder="Repeat Password"
              {...field}
            />
          )}
        />
        <Button type="primary" htmlType="submit">
          Submit
        </Button>
      </form>
    </Modal>
  );
};

ChangePassModal.propTypes = {
  ChangePassword: PropTypes.bool.isRequired,
  SetChangePassword: PropTypes.func.isRequired,
};

export default ChangePassModal;
