import React, { lazy } from 'react';
import { Route, Routes } from 'react-router-dom';

import Structure from './structure/Structure';
import EditOrder from './orders/Edit-Order';

const Null = lazy(() => import('./Null'));
const Home = lazy(() => import('./home/Home'));

const Users = lazy(() => import('./users/Users'));
const Roles = lazy(() => import('./users/Roles/Roles'));
const AddRole = lazy(() => import('./users/Roles/Add-Role'));
const EditRole = lazy(() => import('./users/Roles/Edit-Role'));
const AddUser = lazy(() => import('./users/Add-User'));
const EditUser = lazy(() => import('./users/Edit-User'));

const Hsns = lazy(() => import('./dropdowns/hsns/Hsns'));
const Units = lazy(() => import('./dropdowns/units/Units'));
const Consignors = lazy(() => import('./dropdowns/consignors/Consignors'));
const Charges = lazy(() => import('./dropdowns/charges/Charges'));
const Packings = lazy(() => import('./dropdowns/packing/Packings'));
const LeadOrigins = lazy(() => import('./crm/origins/Origins'));

const Parties = lazy(() => import('./parties/Parties'));
const AddParty = lazy(() => import('./parties/Add-Party'));
const EditParty = lazy(() => import('./parties/Edit-Party'));
const ViewParty = lazy(() => import('./parties/View-Party'));
const ViewPartyLedger = lazy(() => import('./parties/Party-Ledger'));

const Materials = lazy(() => import('./materials/Materials'));
const AddMaterial = lazy(() => import('./materials/Add-Material'));
const EditMaterial = lazy(() => import('./materials/Edit-Material'));

const Products = lazy(() => import('./products/Products'));
const AddProduct = lazy(() => import('./products/Add-Product'));
const EditProduct = lazy(() => import('./products/Edit-Product'));
const ViewProduct = lazy(() => import('./products/View-Product'));

const ProductCosting = lazy(() => import('./costing/View'));

const SOPs = lazy(() => import('./sops/SOPs'));

const Machines = lazy(() => import('./machines/Machines'));
const AddMachine = lazy(() => import('./machines/Add-Machine'));
const EditMachine = lazy(() => import('./machines/Edit-Machine'));

const MaterialInventory = lazy(() => import('./inventory/Material-Inventory'));
const MaterialsToOrder = lazy(() => import('./inventory/Materials-To-Order'));
const ProductsToOrder = lazy(() => import('./inventory/Products-To-Order'));
const ProductInventory = lazy(() => import('./inventory/Product-Inventory'));
const MaterialStockAdjustments = lazy(() =>
  import('./inventory/Material-Stock-Adjustments'),
);
const MaterialStockAdjustForm = lazy(() =>
  import('./inventory/Material-Stock-Adjust-Form'),
);
const MaterialStockTransfers = lazy(() =>
  import('./inventory/Material-Stock-Transfers'),
);
const MaterialStockTransferForm = lazy(() =>
  import('./inventory/Material-Stock-Transfer-Form'),
);
const ProductStockAdjustments = lazy(() =>
  import('./inventory/Product-Stock-Adjustments'),
);
const ProductStockAdjustForm = lazy(() =>
  import('./inventory/Product-Stock-Adjust-Form'),
);
const ProductStockTransfers = lazy(() =>
  import('./inventory/Product-Stock-Transfers'),
);
const ProductStockTransferForm = lazy(() =>
  import('./inventory/Product-Stock-Transfer-Form'),
);

const PurchaseOrders = lazy(() => import('./purchase-orders/Purchase-Orders'));
const AddPurchaseOrder = lazy(() =>
  import('./purchase-orders/Add-Purchase-Order'),
);
const EditPurchaseOrder = lazy(() =>
  import('./purchase-orders/Edit-Purchase-Order'),
);
const ViewPurchaseOrder = lazy(() =>
  import('./purchase-orders/View-Purchase-Order'),
);
const POCalendar = lazy(() => import('./purchase-orders/Calendar'));

const Purchases = lazy(() => import('./purchases/Purchases'));
const AddPurchase = lazy(() => import('./purchases/Add-Purchase'));
const EditPurchase = lazy(() => import('./purchases/Edit-Purchase'));
const ViewPurchase = lazy(() => import('./purchases/View-Purchase'));
const UpdatePurchaseQC = lazy(() => import('./purchases/Update-Purchase-QC'));

const ProformaInvoices = lazy(() =>
  import('./proforma-invoices/Proforma-Invoices'),
);
const AddProformaInvoice = lazy(() =>
  import('./proforma-invoices/Add-Proforma-Invoice'),
);
const EditProformaInvoice = lazy(() =>
  import('./proforma-invoices/Edit-Proforma-Invoice'),
);
const ViewProformaInvoice = lazy(() =>
  import('./proforma-invoices/View-Proforma-Invoice'),
);

const Orders = lazy(() => import('./orders/Orders'));
const Production = lazy(() => import('./production/Production'));
const AddOrder = lazy(() => import('./orders/Add-Order'));
// const EditOrder = lazy(() => import('./orders/Edit-Order'));
const ViewOrder = lazy(() => import('./orders/View-Order'));

const PreSales = lazy(() => import('./pre-sales/Pre-Sales'));
const AddPreSale = lazy(() => import('./pre-sales/Add-Pre-Sale'));
const EditPreSale = lazy(() => import('./pre-sales/Edit-Pre-Sale'));
const ViewPreSale = lazy(() => import('./pre-sales/View-Pre-Sale'));

const Sales = lazy(() => import('./sales/Sales'));
const AddSale = lazy(() => import('./sales/Add-Sale'));
const EditSale = lazy(() => import('./sales/Edit-Sale'));
const ViewSale = lazy(() => import('./sales/View-Sale'));

const CreditDebitNotes = lazy(() => import('./cdns/CreditDebitNotes'));
const AddCreditDebitNote = lazy(() => import('./cdns/Add-CreditDebitNote'));
const EditCreditDebitNote = lazy(() => import('./cdns/Edit-CreditDebitNote'));
const ViewCreditDebitNote = lazy(() => import('./cdns/View-CreditDebitNote'));

// const Leads = lazy(() => import('./temp-lead/List'));

const NotPunchedOrders = lazy(() => import('./dashboard/NotPunchedOrders'));

const Rfqs = lazy(() => import('./rfqs/Rfqs'));
const Rfq = lazy(() => import('./rfqs/Rfq'));

const Banks = lazy(() => import('./accounts/banks/Banks'));
const Assets = lazy(() => import('./accounts/assets/Assets'));
const AssetAdjusts = lazy(() => import('./accounts/assets/Adjusts'));
const Narrations = lazy(() => import('./accounts/narrations/Narrations'));
const Contacts = lazy(() => import('./accounts/contacts/Contacts'));
const Transactions = lazy(() => import('./accounts/transactions/Transactions'));
const ViewTransaction = lazy(() =>
  import('./accounts/transactions/View-Transaction'),
);
const Bills = lazy(() => import('./accounts/bills/Bills'));
const AddBill = lazy(() => import('./accounts/bills/Add-Bill'));
const EditBill = lazy(() => import('./accounts/bills/Edit-Bill'));
const ViewBill = lazy(() => import('./accounts/bills/View-Bill'));

const PIUserWiseOrders = lazy(() =>
  import('./reports/user-wise-orders/User-Wise-Orders'),
);
const PurchasesWithoutTax = lazy(() =>
  import('./reports/Purchases-Without-Tax'),
);
const SalesWithoutTax = lazy(() => import('./reports/Sales-Without-Tax'));
const SaleFigures = lazy(() => import('./reports/Sale-Figures'));
const SalesMIS = lazy(() => import('./reports/Sales-MIS'));
const YearlySaleFigures = lazy(() => import('./reports/Yearly-Sale-Figures'));
const YearlyPIFigures = lazy(() => import('./reports/Yearly-PI-Figures'));
const YearlyPurchaseFigures = lazy(() =>
  import('./reports/Yearly-Purchase-Figures'),
);
const YearlySaleFiguresChart = lazy(() =>
  import('./reports/Yearly-Sale-Figures-Chart'),
);
const LastSellingProductGP = lazy(() =>
  import('./reports/Last-Selling-Product-GP'),
);
const CompositionGP = lazy(() => import('./reports/Composition-GP'));
const OnTimeDelivery = lazy(() => import('./reports/On-Time-Delivery'));
const OnTimeReceived = lazy(() => import('./reports/On-Time-Received'));

const SaleOrders = lazy(() => import('./sale-orders/Sale-Orders'));
const AddSaleOrder = lazy(() => import('./sale-orders/Add-Sale-Order'));
const EditSaleOrder = lazy(() => import('./sale-orders/Edit-Sale-Order'));
const ViewSaleOrder = lazy(() => import('./sale-orders/View-Sale-Order'));

const PackingPending = lazy(() => import('./dispatch/Packing-Pending'));

const Checklists = lazy(() => import('./tasks/checklist/Checklist'));
const TaskCalendar = lazy(() => import('./tasks/Calendar'));
const OrderFMS = lazy(() => import('./reports/Order-FMS'));
const OrderMIS = lazy(() => import('./reports/Order-MIS'));

const FinancialSummary = lazy(() => import('./financials/Financial-Summary'));
const BalanceSheet = lazy(() => import('./financials/Balance-Sheet'));

const Leads = lazy(() => import('./crm/leads/Leads'));
const AddLead = lazy(() => import('./crm/leads/Add-Lead'));
const EditLead = lazy(() => import('./crm/leads/Edit-Lead'));
const ViewLead = lazy(() => import('./crm/leads/View-Lead'));
const EnquiryFMS = lazy(() => import('./crm/Enquiry-FMS'));

const InteractionCalendar = lazy(() => import('./crm/interactions/Calendar'));

const Quotations = lazy(() => import('./crm/quotations/Quotations'));
const AddQuotation = lazy(() => import('./crm/quotations/Add-Quotation'));
const EditQuotation = lazy(() => import('./crm/quotations/Edit-Quotation'));
const ViewQuotation = lazy(() => import('./crm/quotations/View-Quotation'));

const HelpTickets = lazy(() => import('./help-tickets/Help-Tickets'));
const AddHelpTicket = lazy(() => import('./help-tickets/Add-Help-Ticket'));
const EditHelpTicket = lazy(() => import('./help-tickets/Edit-Help-Ticket'));
const ViewHelpTicket = lazy(() => import('./help-tickets/View-Help-Ticket'));

const Router = () => (
  <Structure>
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/users" element={<Users />} />
      <Route path="/users/roles" element={<Roles />} />
      <Route path="/users/roles/add" element={<AddRole />} />
      <Route path="/users/roles/edit/:id" element={<EditRole />} />
      <Route path="/users/add" element={<AddUser />} />
      <Route path="/users/edit/:id" element={<EditUser />} />

      <Route path="/dropdowns/hsns" element={<Hsns />} />
      <Route path="/dropdowns/units" element={<Units />} />
      <Route path="/dropdowns/consignors" element={<Consignors />} />
      <Route path="/dropdowns/charges" element={<Charges />} />
      <Route path="/dropdowns/packings" element={<Packings />} />
      <Route path="/dropdowns/origins" element={<LeadOrigins />} />

      <Route path="/parties" element={<Parties />} />
      <Route path="/parties/add" element={<AddParty />} />
      <Route path="/parties/edit/:id" element={<EditParty />} />
      <Route path="/parties/view/:id" element={<ViewParty />} />
      <Route path="/parties/ledger" element={<ViewPartyLedger />} />

      <Route path="/materials" element={<Materials />} />
      <Route path="/materials/add" element={<AddMaterial />} />
      <Route path="/materials/edit/:id" element={<EditMaterial />} />

      <Route path="/products" element={<Products />} />
      <Route path="/products/add" element={<AddProduct />} />
      <Route path="/products/edit/:id" element={<EditProduct />} />
      <Route path="/products/view/:id" element={<ViewProduct />} />

      <Route path="/products/costing" element={<ProductCosting />} />

      <Route path="/sops" element={<SOPs />} />

      <Route path="/machines" element={<Machines />} />
      <Route path="/machines/add" element={<AddMachine />} />
      <Route path="/machines/edit/:id" element={<EditMachine />} />

      <Route path="/inventory/materials" element={<MaterialInventory />} />
      <Route
        path="/inventory/materialstoorder"
        element={<MaterialsToOrder />}
      />
      <Route
        path="/inventory/products-to-order"
        element={<ProductsToOrder />}
      />
      <Route path="/inventory/products" element={<ProductInventory />} />
      <Route
        path="/inventory/materials/adjustments"
        element={<MaterialStockAdjustments />}
      />
      <Route
        path="/inventory/materials/adjustment-form"
        element={<MaterialStockAdjustForm />}
      />
      <Route
        path="/inventory/materials/transfers"
        element={<MaterialStockTransfers />}
      />
      <Route
        path="/inventory/materials/transfer-form"
        element={<MaterialStockTransferForm />}
      />
      <Route
        path="/inventory/products/adjustments"
        element={<ProductStockAdjustments />}
      />
      <Route
        path="/inventory/products/adjustment-form"
        element={<ProductStockAdjustForm />}
      />
      <Route
        path="/inventory/products/transfers"
        element={<ProductStockTransfers />}
      />
      <Route
        path="/inventory/products/transfer-form"
        element={<ProductStockTransferForm />}
      />

      <Route path="/rfqs" element={<Rfqs />} />
      <Route path="/rfqs/view/:id" element={<Rfq />} />

      <Route path="/purchase-orders" element={<PurchaseOrders />} />
      <Route path="/purchase-orders/add" element={<AddPurchaseOrder />} />
      <Route path="/purchase-orders/edit/:id" element={<EditPurchaseOrder />} />
      <Route path="/purchase-orders/view/:id" element={<ViewPurchaseOrder />} />
      <Route path="/purchase-orders/calendar" element={<POCalendar />} />

      <Route path="/purchases" element={<Purchases />} />
      <Route path="/purchases/add" element={<AddPurchase />} />
      <Route path="/purchases/edit/:id" element={<EditPurchase />} />
      <Route path="/purchases/view/:id" element={<ViewPurchase />} />
      <Route path="/purchases/qc/:id" element={<UpdatePurchaseQC />} />

      <Route path="/proforma-invoices" element={<ProformaInvoices />} />
      <Route path="/proforma-invoices/add" element={<AddProformaInvoice />} />
      <Route
        path="/proforma-invoices/edit/:id"
        element={<EditProformaInvoice />}
      />
      <Route
        path="/proforma-invoices/view/:id"
        element={<ViewProformaInvoice />}
      />

      <Route path="/orders" element={<Orders />} />
      <Route path="/orders/add" element={<AddOrder />} />
      <Route path="/orders/production" element={<Production />} />
      <Route path="/orders/edit/:id" element={<EditOrder />} />
      <Route path="/orders/view/:id" element={<ViewOrder />} />

      <Route path="/pre-sales" element={<PreSales />} />
      <Route path="/pre-sales/add" element={<AddPreSale />} />
      <Route path="/pre-sales/edit/:id" element={<EditPreSale />} />
      <Route path="/pre-sales/view/:id" element={<ViewPreSale />} />

      <Route path="/sales" element={<Sales />} />
      <Route path="/sales/add" element={<AddSale />} />
      <Route path="/sales/edit/:id" element={<EditSale />} />
      <Route path="/sales/view/:id" element={<ViewSale />} />

      <Route path="/cdns" element={<CreditDebitNotes />} />
      <Route path="/cdns/add" element={<AddCreditDebitNote />} />
      <Route path="/cdns/edit/:id" element={<EditCreditDebitNote />} />
      <Route path="/cdns/view/:id" element={<ViewCreditDebitNote />} />

      {/* <Route path="/leads" element={<Leads />} /> */}

      <Route path="/dashboard/order" element={<NotPunchedOrders />} />

      <Route path="/accounts/banks" element={<Banks />} />
      <Route path="/accounts/assets" element={<Assets />} />
      <Route path="/accounts/assets/adjusts" element={<AssetAdjusts />} />
      <Route path="/accounts/narrations" element={<Narrations />} />
      <Route path="/accounts/contacts" element={<Contacts />} />
      <Route path="/accounts/transactions" element={<Transactions />} />
      <Route
        path="/accounts/transactions/view/:id"
        element={<ViewTransaction />}
      />
      <Route path="/accounts/bills" element={<Bills />} />
      <Route path="/accounts/bills/add" element={<AddBill />} />
      <Route path="/accounts/bills/edit/:id" element={<EditBill />} />
      <Route path="/accounts/bills/view/:id" element={<ViewBill />} />

      <Route path="/reports/userwiseorders" element={<PIUserWiseOrders />} />
      <Route
        path="/reports/purchases-without-tax"
        element={<PurchasesWithoutTax />}
      />
      <Route path="/reports/sales-without-tax" element={<SalesWithoutTax />} />
      <Route path="/reports/sale-figures" element={<SaleFigures />} />
      <Route path="/reports/sale-mis" element={<SalesMIS />} />
      <Route
        path="/reports/sale-figures/yearly"
        element={<YearlySaleFigures />}
      />
      <Route path="/reports/pi-figures/yearly" element={<YearlyPIFigures />} />
      <Route
        path="/reports/purchase-figures/yearly"
        element={<YearlyPurchaseFigures />}
      />
      <Route
        path="/reports/sale-figures/yearly/chart"
        element={<YearlySaleFiguresChart />}
      />
      <Route path="/reports/lastsellinggp" element={<LastSellingProductGP />} />
      <Route path="/reports/composition-gp" element={<CompositionGP />} />
      <Route path="/reports/orderfms" element={<OrderFMS />} />
      <Route path="/reports/ordermis" element={<OrderMIS />} />
      <Route path="/reports/on-time-delivery" element={<OnTimeDelivery />} />
      <Route path="/reports/on-time-received" element={<OnTimeReceived />} />

      <Route path="/sale-orders" element={<SaleOrders />} />
      <Route path="/sale-orders/add" element={<AddSaleOrder />} />
      <Route path="/sale-orders/edit/:id" element={<EditSaleOrder />} />
      <Route path="/sale-orders/view/:id" element={<ViewSaleOrder />} />

      <Route path="/dispatch/packing-pending" element={<PackingPending />} />

      <Route path="/tasks/checklists" element={<Checklists />} />

      <Route
        path="/financials/financial-summary"
        element={<FinancialSummary />}
      />
      <Route path="/financials/balance-sheet" element={<BalanceSheet />} />

      <Route path="/crm/leads" element={<Leads />} />
      <Route path="/crm/leads/add" element={<AddLead />} />
      <Route path="/crm/leads/edit/:id" element={<EditLead />} />
      <Route path="/crm/leads/view/:id" element={<ViewLead />} />
      <Route path="/crm/enquiry/fms" element={<EnquiryFMS />} />
      <Route
        path="/crm/interactions/calendar"
        element={<InteractionCalendar />}
      />

      <Route path="/quotations" element={<Quotations />} />
      <Route path="/quotations/add" element={<AddQuotation />} />
      <Route path="/quotations/edit/:id" element={<EditQuotation />} />
      <Route path="/quotations/view/:id" element={<ViewQuotation />} />

      <Route path="/help-tickets" element={<HelpTickets />} />
      <Route path="/help-tickets/add" element={<AddHelpTicket />} />
      <Route path="/help-tickets/edit/:id" element={<EditHelpTicket />} />
      <Route path="/help-tickets/view/:id" element={<ViewHelpTicket />} />

      <Route path="*" element={<Null />} />
    </Routes>
  </Structure>
);

export default Router;
