export const NONCONSIGNORPERMISSIONS = {
  getUsers: 'getUsers',
  addUser: 'addUser',
  editUser: 'editUser',
  deleteUser: 'deleteUser',
  getRoles: 'getRoles',
  addRole: 'addRole',
  editRole: 'editRole',
  deleteRole: 'deleteRole',
  getHsns: 'getHsns',
  addHsn: 'addHsn',
  editHsn: 'editHsn',
  deleteHsn: 'deleteHsn',
  getUnits: 'getUnits',
  addUnit: 'addUnit',
  editUnit: 'editUnit',
  deleteUnit: 'deleteUnit',
  getPackings: 'getPackings',
  addPacking: 'addPacking',
  editPacking: 'editPacking',
  deletePacking: 'deletePacking',
  getCharges: 'getCharges',
  addCharge: 'addCharge',
  editCharge: 'editCharge',
  deleteCharge: 'deleteCharge',
  getConsignors: 'getConsignors',
  addConsignor: 'addConsignor',
  editConsignor: 'editConsignor',
  deleteConsignor: 'deleteConsignor',
  getParties: 'getParties',
  addParty: 'addParty',
  editParty: 'editParty',
  deleteParty: 'deleteParty',
  verifyParty: 'verifyParty',
  viewPartyLedger: 'viewPartyLedger',
  addMaterial: 'addMaterial',
  editMaterial: 'editMaterial',
  getMaterials: 'getMaterials',
  deleteMaterial: 'deleteMaterial',
  restoreMaterial: 'restoreMaterial',
  addRawCOA: 'addRawCOA',
  addPackingCOA: 'addPackingCOA',
  addSecondaryPackingCOA: 'addSecondaryPackingCOA',
  addConsumableCOA: 'addConsumableCOA',
  addProduct: 'addProduct',
  getProducts: 'getProducts',
  editProduct: 'editProduct',
  viewProduct: 'viewProduct',
  deleteProduct: 'deleteProduct',
  restoreProduct: 'restoreProduct',
  copyProduct: 'copyProduct',
  productFiles: 'productFiles',
  addBulkProductCOA: 'addBulkProductCOA',
  addFinishedProductCOA: 'addFinishedProductCOA',
  productCosting: 'productCosting',
  addSop: 'addSop',
  editSop: 'editSop',
  getSops: 'getSops',
  deleteSop: 'deleteSop',
  addMachine: 'addMachine',
  editMachine: 'editMachine',
  getMachines: 'getMachines',
  deleteMachine: 'deleteMachine',
  orderDashboard: 'orderDashboard',
  saleFigures: 'saleFigures',
  purchaseFigures: 'purchaseFigures',
  salesMis: 'salesMis',
  lastSellingProductGpValues: 'lastSellingProductGpValues',
  onTimeDeliveryReport: 'onTimeDeliveryReport',
  onTimeReceivedReport: 'onTimeReceivedReport',
  orderFMS: 'orderFMS',
  orderMIS: 'orderMIS',
  getNarrations: 'getNarrations',
  addNarration: 'addNarration',
  editNarration: 'editNarration',
  deleteNarration: 'deleteNarration',
  addContact: 'addContact',
  editContact: 'editContact',
  deleteContact: 'deleteContact',
  getContacts: 'getContacts',
  getTransactions: 'getTransactions',
  addTransaction: 'addTransaction',
  editTransaction: 'editTransaction',
  deleteTransaction: 'deleteTransaction',
  getChecklists: 'getChecklists',
  addChecklist: 'addChecklist',
  editChecklist: 'editChecklist',
  deleteChecklist: 'deleteChecklist',
  addLead: 'addLead',
  editLead: 'editLead',
  getLeads: 'getLeads',
  deleteLead: 'deleteLead',
  assignLead: 'assignLead',
  getAllLeads: 'getAllLeads',
  addLeadOrigin: 'addLeadOrigin',
  editLeadOrigin: 'editLeadOrigin',
  getLeadOrigins: 'getLeadOrigins',
  deleteLeadOrigin: 'deleteLeadOrigin',
  updateMrp: 'updateMrp',
  updateRemark: 'updateRemark',
  getProductsToOrder: 'getProductsToOrder',
};

export const CONSIGNORPERMISSIONS = {
  addPurchaseOrder: 'addPurchaseOrder',
  editPurchaseOrder: 'editPurchaseOrder',
  deletePurchaseOrder: 'deletePurchaseOrder',
  completePurchaseOrder: 'completePurchaseOrder',
  getPurchaseOrders: 'getPurchaseOrders',
  getRfqs: 'getRfqs',
  addRfq: 'addRfq',
  addPurchase: 'addPurchase',
  editPurchase: 'editPurchase',
  deletePurchase: 'deletePurchase',
  getPurchases: 'getPurchases',
  generateTestingSlips: 'generateTestingSlips',
  purchaseQC: 'purchaseQC',
  getMaterialsToOrder: 'getMaterialsToOrder',
  addQuotation: 'addQuotation',
  editQuotation: 'editQuotation',
  deleteQuotation: 'deleteQuotation',
  getQuotations: 'getQuotations',
  addProformaInvoice: 'addProformaInvoice',
  editProformaInvoice: 'editProformaInvoice',
  deleteProformaInvoice: 'deleteProformaInvoice',
  getProformaInvoices: 'getProformaInvoices',
  authorizeProformaInvoice: 'authorizeProformaInvoice',
  addSaleOrder: 'addSaleOrder',
  getSaleOrders: 'getSaleOrders',
  deleteSaleOrder: 'deleteSaleOrder',
  editSaleOrder: 'editSaleOrder',
  completeSaleOrder: 'completeSaleOrder',
  getInventory: 'getInventory',
  adjustInventory: 'adjustInventory',
  transferInventory: 'transferInventory',
  getOrders: 'getOrders',
  addOrder: 'addOrder',
  editOrder: 'editOrder',
  deleteOrder: 'deleteOrder',
  authorizeOrder: 'authorizeOrder',
  completeOrder: 'completeOrder',
  getBatchedOrder: 'getBatchedOrder',
  uploadOrderDesignChanges: 'uploadOrderDesignChanges',
  approveOrderDesignChanges: 'approveOrderDesignChanges',
  moveToProductionPlanning: 'moveToProductionPlanning',
  updateOrderOverage: 'updateOrderOverage',
  dispenseOrderMaterials: 'dispenseOrderMaterials',
  verifyFormulation: 'verifyFormulation',
  moveToProduction: 'moveToProduction',
  production: 'production',
  fillMfgExpDate: 'fillMfgExpDate',
  fillBMR: 'fillBMR',
  fillBPR: 'fillBPR',
  fillCOA: 'fillCOA',
  downloadProductionFiles: 'downloadProductionFiles',
  addPreSale: 'addPreSale',
  editPreSale: 'editPreSale',
  deletePreSale: 'deletePreSale',
  getPreSales: 'getPreSales',
  addSale: 'addSale',
  editSale: 'editSale',
  deleteSale: 'deleteSale',
  getSales: 'getSales',
  addCDN: 'addCreditDebitNote',
  editCDN: 'editCreditDebitNote',
  deleteCDN: 'deleteCreditDebitNote',
  getCDNs: 'getCreditDebitNotes',
  getBanks: 'getBanks',
  addBank: 'addBank',
  editBank: 'editBank',
  deleteBank: 'deleteBank',
  getAssets: 'getAssets',
  addAsset: 'addAsset',
  editAsset: 'editAsset',
  deleteAsset: 'deleteAsset',
  adjustAsset: 'adjustAsset',
  addBill: 'addBill',
  getBills: 'getBills',
  deleteBill: 'deleteBill',
  editBill: 'editBill',
  financeReports: 'financeReports',
  viewMaterialLedger: 'viewMaterialLedger',
  viewProductLedger: 'viewProductLedger',
  addHelpTicket: 'addHelpTicket',
  editHelpTicket: 'editHelpTicket',
  deleteHelpTicket: 'deleteHelpTicket',
  getHelpTickets: 'getHelpTickets',
};

const PERMISSIONS = {
  ...NONCONSIGNORPERMISSIONS,
  ...CONSIGNORPERMISSIONS,
};

export default PERMISSIONS;
