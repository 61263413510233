export default {
  layout: {
    minHeight: '100vh',
  },
  content: {
    margin: '0 10px 10px',
  },
  contentContainer: {
    background: '#fff',
    minHeight: '100%',
    overflow: 'auto',
    padding: '0 0 10px 0',
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    padding: '0px !important',
    background: '#fff',
  },
  sidebar: {
    height: '100%',
  },
  drawer: {
    height: '100%',
    '& div.ant-drawer-body': {
      padding: 0,
    },
    '& div.ant-drawer-content-wrapper': {
      width: '200px !important',
    },
  },
  '@media (min-width: 768px)': {
    drawer: {
      display: 'none',
    },
    collapseDrawerIcon: {
      display: 'none',
    },
    header: {
      display: 'none',
    },
  },
  '@media (max-width: 767px)': {
    sidebar: {
      display: 'none',
    },
  },
  logoDivStyle: {
    display: 'flex',
    justifyContent: 'center',
  },
  logo: {
    height: '40px',
  },
  collapseIcon: {
    fontSize: '16px',
    marginLeft: '25px',
    cursor: 'pointer',
    outline: 'none!important',
  },
  icon: {
    fontSize: '20px !important',
  },
  navBarMenu: {
    '& span.anticon': {
      marginRight: '5px',
    },
    '& li.ant-dropdown-menu-item': {
      padding: '5px',
      width: 'max-content',
    },
    marginRight: '5px',
  },
  avatarMenu: {
    marginLeft: 'auto',
    padding: '0 10px 0 0',
  },
};
