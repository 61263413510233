import { combineReducers } from '@reduxjs/toolkit';

import hsns from './hsns';
import units from './units';
import consignors from './consignors';
import charges from './charges';
import csc from './csc';
import packings from './packings';
import origins from './origins';

export * from './hsns';
export * from './units';
export * from './consignors';
export * from './charges';
export * from './csc';
export * from './packings';
export * from './origins';

export default combineReducers({
  hsns,
  units,
  consignors,
  charges,
  csc,
  packings,
  origins,
});
