import React, { Suspense, useEffect } from 'react';
import { Route, Routes, useNavigate, useSearchParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';

import 'antd/dist/antd.min.css';
import '../libs/socket-handlers';
import 'react-big-calendar/lib/css/react-big-calendar.css';

import Login from './login/Login';
import './App.css';
import { login } from '../redux/actions';
import Router from './Router';
import FillRates from './rfq-parties/Fill-Rates';

const App = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [searchParams] = useSearchParams();

  const { user } = useSelector(state => state.loginInfo);

  useEffect(() => {
    if (!window.location.pathname.startsWith('/general')) {
      const path = ['/login', '/'].includes(window.location.pathname)
        ? '/login'
        : `/login?path=${window.location.pathname}`;

      dispatch(login())
        .unwrap()
        .catch(() => {
          navigate(path);
        });
    }
  }, []);

  useEffect(() => {
    const path =
      window.location.pathname === '/login'
        ? searchParams.get('path')
        : window.location.pathname;

    if (user) {
      navigate(path || '/');
    }
  }, [user]);

  return (
    <Suspense
      fallback={
        <Spin
          indicator={
            <LoadingOutlined
              style={{
                fontSize: 36,
                position: 'absolute',
                left: '50%',
                top: '50%',
              }}
              spin
            />
          }
        />
      }
    >
      {user ? (
        <Router />
      ) : (
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/general/rfqs/:rfqId/:partyId" element={<FillRates />} />
          <Route path="*" element={null} />
        </Routes>
      )}
    </Suspense>
  );
};

export default App;
