import { createAsyncThunk, createSlice, isAnyOf } from '@reduxjs/toolkit';

import { errorNotification } from '../../components/notifications';
import api from '../../libs/api';

// Initial State redux.users
const initialState = {
  users: {
    list: [],
    page: 1,
    totalPages: 1,
    totalUsers: 0,
  },
  user: {},
  userIds: [],
  roles: [],
  role: {},
  roleIds: [],
};

// Async thunk Actions
export const getUsers = createAsyncThunk('users/getUsers', async query => {
  const { data } = await api.get('/users', query);

  return data;
});

export const getUser = createAsyncThunk('users/getUser', async id => {
  const { data } = await api.get(`/users/${id}`);

  return data;
});

export const deleteUser = createAsyncThunk('users/deleteUser', async id => {
  await api.delete(`/users/${id}`);

  return id;
});

export const getUserIds = createAsyncThunk('users/getUserIds', async query => {
  const { data } = await api.get('/users/ids', query);

  return data;
});

export const getRoles = createAsyncThunk('users/getRoles', async () => {
  const { data } = await api.get('/users/roles');

  return data;
});

export const getRoleIds = createAsyncThunk('users/getRoleIds', async () => {
  const { data } = await api.get('/users/roles/ids');

  return data;
});

export const getRole = createAsyncThunk('users/getRole', async id => {
  const { data } = await api.get(`/users/roles/${id}`);

  return data;
});

export const deleteRole = createAsyncThunk(
  'users/deleteRole',
  async ({ id, newRoleId }) => {
    await api.delete(`/users/roles/${id}`, { newRoleId });

    return id;
  },
);

// User Redux-Toolkit Slice
const users = createSlice({
  name: 'users',
  initialState,

  reducers: {
    clearUsers: draft => {
      draft.users = initialState.users;
    },

    clearUser: draft => {
      draft.user = initialState.user;
    },

    clearUserIds: draft => {
      draft.userIds = initialState.userIds;
    },

    clearRoles: draft => {
      draft.roles = initialState.roles;
    },

    clearRoleIds: draft => {
      draft.roleIds = initialState.roleIds;
    },

    clearRole: draft => {
      draft.role = initialState.role;
    },
  },

  extraReducers: builder => {
    builder.addCase(getUsers.fulfilled, (draft, { payload }) => {
      draft.users.list = payload.users;
      draft.users.totalUsers = payload.totalUsers;
      draft.users.page = payload.page;
      draft.users.totalPages = payload.totalPages;
    });

    builder.addCase(deleteUser.fulfilled, (draft, { payload }) => {
      draft.users = draft.users.filter(el => el.id !== payload);
    });

    builder.addCase(getUser.fulfilled, (draft, { payload }) => {
      draft.user = payload;
    });

    builder.addCase(getUserIds.fulfilled, (draft, { payload }) => {
      draft.userIds = payload;
    });

    builder.addCase(getRoles.fulfilled, (draft, { payload }) => {
      draft.roles = payload;
    });

    builder.addCase(getRole.fulfilled, (draft, { payload }) => {
      draft.role = payload;
    });

    builder.addCase(getRoleIds.fulfilled, (draft, { payload }) => {
      draft.roleIds = payload;
    });

    builder.addCase(deleteRole.fulfilled, (draft, { payload }) => {
      draft.roles = draft.roles.filter(el => el.id !== payload);
    });

    builder.addMatcher(
      isAnyOf(
        getUsers.rejected,
        getUser.rejected,
        deleteUser.rejected,
        getUserIds.rejected,
        getRoles.rejected,
        getRoleIds.rejected,
        getRole.rejected,
        deleteRole.rejected,
      ),
      (_Proxy, { error }) => {
        errorNotification(error);
      },
    );
  },
});

export const {
  clearUsers,
  clearUser,
  clearUserIds,
  clearRoles,
  clearRoleIds,
  clearRole,
} = users.actions;

export default users.reducer;
