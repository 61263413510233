import { createAsyncThunk, createSlice, isAnyOf } from '@reduxjs/toolkit';

import { errorNotification } from '../../components/notifications';
import api from '../../libs/api';

const initialState = {
  quotations: {
    list: [],
    page: 1,
    query: { page: 1 },
    totalPages: 1,
    totalQuotations: 0,
  },
  quotation: { materials: [], products: [], charges: [] },
  quotationIds: [],
  files: [],
};

export const getQuotations = createAsyncThunk(
  'quotations/getQuotations',
  async (query, { getState }) => {
    const currentQuery = getState().quotations.quotations.query;

    const effectiveQuery = query || currentQuery;

    const { data } = await api.get('/quotations', effectiveQuery);

    return { data, query: effectiveQuery };
  },
);

export const getQuotation = createAsyncThunk(
  'quotations/getQuotation',
  async id => {
    const { data } = await api.get(`/quotations/${id}`);

    return data;
  },
);

export const deleteQuotation = createAsyncThunk(
  'quotations/deleteQuotation',
  async (id, { getState }) => {
    const userName = getState().loginInfo.user.name;

    await api.delete(`/quotations/${id}`);

    return { id, userName };
  },
);

export const cancelQuotation = createAsyncThunk(
  'quotations/cancelQuotation',
  async (id, { getState }) => {
    const userName = getState().loginInfo.user.name;

    await api.put(`/quotations/${id}/cancel`);

    return { id, userName };
  },
);

export const convertQuotation = createAsyncThunk(
  'quotations/convertQuotation',
  async (id, { getState }) => {
    const userName = getState().loginInfo.user.name;

    await api.put(`/quotations/${id}/convert`);

    return { id, userName };
  },
);

export const getQuotationIds = createAsyncThunk(
  'quotations/getQuotationIds ',
  async query => {
    const { data } = await api.get('/quotations/ids', query);

    return data;
  },
);

export const getQuotationFiles = createAsyncThunk(
  'quotations/getQuotationFiles',
  async quotationId => {
    const { data } = await api.get(`/quotations/files/${quotationId}`);

    return data;
  },
);

export const deleteQuotationFile = createAsyncThunk(
  'quotations/deleteQuotationFiles',
  async ({ fileId, quotationId }) => {
    await api.delete(`/quotations/files/${fileId}`);

    return { fileId, quotationId };
  },
);

export const addQuotationFiles = createAsyncThunk(
  'quotations/addQuotationFiles',
  async ({ quotationId, files }) => {
    if (!files || !files.length) {
      throw new Error('Please select files first');
    }

    const formData = new FormData();

    files.forEach((file, index) => {
      formData.append(`files[${index}]`, file);
    });

    const { data } = await api.post(
      `/quotations/files/${quotationId}`,
      formData,
      {
        headers: {
          'content-type': 'multipart/form-data',
        },
      },
    );

    return { data, quotationId };
  },
);

const quotations = createSlice({
  name: 'quotations',

  initialState,

  reducers: {
    clearQuotations: draft => {
      draft.quotations = initialState.quotations;
    },

    clearQuotation: draft => {
      draft.quotation = initialState.quotation;
    },

    clearQuotationIds: draft => {
      draft.quotationIds = initialState.quotationIds;
    },

    clearQuotationFiles: draft => {
      draft.files = initialState.files;
    },
  },

  extraReducers: builder => {
    builder.addCase(getQuotations.fulfilled, (draft, { payload }) => {
      draft.quotations.list = payload.data.quotations;
      draft.quotations.page = payload.data.page;
      draft.quotations.query = payload.query;
      draft.quotations.totalQuotations = payload.data.totalQuotations;
      draft.quotations.totalPages = payload.data.totalPages;
    });

    builder.addCase(getQuotation.fulfilled, (draft, { payload }) => {
      draft.quotation = payload;
    });

    builder.addCase(deleteQuotation.fulfilled, (draft, { payload }) => {
      const index = draft.quotations.list.findIndex(el => el.id === payload.id);

      if (index > -1) {
        draft.quotations.list.splice(index, 1);
      }
    });

    builder.addCase(cancelQuotation.fulfilled, (draft, { payload }) => {
      const index = draft.quotations.list.findIndex(el => el.id === payload.id);

      if (index > -1) {
        draft.quotations.list.splice(index, 1);
      }
    });

    builder.addCase(convertQuotation.fulfilled, (draft, { payload }) => {
      const index = draft.quotations.list.findIndex(el => el.id === payload.id);

      if (index > -1) {
        draft.quotations.list.splice(index, 1);
      }
    });

    builder.addCase(getQuotationIds.fulfilled, (draft, { payload }) => {
      draft.quotationIds = payload;
    });

    builder.addCase(getQuotationFiles.fulfilled, (draft, { payload }) => {
      draft.files = payload;
    });

    builder.addCase(deleteQuotationFile.fulfilled, (draft, { payload }) => {
      draft.files = draft.files.filter(file => file.id !== payload.fileId);

      const quotation = draft.quotations.list.find(
        el => el.id === payload.quotationId,
      );

      if (quotation) {
        quotation.noOfFiles = +quotation.noOfFiles - 1;
      }
    });

    builder.addCase(addQuotationFiles.fulfilled, (draft, { payload }) => {
      draft.files.push(...payload.data.uploaded);

      const quotation = draft.quotations.list.find(
        el => el.id === payload.quotationId,
      );

      if (quotation) {
        quotation.noOfFiles =
          +quotation.noOfFiles + payload.data.uploaded.length;
      }

      if (payload.data.failed.length) {
        errorNotification(
          new Error(`${payload.data.failed.join(', ')} failed to upload`),
        );
      }
    });

    builder.addMatcher(
      isAnyOf(
        getQuotations.rejected,
        getQuotation.rejected,
        deleteQuotation.rejected,
        cancelQuotation.rejected,
        convertQuotation.rejected,
        getQuotationIds.rejected,
        getQuotationFiles.rejected,
        deleteQuotationFile.rejected,
        addQuotationFiles.rejected,
      ),
      (_Draft, { error }) => {
        errorNotification(error);
      },
    );
  },
});

export const {
  clearQuotations,
  clearQuotation,
  clearQuotationIds,
  clearQuotationFiles,
} = quotations.actions;

export default quotations.reducer;
