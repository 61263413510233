import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import withStyles from 'react-jss';
import { Button, Col, Input, InputNumber, Row } from 'antd';
import { useParams } from 'react-router-dom';

import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { FileOutlined } from '@ant-design/icons';
import api from '../../libs/api';
import styles from '../styles';
import { errorNotification, successNotification } from '../notifications';
import GetField from '../common/GetField';

const AntInputNumber = GetField(InputNumber);
const AntInputTextArea = GetField(Input.TextArea);

const FillRates = props => {
  const { classes } = props;

  const [PartyDetails, SetPartyDetails] = useState({ materials: [] });

  const params = useParams();

  const { handleSubmit, control, reset } = useForm();

  const dateFormatter = new Intl.DateTimeFormat('en', { dateStyle: 'medium' });

  const { fields } = useFieldArray({
    control,
    name: 'materials',
  });

  useEffect(() => {
    api
      .get(`/rfqs/${params.rfqId}/parties/${params.partyId}`)
      .then(({ data }) => {
        SetPartyDetails(data);
        reset({ materials: data.materials });
      })
      .catch(error => {
        errorNotification('Invalid Request');
      });
  }, []);

  const onSubmit = async formValues => {
    try {
      await api.patch(
        `/rfqs/${params.rfqId}/parties/${params.partyId}`,
        formValues,
      );

      successNotification('RFQ details updated');
    } catch (error) {
      errorNotification(error);
    }
  };

  return (
    <>
      <Row>
        <h3 style={{ margin: '10px auto' }}>Request For Quotation</h3>
      </Row>
      <Row>
        <h3 style={{ margin: 'auto' }}>Party: {PartyDetails.name}</h3>
      </Row>
      <Row>
        <h4 style={{ margin: 'auto' }}>
          DL #: {PartyDetails.dln || 'Not Available'}
        </h4>
      </Row>
      <Row>
        <h4 style={{ margin: 'auto' }}>
          GST #: {PartyDetails.gstin || 'Not Available'}
        </h4>
      </Row>
      <Row>
        <h4 style={{ margin: 'auto' }}>
          RFQ Date:{' '}
          {PartyDetails.createdAt &&
            dateFormatter.format(new Date(PartyDetails.createdAt))}
        </h4>
      </Row>
      <br />
      <form onSubmit={handleSubmit(onSubmit)}>
        <Row>
          <Col xs={{ span: 22, offset: 1 }}>
            <table className={classes.inputTable}>
              <thead className={classes.inputThead}>
                <tr>
                  <th className={classes.inputTh}>Material</th>
                  <th className={classes.inputTh} style={{ width: 70 }}>
                    Design File
                  </th>
                  <th className={classes.inputTh} style={{ width: 150 }}>
                    Type
                  </th>
                  <th className={classes.inputTh} style={{ width: 100 }}>
                    Requirement
                  </th>
                  <th className={classes.inputTh}>Quantity</th>
                  <th className={classes.inputTh}>Rate</th>
                  <th className={classes.inputTh}>Lead Time (In Days)</th>
                  <th className={classes.inputTh}>Remark</th>
                </tr>
              </thead>
              <tbody>
                {fields.map((item, index) => (
                  <tr key={item.id}>
                    <td className={classes.inputTdText}>{item.material}</td>
                    <td className={classes.inputTdText}>
                      {item.url && (
                        <>
                          &nbsp; &nbsp;
                          <a href={item.url} target="_blank" rel="noreferrer">
                            <FileOutlined />
                          </a>
                        </>
                      )}
                    </td>
                    <td className={classes.inputTdText}>{item.type}</td>
                    <td className={classes.inputTdText}>
                      {+item.requirement} {item.unit}
                    </td>
                    <td className={classes.inputTd}>
                      <Controller
                        name={`materials[${index}].quantity`}
                        control={control}
                        render={({ field }) => (
                          <AntInputNumber
                            {...field}
                            placeholder="Quantity"
                            step={0.0001}
                          />
                        )}
                      />
                    </td>
                    <td className={classes.inputTd}>
                      <Controller
                        name={`materials[${index}].rate`}
                        control={control}
                        render={({ field }) => (
                          <AntInputNumber
                            {...field}
                            placeholder={`Rate per ${item.unit}`}
                            addonAfter={`/${item.unit}`}
                            step={0.001}
                          />
                        )}
                      />
                    </td>
                    <td className={classes.inputTd}>
                      <Controller
                        name={`materials[${index}].leadTime`}
                        control={control}
                        render={({ field }) => (
                          <AntInputNumber
                            {...field}
                            placeholder="Lead Time in days"
                            step={1}
                          />
                        )}
                      />
                    </td>
                    <td className={classes.inputTd}>
                      <Controller
                        name={`materials[${index}].remark`}
                        control={control}
                        render={({ field }) => (
                          <AntInputTextArea
                            placeholder="Remark if any!"
                            {...field}
                            autoSize={{ minRows: 1, maxRows: 6 }}
                          />
                        )}
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </Col>
        </Row>
        <Row>
          <Button
            style={{ margin: '10px auto' }}
            type="primary"
            htmlType="submit"
          >
            Submit
          </Button>
        </Row>
      </form>
    </>
  );
};

FillRates.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(FillRates);
