import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import withStyles from 'react-jss';
import { useDispatch } from 'react-redux';
import { Input, Button, Modal } from 'antd';
import { Link } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';

import styles from './styles';
import { successNotification, errorNotification } from '../notifications';
import api from '../../libs/api';
import GetField from '../common/GetField';
import { login, setAccountPassword } from '../../redux/actions';

const AntInput = GetField(Input);
const AntPassword = GetField(Input.Password);

const Login = props => {
  const { classes } = props;

  const dispatch = useDispatch();

  const { handleSubmit, control, getValues } = useForm();

  const [ForgotPasswordEmailModal, SetForgotPasswordEmailModal] =
    useState(false);
  const [ForgotPasswordModal, SetForgotPasswordModal] = useState(false);
  const [ConfirmLoading, SetConfirmLoading] = useState(false);
  const [VerifyModal, SetVerifyModal] = useState(false);

  const onSubmit = useCallback(
    async formValues => {
      dispatch(login(formValues));
    },
    [dispatch],
  );

  const onForgot = useCallback(async () => {
    try {
      SetConfirmLoading(true);

      const formValues = getValues();
      const { email } = formValues;

      await api.post('/auth/forget', { email });

      successNotification('Code sent to entered your e-mail');

      SetForgotPasswordEmailModal(false);
      SetForgotPasswordModal(true);
    } catch (error) {
      errorNotification(error);
    } finally {
      SetConfirmLoading(false);
    }
  }, [
    SetConfirmLoading,
    getValues,
    SetForgotPasswordEmailModal,
    SetForgotPasswordModal,
  ]);

  const onSubmitNewPasword = useCallback(async () => {
    try {
      const formValues = getValues();

      const { email, otp, password, repeatPassword } = formValues;

      await dispatch(
        setAccountPassword({ email, otp, password, repeatPassword }),
      ).unwrap();

      successNotification('Password changed');
    } catch (error) {
      errorNotification(error);
    }
  }, []);

  const hideForgotModal = useCallback(() => {
    SetForgotPasswordModal(false);
    SetForgotPasswordEmailModal(false);
    SetVerifyModal(false);
    SetConfirmLoading(false);
  }, [SetForgotPasswordModal, SetConfirmLoading]);

  const handleVefiryModalOk = async () => {
    try {
      const formValues = getValues();
      const { email, otp, password, repeatPassword } = formValues;

      await dispatch(
        setAccountPassword({ email, otp, password, repeatPassword }),
      ).unwrap();

      successNotification('Password set sucessfully');
    } catch (error) {
      errorNotification(error);
    }
  };

  return (
    <div className={classes.background}>
      <div className={classes.card}>
        <span className={classes.company}>Sign in to Sarthak Hive</span>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Controller
            name="email"
            control={control}
            render={({ field }) => (
              <AntInput required allowClear placeholder="Email" {...field} />
            )}
          />
          <Controller
            name="password"
            control={control}
            render={({ field }) => (
              <AntPassword
                required
                allowClear
                placeholder="Password"
                {...field}
              />
            )}
          />
          <Button className={classes.button} type="primary" htmlType="submit">
            Sign in
          </Button>
          <div className={classes.linkGroup}>
            <Link
              to="#"
              onKeyPress={() => SetForgotPasswordEmailModal(true)}
              onClick={() => SetForgotPasswordEmailModal(true)}
              className={classes.link}
            >
              Forgot password ?
            </Link>
            <Link
              to="#"
              onKeyPress={() => SetVerifyModal(true)}
              onClick={() => SetVerifyModal(true)}
              className={classes.link}
            >
              Verify
            </Link>
          </div>
        </form>
        <Modal
          onOk={ForgotPasswordModal ? onSubmitNewPasword : onForgot}
          open={ForgotPasswordEmailModal || ForgotPasswordModal}
          confirmLoading={ConfirmLoading}
          onCancel={hideForgotModal}
          centered
          maskClosable={false}
          title={`Forgot password ? ${ForgotPasswordEmailModal ? 1 : 2}/2`}
          okText="Continue"
        >
          <>
            {ForgotPasswordEmailModal && (
              <>
                <p>
                  You will receive 6 digits characters code by e-mail to verify
                  your identity.
                </p>
                <Controller
                  name="email"
                  control={control}
                  render={({ field }) => (
                    <AntInput
                      allowClear
                      placeholder="Email to recover"
                      {...field}
                    />
                  )}
                />
              </>
            )}
            {ForgotPasswordModal && (
              <>
                <p>
                  You have received a six digits characters code by e-mail{' '}
                  <span>📫</span>. Enter it in the Code, Password and Repeat
                  Password fields below to continue.
                </p>
                <Controller
                  name="otp"
                  control={control}
                  render={({ field }) => (
                    <AntInput allowClear placeholder="Code" {...field} />
                  )}
                />
                <Controller
                  name="password"
                  control={control}
                  render={({ field }) => (
                    <AntPassword
                      required
                      allowClear
                      placeholder="Password"
                      {...field}
                    />
                  )}
                />
                <Controller
                  name="repeatPassword"
                  control={control}
                  render={({ field }) => (
                    <AntPassword
                      required
                      allowClear
                      placeholder="Repeat Password"
                      {...field}
                    />
                  )}
                />
              </>
            )}
          </>
        </Modal>
        <Modal
          onOk={handleVefiryModalOk}
          open={VerifyModal}
          onCancel={() => SetVerifyModal(false)}
          centered
          maskClosable={false}
          title="Verify and set password"
          okText="Submit"
        >
          <p>
            Enter the Email, code (received six digit character code in email),
            password and repeat Password fields below to verify your account.
          </p>
          <Controller
            name="email"
            control={control}
            render={({ field }) => (
              <AntInput allowClear placeholder="Email" {...field} />
            )}
          />
          <Controller
            name="otp"
            control={control}
            render={({ field }) => (
              <AntInput allowClear placeholder="Code" {...field} />
            )}
          />
          <Controller
            name="password"
            control={control}
            render={({ field }) => (
              <AntPassword
                required
                allowClear
                placeholder="Password"
                {...field}
              />
            )}
          />
          <Controller
            name="repeatPassword"
            control={control}
            render={({ field }) => (
              <AntPassword
                required
                allowClear
                placeholder="Repeat Password"
                {...field}
              />
            )}
          />
        </Modal>
      </div>
    </div>
  );
};

Login.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Login);
