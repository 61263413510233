import { createAsyncThunk, createSlice, isAnyOf } from '@reduxjs/toolkit';

import { errorNotification } from '../../components/notifications';
import api from '../../libs/api';

const initialState = {
  assets: [],
  asset: {},
  assetIds: [],
  adjusts: [],
};

export const getAssets = createAsyncThunk('assets/getAssets', async () => {
  const { data } = await api.get('/assets');

  return data;
});

export const getAssetIds = createAsyncThunk('assets/getAssetIds', async () => {
  const { data } = await api.get('/assets/ids');

  return data;
});

export const getAsset = createAsyncThunk('assets/getAsset', async id => {
  const { data } = await api.get(`/assets/${id}`);

  return data;
});

export const addAsset = createAsyncThunk('assets/addAsset', async data => {
  const { data: asset } = await api.post('/assets', data);

  return asset;
});

export const editAsset = createAsyncThunk(
  'assets/editAsset',
  async ({ id, data }) => {
    const { data: asset } = await api.put(`/assets/${id}`, data);

    return asset;
  },
);

export const getAdjusts = createAsyncThunk('assets/getAdjusts', async () => {
  const { data } = await api.get('/assets/adjust');

  return data;
});

const assets = createSlice({
  name: 'assets',

  initialState,

  reducers: {
    clearAssets: draft => {
      draft.assets = initialState.assets;
    },

    clearAsset: draft => {
      draft.asset = initialState.asset;
    },

    clearAssetIds: draft => {
      draft.assetIds = initialState.assetIds;
    },

    clearAdjusts: draft => {
      draft.adjusts = initialState.adjusts;
    },
  },

  extraReducers: builder => {
    builder.addCase(getAssets.fulfilled, (draft, { payload }) => {
      draft.assets = payload;
    });

    builder.addCase(getAsset.fulfilled, (draft, { payload }) => {
      draft.asset = payload;
    });

    builder.addCase(addAsset.fulfilled, (draft, { payload }) => {
      draft.assets.push(payload);
    });

    builder.addCase(editAsset.fulfilled, (draft, { payload }) => {
      const index = draft.assets.findIndex(el => el.id === payload.id);

      draft.assets[index] = payload;
    });

    builder.addCase(getAssetIds.fulfilled, (draft, { payload }) => {
      draft.assetIds = payload;
    });

    builder.addCase(getAdjusts.fulfilled, (draft, { payload }) => {
      draft.adjusts = payload;
    });

    builder.addMatcher(
      isAnyOf(
        getAssets.rejected,
        getAsset.rejected,
        addAsset.rejected,
        editAsset.rejected,
        getAssetIds.rejected,
        getAdjusts.rejected,
      ),
      (_Draft, { error }) => {
        errorNotification(error);
      },
    );
  },
});

export const { clearAssets, clearAsset, clearAssetIds, clearAdjusts } =
  assets.actions;

export default assets.reducer;
