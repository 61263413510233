import { createAsyncThunk, createSlice, isAnyOf } from '@reduxjs/toolkit';

import { errorNotification } from '../../components/notifications';
import api from '../../libs/api';

const initialState = {
  banks: [],
  bank: {},
  bankIds: [],
};

export const getBanks = createAsyncThunk('banks/getBanks', async () => {
  const { data } = await api.get('/banks');

  return data;
});

export const getBankIds = createAsyncThunk('banks/getBankIds', async query => {
  const { data } = await api.get('/banks/ids', query);

  return data;
});

export const getBank = createAsyncThunk('banks/getBank', async id => {
  const { data } = await api.get(`/banks/${id}`);

  return data;
});

export const deleteBank = createAsyncThunk('banks/deleteBank', async id => {
  await api.delete(`/banks/${id}`);

  return id;
});

export const restoreBank = createAsyncThunk('banks/restoreBank', async id => {
  await api.put(`/banks/restore/${id}`);

  return id;
});

export const addBank = createAsyncThunk('banks/addBank', async data => {
  const { data: bank } = await api.post('/banks', data);

  return bank;
});

export const editBank = createAsyncThunk(
  'banks/editBank',
  async ({ id, data }) => {
    const { data: bank } = await api.put(`/banks/${id}`, data);

    return bank;
  },
);

const banks = createSlice({
  name: 'banks',

  initialState,

  reducers: {
    clearBanks: draft => {
      draft.banks = initialState.banks;
    },

    clearBank: draft => {
      draft.bank = initialState.bank;
    },

    clearBankIds: draft => {
      draft.bankIds = initialState.bankIds;
    },
  },

  extraReducers: builder => {
    builder.addCase(getBanks.fulfilled, (draft, { payload }) => {
      draft.banks = payload;
    });

    builder.addCase(getBank.fulfilled, (draft, { payload }) => {
      draft.bank = payload;
    });

    builder.addCase(deleteBank.fulfilled, (draft, { payload }) => {
      draft.banks = draft.banks.filter(el => el.id !== payload);
    });

    builder.addCase(restoreBank.fulfilled, (draft, { payload }) => {
      draft.banks = draft.banks.filter(el => el.id !== payload);
    });

    builder.addCase(addBank.fulfilled, (draft, { payload }) => {
      draft.banks.push(payload);
    });

    builder.addCase(editBank.fulfilled, (draft, { payload }) => {
      const index = draft.banks.findIndex(el => el.id === payload.id);

      draft.banks[index] = payload;
    });

    builder.addCase(getBankIds.fulfilled, (draft, { payload }) => {
      draft.bankIds = payload;
    });

    builder.addMatcher(
      isAnyOf(
        getBanks.rejected,
        getBank.rejected,
        deleteBank.rejected,
        restoreBank.rejected,
        addBank.rejected,
        editBank.rejected,
        getBankIds.rejected,
      ),
      (_Draft, { error }) => {
        errorNotification(error);
      },
    );
  },
});

export const { clearBanks, clearBank, clearBankIds } = banks.actions;

export default banks.reducer;
