import { createAsyncThunk, createSlice, isAnyOf } from '@reduxjs/toolkit';

import { errorNotification } from '../../../components/notifications';
import api from '../../../libs/api';

const initialState = {
  countries: [],
  states: [],
  cities: [],
};

export const getCountries = createAsyncThunk(
  'dropdowns/csc/getCountries',
  async () => {
    const { data } = await api.get('/dropdowns/csc/countries');

    return data;
  },
);

export const getStates = createAsyncThunk(
  'dropdowns/csc/getStates',
  async ({ country }) => {
    const { data } = await api.get('/dropdowns/csc/states', { country });

    return data;
  },
);

export const getCities = createAsyncThunk(
  'dropdowns/csc/getCities',
  async ({ country, state }) => {
    const { data } = await api.get('/dropdowns/csc/cities', { country, state });

    return data;
  },
);

const csc = createSlice({
  name: 'csc',

  initialState,

  reducers: {
    clearCountries: draft => {
      draft.countries = initialState.countries;
    },

    clearStates: draft => {
      draft.states = initialState.states;
    },

    clearCities: draft => {
      draft.cities = initialState.cities;
    },
  },

  extraReducers: builder => {
    builder.addCase(getCountries.fulfilled, (draft, { payload }) => {
      draft.countries = payload;
    });

    builder.addCase(getStates.fulfilled, (draft, { payload }) => {
      draft.states = payload;
    });

    builder.addCase(getCities.fulfilled, (draft, { payload }) => {
      draft.cities = payload;
    });

    builder.addMatcher(
      isAnyOf(getCountries.rejected, getStates.rejected, getCities.rejected),
      (_draft, { error }) => {
        errorNotification(error);
      },
    );
  },
});

export const { clearCountries, clearStates, clearCities } = csc.actions;

export default csc.reducer;
