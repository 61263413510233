import { Button, Modal } from 'antd';
import PropTypes from 'prop-types';
import withStyles from 'react-jss';

import moment from 'moment';
import styles from '../styles';

const PiProductPickModal = props => {
  const { setValue, setPiProducts, piProducts, classes } = props;

  return (
    <Modal
      centered
      open
      maskClosable={false}
      okButtonProps={{ style: { display: 'none' } }}
      cancelButtonProps={{ style: { display: 'none' } }}
      width={800}
      title="Select Product Details"
      closeIcon={null}
    >
      <table className={classes.inputTable}>
        <thead className={classes.inputThead}>
          <tr key="header">
            <th className={classes.inputTh}>MRP</th>
            <th className={classes.inputTh}>Quantity</th>
            <th className={classes.inputTh}>Rate</th>
            <th className={classes.inputTh}>Design Changes Required?</th>
            <th className={classes.inputTh}>Expected Delivery Date</th>
            <th className={classes.inputTh}>Action</th>
          </tr>
        </thead>
        <tbody>
          {piProducts.map((product, index) => (
            <tr key={index}>
              <td className={classes.inputTd}>{product.mrp}</td>
              <td className={classes.inputTd}>{product.quantity}</td>
              <td className={classes.inputTd}>{product.rate}</td>
              <td className={classes.inputTd}>
                {product.designChangesRequired ? 'Yes' : 'No'}
              </td>
              <td className={classes.inputTd}>
                {moment().add(product.leadTime, 'days').format('DD MMM YYYY')}
              </td>
              <td className={classes.inputTd}>
                <Button
                  htmlType="button"
                  onClick={() => {
                    setValue('mrp', product.mrp);
                    setValue('quantity', product.quantity);
                    setValue('rate', product.rate);
                    setValue('type', product.type);
                    setValue(
                      'isDesignChangesRequired',
                      product.isDesignChangesRequired,
                    );
                    setValue(
                      'expectedDeliveryDate',
                      moment().add(product.leadTime, 'days').toISOString(),
                    );
                    setPiProducts([]);
                  }}
                >
                  Select
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </Modal>
  );
};

PiProductPickModal.propTypes = {
  setValue: PropTypes.func.isRequired,
  setPiProducts: PropTypes.func.isRequired,
  piProducts: PropTypes.array.isRequired,
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(PiProductPickModal);
