import { createAsyncThunk, createSlice, isAnyOf } from '@reduxjs/toolkit';

import { errorNotification } from '../../components/notifications';
import api from '../../libs/api';

const initialState = {
  sops: {
    list: [],
    page: 1,
    query: {
      page: 1,
    },
    totalPages: 1,
    totalSops: 0,
  },
  sop: {},
  sopIds: [],
  files: [],
};

export const addSop = createAsyncThunk('sops/addSop', async data => {
  const { data: sop } = await api.post('/sops', data);

  return sop;
});

export const editSop = createAsyncThunk(
  'sops/editSop',
  async ({ id, data }) => {
    const { data: sop } = await api.put(`/sops/${id}`, data);

    return sop;
  },
);

export const getSops = createAsyncThunk(
  'sops/getSops',
  async (query, { getState }) => {
    const currentQuery = getState().sops.sops.query;

    const effectiveQuery = query || currentQuery;

    const { data } = await api.get('/sops', effectiveQuery);

    return { data, query: effectiveQuery };
  },
);

export const getSop = createAsyncThunk('sops/getSop', async id => {
  const { data } = await api.get(`/sops/${id}`);

  return data;
});

export const getSopIds = createAsyncThunk('sops/getSopIds', async () => {
  const { data } = await api.get('/sops/ids');

  return data;
});

export const getSopFiles = createAsyncThunk('sops/getSopFiles', async sopId => {
  const { data } = await api.get(`/sops/files/${sopId}`);

  return data;
});

export const deleteSopFile = createAsyncThunk(
  'sops/deleteSopFiles',
  async ({ fileId, sopId }) => {
    await api.delete(`/sops/files/${fileId}`);

    return { fileId, sopId };
  },
);

export const addSopFiles = createAsyncThunk(
  'sops/addSopFiles',
  async ({ sopId, files }) => {
    if (!files || !files.length) {
      throw new Error('Please select files first');
    }

    const formData = new FormData();

    files.forEach((file, index) => {
      formData.append(`files[${index}]`, file);
    });

    const { data } = await api.post(`/sops/files/${sopId}`, formData, {
      headers: {
        'content-type': 'multipart/form-data',
      },
    });

    return { data, sopId };
  },
);

const sops = createSlice({
  name: 'sops',

  initialState,

  reducers: {
    clearSops: draft => {
      draft.sops = initialState.sops;
    },

    clearSop: draft => {
      draft.sop = initialState.sop;
    },

    clearSopIds: draft => {
      draft.sopIds = initialState.sopIds;
    },

    clearSopFiles: draft => {
      draft.files = initialState.files;
    },
  },

  extraReducers: builder => {
    // builder.addCase(addSop.fulfilled, (draft, { payload }) => {
    //   draft.sops.list.push(payload);
    // });

    // builder.addCase(editSop.fulfilled, (draft, { payload }) => {});

    builder.addCase(getSops.fulfilled, (draft, { payload }) => {
      draft.sops.list = payload.data.sops;
      draft.sops.page = payload.data.page;
      draft.sops.query = payload.query;
      draft.sops.totalSops = payload.data.totalSops;
      draft.sops.totalPages = payload.data.totalPages;
    });

    builder.addCase(getSop.fulfilled, (draft, { payload }) => {
      draft.sop = payload;
    });

    builder.addCase(getSopIds.fulfilled, (draft, { payload }) => {
      draft.sopIds = payload;
    });

    builder.addCase(getSopFiles.fulfilled, (draft, { payload }) => {
      draft.files = payload;
    });

    builder.addCase(deleteSopFile.fulfilled, (draft, { payload }) => {
      draft.files = draft.files.filter(file => file.id !== payload.fileId);

      const sop = draft.sops.list.find(el => el.id === payload.sopId);

      if (sop) {
        sop.noOfFiles = +sop.noOfFiles - 1;
      }
    });

    builder.addCase(addSopFiles.fulfilled, (draft, { payload }) => {
      draft.files.push(...payload.data.uploaded);

      const sop = draft.sops.list.find(el => el.id === payload.sopId);

      if (sop) {
        sop.noOfFiles = +sop.noOfFiles + payload.data.uploaded.length;
      }

      if (payload.data.failed.length) {
        errorNotification(
          new Error(`${payload.data.failed.join(', ')} failed to upload`),
        );
      }
    });

    builder.addMatcher(
      isAnyOf(
        addSop.rejected,
        editSop.rejected,
        getSops.rejected,
        getSop.rejected,
        getSopIds.rejected,
        getSopFiles.rejected,
        deleteSopFile.rejected,
        addSopFiles.rejected,
      ),
      (_Draft, { error }) => {
        errorNotification(error);
      },
    );
  },
});

export const { clearSops, clearSop, clearSopIds, clearSopFiles } = sops.actions;

export default sops.reducer;
