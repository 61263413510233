import { createAsyncThunk, createSlice, isAnyOf } from '@reduxjs/toolkit';

import { errorNotification } from '../../components/notifications';
import api from '../../libs/api';

const initialState = {
  products: {
    list: [],
    page: 1,
    query: {
      page: 1,
    },
    totalPages: 1,
    totalProducts: 0,
  },
  product: {},
  productIds: [],
  files: [],
  coa: { tests: [] },
  reports: {
    lastSellingGP: [],
    compositionGP: [],
  },
  ledger: [],
};

export const getLastSellingGP = createAsyncThunk(
  'products/getLastSellingGP',
  async query => {
    const { data } = await api.get(`/products/reports/gp`, query);

    return data;
  },
);

export const getCompositionGP = createAsyncThunk(
  'products/getCompositionGP',
  async query => {
    const { data } = await api.get(`/products/reports/gp/compositions`, query);

    return data;
  },
);

export const getProductLedger = createAsyncThunk(
  'products/getProductLedger',
  async ({ id, consignorId, ...query }) => {
    const { data } = await api.get(
      `/products/ledger/${id}/${consignorId}`,
      query,
    );

    return data;
  },
);

export const getProducts = createAsyncThunk(
  'products/getProducts',
  async (query, { getState }) => {
    const currentQuery = getState().products.products.query;

    const effectiveQuery = query || currentQuery;

    const { data } = await api.get('/products', effectiveQuery);

    return { data, query: effectiveQuery };
  },
);

export const getProduct = createAsyncThunk(
  'products/getProduct',
  async ({ id, query }) => {
    const { data } = await api.get(`/products/${id}`, query);

    return data;
  },
);

export const deleteProduct = createAsyncThunk(
  'products/deleteProduct',
  async (id, { getState }) => {
    const userName = getState().loginInfo.user.name;

    await api.delete(`/products/${id}`);

    return { id, userName };
  },
);

export const restoreProduct = createAsyncThunk(
  'products/restoreProduct',
  async (id, { getState }) => {
    const userName = getState().loginInfo.user.name;

    await api.put(`/products/${id}/restore`);

    return { id, userName };
  },
);

export const getProductIds = createAsyncThunk(
  'products/getProductIds',
  async query => {
    const { data } = await api.get('/products/ids', query);

    return data;
  },
);

export const getProductFiles = createAsyncThunk(
  'products/getProductFiles',
  async ({ productId, orderId }) => {
    const { data } = await api.get(`/products/files/${productId}`, { orderId });

    return data;
  },
);

export const deleteProductFile = createAsyncThunk(
  'products/deleteProductFiles',
  async ({ fileId, productId }) => {
    await api.delete(`/products/files/${fileId}`);

    return { fileId, productId };
  },
);

export const addProductFiles = createAsyncThunk(
  'products/addProductFiles',
  async ({ productId, files }) => {
    if (!files || !files.length) {
      throw new Error('Please select files first');
    }

    const formData = new FormData();

    files.forEach((file, index) => {
      formData.append(`files[${index}]`, file);
    });

    const { data } = await api.post(`/products/files/${productId}`, formData, {
      headers: {
        'content-type': 'multipart/form-data',
      },
    });

    return { data, productId };
  },
);

export const getProductCoa = createAsyncThunk(
  'products/getProductCOA',
  async ({ id, type }) => {
    const { data } = await api.get(`/products/${id}/coa/${type}`);

    return data;
  },
);

const products = createSlice({
  name: 'products',

  initialState,

  reducers: {
    clearProducts: draft => {
      draft.products = initialState.products;
    },

    clearProduct: draft => {
      draft.product = initialState.product;
    },

    clearProductIds: draft => {
      draft.productIds = initialState.productIds;
    },

    clearProductFiles: draft => {
      draft.files = initialState.files;
    },

    appendProductFiles: (draft, { payload }) => {
      draft.files.push(...payload);
    },

    clearProductCoa: draft => {
      draft.coa = initialState.coa;
    },

    clearLastSellingGP: draft => {
      draft.reports.lastSellingGP = initialState.reports.lastSellingGP;
    },

    clearCompositionGP: draft => {
      draft.reports.compositionGP = initialState.reports.compositionGP;
    },

    clearProductLedger: draft => {
      draft.ledger = initialState.ledger;
    },
  },

  extraReducers: builder => {
    builder.addCase(getProducts.fulfilled, (draft, { payload }) => {
      draft.products.list = payload.data.products;
      draft.products.page = payload.data.page;
      draft.products.query = payload.query;
      draft.products.totalProducts = payload.data.totalProducts;
      draft.products.totalPages = payload.data.totalPages;
    });

    builder.addCase(getProduct.fulfilled, (draft, { payload }) => {
      draft.product = payload;
    });

    builder.addCase(deleteProduct.fulfilled, (draft, { payload }) => {
      const product = draft.products.list.find(el => el.id === payload.id);

      if (product) {
        product.deletedAt = new Date().toISOString();
        product.deletedBy = payload.userName;
      }
    });

    builder.addCase(restoreProduct.fulfilled, (draft, { payload }) => {
      const product = draft.products.list.find(el => el.id === payload.id);

      if (product) {
        product.deletedAt = null;
        product.deletedBy = null;
      }
    });

    builder.addCase(getProductIds.fulfilled, (draft, { payload }) => {
      draft.productIds = payload;
    });

    builder.addCase(getProductFiles.fulfilled, (draft, { payload }) => {
      draft.files = payload;
    });

    builder.addCase(deleteProductFile.fulfilled, (draft, { payload }) => {
      draft.files = draft.files.filter(file => file.id !== payload.fileId);

      const product = draft.products.list.find(
        el => el.id === payload.productId,
      );

      if (product) {
        product.noOfFiles = +product.noOfFiles - 1;
      }
    });

    builder.addCase(addProductFiles.fulfilled, (draft, { payload }) => {
      draft.files.push(...payload.data.uploaded);

      const product = draft.products.list.find(
        el => el.id === payload.productId,
      );

      if (product) {
        product.noOfFiles = +product.noOfFiles + payload.data.uploaded.length;
      }

      if (payload.data.failed.length) {
        errorNotification(
          new Error(`${payload.data.failed.join(', ')} failed to upload`),
        );
      }
    });

    builder.addCase(getProductCoa.fulfilled, (draft, { payload }) => {
      draft.coa = payload;
    });

    builder.addCase(getLastSellingGP.fulfilled, (draft, { payload }) => {
      draft.reports.lastSellingGP = payload;
    });

    builder.addCase(getCompositionGP.fulfilled, (draft, { payload }) => {
      draft.reports.compositionGP = payload;
    });

    builder.addCase(getProductLedger.fulfilled, (draft, { payload }) => {
      draft.ledger = payload;
    });

    builder.addMatcher(
      isAnyOf(
        getProducts.rejected,
        getProduct.rejected,
        deleteProduct.rejected,
        restoreProduct.rejected,
        getProductIds.rejected,
        getProductFiles.rejected,
        deleteProductFile.rejected,
        addProductFiles.rejected,
        getProductCoa.rejected,
        getLastSellingGP.rejected,
        getProductLedger.rejected,
        getCompositionGP.rejected,
      ),
      (_Draft, { error }) => {
        errorNotification(error);
      },
    );
  },
});

export const {
  clearProducts,
  clearProduct,
  clearProductIds,
  appendProductFiles,
  clearProductCoa,
  clearProductFiles,
  clearLastSellingGP,
  clearProductLedger,
  clearCompositionGP,
} = products.actions;

export default products.reducer;
