import { createAsyncThunk, createSlice, isAnyOf } from '@reduxjs/toolkit';

import { errorNotification } from '../../components/notifications';
import api from '../../libs/api';

const initialState = {
  purchases: {
    list: [],
    page: 1,
    query: { page: 1 },
    totalPages: 1,
    totalPurchases: 0,
  },
  purchase: { charges: [], materials: [], products: [] },
  purchaseIds: [],
  files: [],
  reports: {
    yearlyPurchaseFigures: { total: 0, parties: [] },
    purchasesWithoutTax: [],
  },
};

export const getPurchasesWithoutTax = createAsyncThunk(
  'purchases/getPurchasesWithoutTax',
  async query => {
    const { data } = await api.get(
      `/purchases/reports/purchaseswithouttax`,
      query,
    );

    return data;
  },
);

export const getYearlyPurchaseFigures = createAsyncThunk(
  'purchases/getYearlyPurchaseFigures',
  async query => {
    const { data } = await api.get(`/purchases/reports/figures/yearly`, query);

    return data;
  },
);

export const getPurchases = createAsyncThunk(
  'purchases/getPurchases',
  async (query, { getState }) => {
    const currentQuery = getState().purchases.purchases.query;

    const effectiveQuery = query || currentQuery;

    const { data } = await api.get('/purchases', effectiveQuery);

    return { data, query: effectiveQuery };
  },
);

export const getPurchase = createAsyncThunk(
  'purchases/getPurchase',
  async id => {
    const { data } = await api.get(`/purchases/${id}`);

    return data;
  },
);

export const deletePurchase = createAsyncThunk(
  'purchases/deletePurchase',
  async (id, { getState }) => {
    const userName = getState().loginInfo.user.name;

    await api.delete(`/purchases/${id}`);

    return { id, userName };
  },
);

export const getPurchaseIds = createAsyncThunk(
  'purchases/getPurchaseIds',
  async () => {
    const { data } = await api.get('/purchases/ids');

    return data;
  },
);

export const getPurchaseFiles = createAsyncThunk(
  'purchases/getPurchaseFiles',
  async purchaseId => {
    const { data } = await api.get(`/purchases/files/${purchaseId}`);

    return data;
  },
);

export const deletePurchaseFile = createAsyncThunk(
  'purchases/deletePurchaseFiles',
  async ({ fileId, purchaseId }) => {
    await api.delete(`/purchases/files/${fileId}`);

    return { fileId, purchaseId };
  },
);

export const addPurchaseFiles = createAsyncThunk(
  'purchases/addPurchaseFiles',
  async ({ purchaseId, files }) => {
    if (!files || !files.length) {
      throw new Error('Please select files first');
    }

    const formData = new FormData();

    files.forEach((file, index) => {
      formData.append(`files[${index}]`, file);
    });

    const { data } = await api.post(
      `/purchases/files/${purchaseId}`,
      formData,
      {
        headers: {
          'content-type': 'multipart/form-data',
        },
      },
    );

    return { data, purchaseId };
  },
);

const purchases = createSlice({
  name: 'purchases',

  initialState,

  reducers: {
    clearPurchases: draft => {
      draft.purchases = initialState.purchases;
    },

    clearPurchase: draft => {
      draft.purchase = initialState.purchase;
    },

    clearPurchaseIds: draft => {
      draft.purchaseIds = initialState.purchaseIds;
    },

    clearPurchaseFiles: draft => {
      draft.files = initialState.files;
    },

    clearYearlyPurchaseFigures: draft => {
      draft.reports.yearlyPurchaseFigures =
        initialState.reports.yearlyPurchaseFigures;
    },

    clearPurchasesWithoutTax: draft => {
      draft.reports.purchasesWithoutTax =
        initialState.reports.purchasesWithoutTax;
    },
  },

  extraReducers: builder => {
    builder.addCase(getPurchases.fulfilled, (draft, { payload }) => {
      draft.purchases.list = payload.data.purchases;
      draft.purchases.page = payload.data.page;
      draft.purchases.query = payload.query;
      draft.purchases.totalPurchases = payload.data.totalPurchases;
      draft.purchases.totalPages = payload.data.totalPages;
    });

    builder.addCase(getPurchase.fulfilled, (draft, { payload }) => {
      draft.purchase = payload;
    });

    builder.addCase(deletePurchase.fulfilled, (draft, { payload }) => {
      const _purchases = [...draft.purchases.list];

      _purchases.splice(
        _purchases.findIndex(el => el.id === payload.id),
        1,
      );

      draft.purchases.list = _purchases;
    });

    builder.addCase(getPurchaseIds.fulfilled, (draft, { payload }) => {
      draft.purchaseIds = payload;
    });

    builder.addCase(getPurchaseFiles.fulfilled, (draft, { payload }) => {
      draft.files = payload;
    });

    builder.addCase(deletePurchaseFile.fulfilled, (draft, { payload }) => {
      draft.files = draft.files.filter(file => file.id !== payload.fileId);

      const purchase = draft.purchases.list.find(
        el => el.id === payload.purchaseId,
      );

      if (purchase) {
        purchase.noOfFiles = +purchase.noOfFiles - 1;
      }
    });

    builder.addCase(addPurchaseFiles.fulfilled, (draft, { payload }) => {
      draft.files.push(...payload.data.uploaded);

      const purchase = draft.purchases.list.find(
        el => el.id === payload.purchaseId,
      );

      if (purchase) {
        purchase.noOfFiles = +purchase.noOfFiles + payload.data.uploaded.length;
      }

      if (payload.data.failed.length) {
        errorNotification(
          new Error(`${payload.data.failed.join(', ')} failed to upload`),
        );
      }
    });

    builder.addCase(
      getYearlyPurchaseFigures.fulfilled,
      (draft, { payload }) => {
        draft.reports.yearlyPurchaseFigures = payload;
      },
    );

    builder.addCase(getPurchasesWithoutTax.fulfilled, (draft, { payload }) => {
      draft.reports.purchasesWithoutTax = payload;
    });

    builder.addMatcher(
      isAnyOf(
        getPurchases.rejected,
        getPurchase.rejected,
        deletePurchase.rejected,
        getPurchaseIds.rejected,
        getPurchaseFiles.rejected,
        deletePurchaseFile.rejected,
        addPurchaseFiles.rejected,
        getYearlyPurchaseFigures.rejected,
        getPurchasesWithoutTax.rejected,
      ),
      (_Draft, { error }) => {
        errorNotification(error);
      },
    );
  },
});

export const {
  clearPurchases,
  clearPurchase,
  clearPurchaseIds,
  clearYearlyPurchaseFigures,
  clearPurchaseFiles,
  clearPurchasesWithoutTax,
} = purchases.actions;

export default purchases.reducer;
