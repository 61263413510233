import { createAsyncThunk, createSlice, isAnyOf } from '@reduxjs/toolkit';

import { errorNotification } from '../../../components/notifications';
import api from '../../../libs/api';

const initialState = {
  packings: [],
  packing: {},
  packingIds: [],
};

export const getPackings = createAsyncThunk(
  'dropdowns/packings/getPackings',
  async () => {
    const { data } = await api.get('/dropdowns/packings');

    return data;
  },
);

export const getPackingIds = createAsyncThunk(
  'dropdowns/packings/getPackingIds',
  async () => {
    const { data } = await api.get('/dropdowns/packings/ids');

    return data;
  },
);

export const getPacking = createAsyncThunk(
  'dropdowns/packings/getPacking',
  async id => {
    const { data } = await api.get(`/dropdowns/packings/${id}`);

    return data;
  },
);

export const deletePacking = createAsyncThunk(
  'dropdowns/packings/deletePacking',
  async id => {
    await api.delete(`/dropdowns/packings/${id}`);

    return id;
  },
);

export const addPacking = createAsyncThunk(
  'dropdowns/packings/addPacking',
  async data => {
    const { data: packing } = await api.post('/dropdowns/packings', data);

    return packing;
  },
);

export const editPacking = createAsyncThunk(
  'dropdowns/packings/editPacking',
  async ({ id, data }) => {
    const { data: packing } = await api.put(`/dropdowns/packings/${id}`, data);

    return packing;
  },
);

const packings = createSlice({
  name: 'packings',

  initialState,

  reducers: {
    clearPackings: draft => {
      draft.packings = initialState.packings;
    },

    clearPacking: draft => {
      draft.packing = initialState.packing;
    },

    clearPackingIds: draft => {
      draft.packingIds = initialState.packingIds;
    },
  },

  extraReducers: builder => {
    builder.addCase(getPackings.fulfilled, (draft, { payload }) => {
      draft.packings = payload;
    });

    builder.addCase(getPacking.fulfilled, (draft, { payload }) => {
      draft.packing = payload;
    });

    builder.addCase(deletePacking.fulfilled, (draft, { payload }) => {
      draft.packings = draft.packings.filter(el => el.id !== payload);
    });

    builder.addCase(addPacking.fulfilled, (draft, { payload }) => {
      draft.packings.push(payload);
    });

    builder.addCase(editPacking.fulfilled, (draft, { payload }) => {
      const index = draft.packings.findIndex(el => el.id === payload.id);

      draft.packings[index] = payload;
    });

    builder.addCase(getPackingIds.fulfilled, (draft, { payload }) => {
      draft.packingIds = payload;
    });

    builder.addMatcher(
      isAnyOf(
        getPackings.rejected,
        getPacking.rejected,
        deletePacking.rejected,
        addPacking.rejected,
        editPacking.rejected,
        getPackingIds.rejected,
      ),
      (_Draft, { error }) => {
        errorNotification(error);
      },
    );
  },
});

export const { clearPackings, clearPacking, clearPackingIds } =
  packings.actions;

export default packings.reducer;
