import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { Layout } from 'antd';
import withStyles from 'react-jss';
import { MenuUnfoldOutlined, MenuFoldOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';

import AvatarMenu from './AvatarMenu';

import styles from './styles';

const { Header } = Layout;

const Navbar = props => {
  const { classes, setVisible, visible } = props;

  const toggleDrawerCollapse = useCallback(() => {
    setVisible(!visible);
  }, [visible]);

  return (
    <Header className={classes.header}>
      <Link to="/">
        <img src="/logo.png" alt="logo" className={classes.logo} />
      </Link>
      <div
        onClick={toggleDrawerCollapse}
        role="button"
        tabIndex={0}
        onKeyPress={toggleDrawerCollapse}
        className={classes.collapseIcon}
      >
        {visible ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
      </div>
      <div className={classes.avatarMenu}>
        <AvatarMenu />
      </div>
    </Header>
  );
};

Navbar.propTypes = {
  classes: PropTypes.object.isRequired,
  setVisible: PropTypes.func.isRequired,
  visible: PropTypes.bool.isRequired,
};

export default withStyles(styles)(Navbar);
