import { createAsyncThunk, createSlice, isAnyOf } from '@reduxjs/toolkit';

import { errorNotification } from '../../components/notifications';
import api from '../../libs/api';

const initialState = {
  transactions: {
    list: [],
    page: 1,
    query: { page: 1 },
    totalPages: 1,
    totalTransactions: 0,
  },
  transaction: {},
  transactionIds: [],
};

export const getTransactions = createAsyncThunk(
  'transactions/getTransactions',
  async (query, { getState }) => {
    const currentQuery = getState().transactions.transactions.query;

    const effectiveQuery = query || currentQuery;

    const { data } = await api.get('/transactions', effectiveQuery);

    return { data, query: effectiveQuery };
  },
);

export const getTransactionIds = createAsyncThunk(
  'transactions/getTransactionIds',
  async () => {
    const { data } = await api.get('/transactions/ids');

    return data;
  },
);

export const getTransaction = createAsyncThunk(
  'transactions/getTransaction',
  async id => {
    const { data } = await api.get(`/transactions/${id}`);

    return data;
  },
);

const transactions = createSlice({
  name: 'transactions',

  initialState,

  reducers: {
    clearTransactions: draft => {
      draft.transactions = initialState.transactions;
    },

    clearTransaction: draft => {
      draft.transaction = initialState.transaction;
    },

    clearTransactionIds: draft => {
      draft.transactionIds = initialState.transactionIds;
    },
  },

  extraReducers: builder => {
    builder.addCase(getTransactions.fulfilled, (draft, { payload }) => {
      draft.transactions.list = payload.data.transactions;
      draft.transactions.page = payload.data.page;
      draft.transactions.query = payload.query;
      draft.transactions.totalTransactions = payload.data.totalTransactions;
      draft.transactions.totalPages = payload.data.totalPages;
    });

    builder.addCase(getTransaction.fulfilled, (draft, { payload }) => {
      draft.transaction = payload;
    });

    builder.addCase(getTransactionIds.fulfilled, (draft, { payload }) => {
      draft.transactionIds = payload;
    });

    builder.addMatcher(
      isAnyOf(
        getTransactions.rejected,
        getTransaction.rejected,
        getTransactionIds.rejected,
      ),
      (_Draft, { error }) => {
        errorNotification(error);
      },
    );
  },
});

export const { clearTransactions, clearTransaction, clearTransactionIds } =
  transactions.actions;

export default transactions.reducer;
