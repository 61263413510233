import { createAsyncThunk, createSlice, isAnyOf } from '@reduxjs/toolkit';

import { errorNotification } from '../../components/notifications';
import api from '../../libs/api';

const initialState = {
  cdns: {
    list: [],
    page: 1,
    query: { page: 1 },
    totalPages: 1,
    totalCdns: 0,
  },
  cdn: { charges: [], materials: [], products: [] },
  files: [],
};

export const getCdns = createAsyncThunk(
  'cdns/getCdns',
  async (query, { getState }) => {
    const effectiveQuery = query || getState().cdns.cdns.query;

    const { data } = await api.get('/cd-notes', effectiveQuery);

    return { data, query: effectiveQuery };
  },
);

export const getCdn = createAsyncThunk('cdns/getCdn', async id => {
  const { data } = await api.get(`/cd-notes/${id}`);

  return data;
});

export const deleteCdn = createAsyncThunk(
  'cdns/deleteCdn',
  async (id, { getState }) => {
    const userName = getState().loginInfo.user.name;

    await api.delete(`/cd-notes/${id}`);

    return { id, userName };
  },
);

export const getCdnFiles = createAsyncThunk('cdns/getCdnFiles', async cdnId => {
  const { data } = await api.get(`/cd-notes/files/${cdnId}`);

  return data;
});

export const deleteCdnFile = createAsyncThunk(
  'cdns/deleteCdnFile',
  async ({ fileId, cdnId }) => {
    await api.delete(`/cd-notes/files/${fileId}`);

    return { fileId, cdnId };
  },
);

export const addCdnFiles = createAsyncThunk(
  'cdns/addCdnFiles',
  async ({ cdnId, files }) => {
    if (!files || !files.length) {
      throw new Error('Please select files first');
    }

    const formData = new FormData();

    files.forEach((file, index) => {
      formData.append(`files[${index}]`, file);
    });

    const { data } = await api.post(`/cd-notes/files/${cdnId}`, formData, {
      headers: {
        'content-type': 'multipart/form-data',
      },
    });

    return { data, cdnId };
  },
);

const cdns = createSlice({
  name: 'cdns',

  initialState,

  reducers: {
    clearCdns: draft => {
      draft.cdns = initialState.cdns;
    },

    clearCdn: draft => {
      draft.cdn = initialState.cdn;
    },

    clearCdnFiles: draft => {
      draft.files = initialState.files;
    },
  },

  extraReducers: builder => {
    builder.addCase(getCdns.fulfilled, (draft, { payload }) => {
      draft.cdns.list = payload.data.cdns;
      draft.cdns.page = payload.data.page;
      draft.cdns.query = payload.query;
      draft.cdns.totalCdns = payload.data.totalCdns;
      draft.cdns.totalPages = payload.data.totalPages;
    });

    builder.addCase(getCdn.fulfilled, (draft, { payload }) => {
      draft.cdn = payload;
    });

    builder.addCase(deleteCdn.fulfilled, (draft, { payload }) => {
      const index = draft.cdns.list.findIndex(el => el.id === payload.id);

      if (index > -1) {
        draft.cdns.list.splice(index, 1);
      }
    });

    builder.addCase(getCdnFiles.fulfilled, (draft, { payload }) => {
      draft.files = payload;
    });

    builder.addCase(deleteCdnFile.fulfilled, (draft, { payload }) => {
      draft.files = draft.files.filter(file => file.id !== payload.fileId);

      const cdn = draft.cdns.list.find(el => el.id === payload.cdnId);

      if (cdn) {
        cdn.noOfFiles = +cdn.noOfFiles - 1;
      }
    });

    builder.addCase(addCdnFiles.fulfilled, (draft, { payload }) => {
      draft.files.push(...payload.data.uploaded);

      const cdn = draft.cdns.list.find(el => el.id === payload.cdnId);

      if (cdn) {
        cdn.noOfFiles = +cdn.noOfFiles + payload.data.uploaded.length;
      }

      if (payload.data.failed.length) {
        errorNotification(
          new Error(`${payload.data.failed.join(', ')} failed to upload`),
        );
      }
    });

    builder.addMatcher(
      isAnyOf(
        getCdns.rejected,
        getCdn.rejected,
        deleteCdn.rejected,
        getCdnFiles.rejected,
        deleteCdnFile.rejected,
        addCdnFiles.rejected,
      ),
      (_Draft, { error }) => {
        errorNotification(error);
      },
    );
  },
});

export const { clearCdns, clearCdn } = cdns.actions;

export default cdns.reducer;
