const CONSTANTS = {
  title: 'Sarthak Hive',
  user: {
    roles: {
      owner: 'Owner',
    },
  },
  party: {
    types: {
      customer: 'Customer',
      supplier: 'Supplier',
      manufacturer: 'Manufacturer',
      transporter: 'Transporter',
      ecommerce: 'Ecommerce',
    },
    paymentCategory: {
      advance: 'Advance',
      credit: 'Credit',
    },
  },
  material: {
    types: {
      raw: 'Raw',
      packing: 'Packing',
      secondaryPacking: 'Secondary Packing',
      consumable: 'Consumable',
      // finishedGoods: 'Finished Goods (Outsource)',
    },
    ingredientTypes: {
      Raw: {
        active: 'Active',
        excipient: 'Excipient',
        color: 'Color',
        coating: 'Coating',
        flavor: 'Flavor',
        fragrance: 'Fragrance',
        shell: 'Shell',
      },
      Packing: {
        printedFoil: 'Printed Foil',
        printedBox: 'Printed Box',
        printedInsert: 'Printed Insert',
        label: 'Label',
        tube: 'Tube',
        bag: 'Bag',
      },
      'Secondary Packing': {
        bottle: 'Bottle',
        foil: 'Foil',
        box: 'Box',
        cap: 'Cap',
        pvc: 'PVC',
        tape: 'Tape',
        belt: 'Belt',
        dropper: 'Dropper',
        bag: 'Bag',
        wrap: 'Wrap',
      },
      Consumable: {
        consumable: 'Consumable',
        cylinder: 'Cylinder',
      },
      'Finished Goods (Outsource)': {
        'Finished Goods (Outsource)': 'Finished Goods (Outsource)',
      },
      Services: {
        services: 'Services',
      },
    },
  },
  stockAdjustment: {
    types: {
      stockAdd: 'Stock Add',
      stockRemove: 'Stock Remove',
    },
  },
  product: {
    categories: {
      beta: 'Beta',
      nonBeta: 'Non Beta',
      food: 'Food',
      ayush: 'Ayush',
      finishedGoods: 'Finished Goods (Outsource)',
    },
    subcategories: {
      capsule: 'Capsule',
      liquid: 'Liquid',
      ointmentExternal: 'Ointment/External',
      soap: 'Soap',
      tablet: 'Tablet',
      drySyrup: 'Dry Syrup',
      dustingPowder: 'Dusting Powder',
      sachet: 'Sachet',
      other: 'Other',
      finishedGoods: 'Finished Goods (Outsource)',
    },
    specification: {
      ip: 'IP',
      bp: 'BP',
      usp: 'USP',
      other: 'Other',
    },
    testingParameter: {
      dt: 'DT',
      diso: 'DISO',
    },
  },
  order: {
    types: {
      government: 'Government',
      export: 'Export',
      other: 'Other',
      thirdParty: 'Third Party',
    },
  },
  production: {
    coating: {
      sugar: 'Sugar',
      film: 'Film',
      enteric: 'Enteric',
    },
  },
  machine: {
    sections: {
      liquid: 'Liquid',
      capsule: 'Capsule',
      rawMaterial: 'Raw Material',
      tablet: 'Tablet',
      packingNonBeta: 'Packing Non Beta',
      packingBetaLactum: 'Packing Beta Lactum',
      coating: 'Coating',
      dustingPowder: 'Dusting Powder',
      ointmentExternal: 'Ointment/External',
      soap: 'Soap',
      drySyrup: 'Dry Syrup',
      sachet: 'Sachet',
    },
    locations: {
      Liquid: {
        solutionPreparationRoom: 'Solution Preparation Room',
        liquidFIllingAndSealingRoom: 'Liquid Filling And Sealing Room',
        liquidPackingArea: 'Liquid Packing Area',
      },
      Capsule: {
        capsuleNonBeta: 'Capsule Non Beta',
        capsuleBetaLactam: 'Capsule Beta Lactum',
      },
      'Raw Material': {
        rawMaterialNonBeta: 'Raw Material Non Beta',
        rawMaterialBetaLactum: 'Raw Material Beta Lactum',
      },
      Tablet: {
        tabletNonBeta: 'Tablet Non Beta',
        tabletBetaLactum: 'Tablet Beta Lactum',
      },
      'Packing Non Beta': {
        blisterMachineRoom: 'Blister Machine Room',
        aluAluMachineRoom: 'Alu-Alu Machine Room',
      },
      'Packing Beta Lactum': {
        aluAluMachineRoom: 'Alu-Alu Machine Room',
      },
      Coating: {
        coatingNonBeta: 'Coating Non Beta',
        coatingBetaLactum: 'Coating Beta Lactum',
      },
      'Dusting Powder': {
        dustingPowderNonBeta: 'Dusting Powder Non Beta',
        dustingPowderBetaLactum: 'Dusting Powder Beta Lactum',
      },
      'Ointment/External': {
        ointmentExternal: 'Ointment/External',
      },
      Soap: { soap: 'Soap' },
      'Dry Syrup': { drySyrup: 'Dry Syrup' },
      Sachet: { sachet: 'Sachet' },
    },
  },
  invoice: {
    cancelReason: {
      1: 'Duplicate',
      2: 'Data entry mistake',
      3: 'Order Cancelled',
      4: 'Others',
    },
  },
  cdn: {
    types: {
      credit: 'Credit Note',
      debit: 'Debit Note',
    },
    reasons: {
      rateDiff: 'Rate Difference',
      quantityDiff: 'Quantity Difference',
      rejection: 'Quantity Rejection',
      discount: 'Discount',
      general: 'General',
    },
  },
  enquiry: {
    types: {
      pcd: 'PCD',
      thirdParty: 'Third Party',
    },
  },
  bank: {
    summaryHeads: {
      directExpenses: 'Direct Expenses',
      indirectExpenses: 'Indirect Expenses',
      directIncomes: 'Direct Incomes',
      indirectIncomes: 'Indirect Incomes',
      otherIncome: 'Other Income',
      manufacturingExpenses: 'Manufacturing Expenses',
      capitalAccount: 'Capital Account',
      loans: 'Loans',
      otherLoans: 'Other Loans',
      currentLiabilities: 'Current Liabilities',
      fixedAssets: 'Fixed Assets',
      currentAssets: 'Current Assets',
      supplierPayment: 'Supplier Payment',
      customerPayment: 'Customer Payment',
    },
    transactions: {
      types: {
        expense: 'Expense',
        income: 'Income',
      },
      whom: {
        party: 'Party',
        contact: 'Contact',
        asset: 'Asset',
      },
    },
  },
  assets: {
    adjust: {
      increment: 'Increment',
      decrement: 'Decrement',
    },
  },
  contact: {
    types: {
      director: 'Director',
      partner: 'Partner',
      employee: 'Employee',
      other: 'Other',
    },
  },
  bill: {
    whom: {
      party: 'Party',
      contact: 'Contact',
    },
  },
  tasks: {
    repeat: {
      daily: 'daily',
      weekly: 'weekly',
      biWeekly: 'bi-weekly',
      monthly: 'monthly',
      customMonths: 'custom-months',
    },
    types: {
      task: 'task',
      event: 'event',
      reminder: 'reminder',
    },
  },
  leads: {
    category: {
      // nBDcRR: 'NBD CRR',
      nBDoUTCOMING: 'NBD Outgoing',
      nBDiNCOMING: 'NBD Incoming',
    },
    sources: {
      // 'NBD CRR': {
      //   self: 'Self',
      //   promptedForWalletShareOrCrossSell:
      //     'Prompted for wallet share or cross sell',
      // },
      'NBD Outgoing': {
        digitalPromotion: 'Digital Promotion',
        seo: 'SEO',
        telecalling: 'Tele Calling',
      },
      'NBD Incoming': {
        website: 'Website',
        referenceFromOthers: 'Reference from Others',
        promptedForWalletShareOrCrossSell:
          'Prompted for wallet share or cross sell',
      },
    },
    statuses: {
      open: 'Open',
      meeting: 'Meeting Done',
      quotation: 'Quotation Sent',
      converted: 'Converted',
      closed: 'Closed',
    },
  },
  interactions: {
    types: {
      call: 'Call',
      email: 'Email',
      virtualMeeting: 'Virtual Meeting',
      inPersonMeeting: 'In-Person Meeting',
    },
    outcomes: {
      Call: {
        noAnswer: 'No Answer',
        busy: 'Busy',
        wrongNumber: 'Wrong Number',
        leftVoicemail: 'Left Voicemail',
        connected: 'Connected',
        callMeAgain: 'Call Me Again',
        missed: 'Missed',
      },
      Email: {
        delivered: 'Delivered',
        wrongEmail: 'Wrong Email',
        deliveryFailure: 'Delivery Failure',
        spam: 'Spam',
      },
      'Virtual Meeting': {
        completed: 'Completed',
        rescheduled: 'Rescheduled',
        cancelled: 'Cancelled',
      },
      'In-Person Meeting': {
        attended: 'Attended',
        notAttended: 'Not Attended',
      },
    },
  },
};

export default CONSTANTS;
