export default {
  background: {
    display: 'flex',
    minHeight: '100vh',
    backgroundColor: '#f8f8f8',
  },
  card: {
    margin: 'auto',
    padding: '36px',
    boxShadow: '0 0 100px rgba(0, 0, 0, 0.15)',
    width: '320px',
    borderRadius: '5px',
  },
  button: {
    width: '100%',
  },
  company: {
    marginBottom: '45px',
    fontSize: '18px',
    display: 'block',
    textAlign: 'center',
    fontWeight: '600',
  },
  linkGroup: {
    marginTop: '35px',
    fontSize: '14px',
    display: 'flex',
    justifyContent: 'space-between',
  },
  link: {
    color: '#0b2933 !important',
  },
};
