import { createAsyncThunk, createSlice, isAnyOf } from '@reduxjs/toolkit';

import { errorNotification } from '../../components/notifications';
import api from '../../libs/api';

const initialState = {
  parties: {
    list: [],
    totalPages: 1,
    page: 1,
    totalParties: 0,
    query: { page: 1 },
  },
  party: {},
  partyIds: [],
  manufacturerIds: [],
  files: [],
  ledger: {
    openingBalance: null,
    ledger: [],
  },
  reports: {
    onTimeReceived: [],
  },
};

export const getOnTimeReceivedReport = createAsyncThunk(
  'parties/getOnTimeReceivedReport',
  async query => {
    const { data } = await api.get('/parties/reports/on-time-delivery', query);

    return data;
  },
);

export const getParties = createAsyncThunk(
  'parties/getParties',
  async (query, { getState }) => {
    const currentQuery = getState().parties.parties.query;

    const effectiveQuery = query || currentQuery;

    const { data } = await api.get('/parties', effectiveQuery);

    return { data, query: effectiveQuery };
  },
);

export const getParty = createAsyncThunk('parties/getParty', async id => {
  const { data } = await api.get(`/parties/${id}`);

  return data;
});

export const getPartyLedger = createAsyncThunk(
  'parties/getPartyLedger',
  async ({ id, consignorId, ...query }) => {
    const { data } = await api.get(
      `/parties/ledger/${id}/${consignorId}`,
      query,
    );

    return data;
  },
);

export const getPartyIds = createAsyncThunk(
  'parties/getPartyIds',
  async query => {
    const { data } = await api.get('/parties/ids', query);

    data.forEach(el => {
      el.string = JSON.stringify(el);
    });

    return data;
  },
);

export const getManufacturerIds = createAsyncThunk(
  'parties/getManufacturerIds',
  async () => {
    const { data } = await api.get('/parties/ids', { type: 'Manufacturer' });

    return data;
  },
);

export const getPartyFiles = createAsyncThunk(
  'parties/getPartyFiles',
  async partyId => {
    const { data } = await api.get(`/parties/files/${partyId}`);

    return data;
  },
);

export const deletePartyFile = createAsyncThunk(
  'parties/deletePartyFiles',
  async ({ fileId, partyId }) => {
    await api.delete(`/parties/files/${fileId}`);

    return { fileId, partyId };
  },
);

export const addPartyFiles = createAsyncThunk(
  'parties/addPartyFiles',
  async ({ partyId, files }) => {
    if (!files || !files.length) {
      throw new Error('Please select files first');
    }

    const formData = new FormData();

    files.forEach((file, index) => {
      formData.append(`files[${index}]`, file);
    });

    const { data } = await api.post(`/parties/files/${partyId}`, formData, {
      headers: {
        'content-type': 'multipart/form-data',
      },
    });

    return { data, partyId };
  },
);

const parties = createSlice({
  name: 'parties',

  initialState,

  reducers: {
    clearParties: draft => {
      draft.parties = initialState.parties;
    },

    clearParty: draft => {
      draft.party = initialState.party;
    },

    clearPartyIds: draft => {
      draft.partyIds = initialState.partyIds;
    },

    clearManufacturerIds: draft => {
      draft.manufacturerIds = initialState.manufacturerIds;
    },

    clearPartyFiles: draft => {
      draft.files = initialState.files;
    },

    clearPartyLedger: draft => {
      draft.ledger = initialState.ledger;
    },

    clearOnTimeReceivedReport: draft => {
      draft.reports.onTimeReceived = initialState.reports.onTimeReceived;
    },
  },

  extraReducers: builder => {
    builder.addCase(getParties.fulfilled, (draft, { payload }) => {
      draft.parties.list = payload.data.parties;
      draft.parties.totalPages = payload.data.totalPages;
      draft.parties.totalParties = payload.data.totalParties;
      draft.parties.page = payload.data.page;

      draft.parties.query = payload.query;
    });

    builder.addCase(getParty.fulfilled, (draft, { payload }) => {
      draft.party = payload;
    });

    builder.addCase(getPartyLedger.fulfilled, (draft, { payload }) => {
      draft.ledger = payload;
    });

    builder.addCase(getPartyIds.fulfilled, (draft, { payload }) => {
      draft.partyIds = payload;
    });

    builder.addCase(getManufacturerIds.fulfilled, (draft, { payload }) => {
      draft.manufacturerIds = payload;
    });

    builder.addCase(getPartyFiles.fulfilled, (draft, { payload }) => {
      draft.files = payload;
    });

    builder.addCase(deletePartyFile.fulfilled, (draft, { payload }) => {
      draft.files = draft.files.filter(file => file.id !== payload.fileId);

      const party = draft.parties.list.find(el => el.id === payload.partyId);

      if (party) {
        party.noOfFiles = +party.noOfFiles - 1;
      }
    });

    builder.addCase(addPartyFiles.fulfilled, (draft, { payload }) => {
      draft.files.push(...payload.data.uploaded);

      const party = draft.parties.list.find(el => el.id === payload.partyId);

      if (party) {
        party.noOfFiles = +party.noOfFiles + payload.data.uploaded.length;
      }

      if (payload.data.failed.length) {
        errorNotification(
          new Error(`${payload.data.failed.join(', ')} failed to upload`),
        );
      }
    });

    builder.addCase(getOnTimeReceivedReport.fulfilled, (draft, { payload }) => {
      draft.reports.onTimeReceived = payload;
    });

    builder.addMatcher(
      isAnyOf(
        getParties.rejected,
        getParty.rejected,
        getPartyIds.rejected,
        getManufacturerIds.rejected,
        getPartyFiles.rejected,
        deletePartyFile.rejected,
        addPartyFiles.rejected,
      ),
      (_draft, { error }) => {
        errorNotification(error);
      },
    );
  },
});

export const {
  clearParties,
  clearParty,
  clearPartyIds,
  clearPartyFiles,
  clearManufacturerIds,
  clearPartyLedger,
  clearOnTimeReceivedReport,
} = parties.actions;

export default parties.reducer;
