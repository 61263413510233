import { createAsyncThunk, createSlice, isAnyOf } from '@reduxjs/toolkit';

import { errorNotification } from '../../components/notifications';
import api from '../../libs/api';

const initialState = {
  machines: {
    list: [],
    page: 1,
    query: {
      page: 1,
    },
    totalPages: 1,
    totalMachines: 0,
  },
  machine: {},
  machineIds: [],
  files: [],
};

export const getMachines = createAsyncThunk(
  'machines/getMachines',
  async (query, { getState }) => {
    const currentQuery = getState().machines.machines.query;

    const effectiveQuery = query || currentQuery;

    const { data } = await api.get('/machines', effectiveQuery);

    return { data, query: effectiveQuery };
  },
);

export const getMachine = createAsyncThunk('machines/getMachine', async id => {
  const { data } = await api.get(`/machines/${id}`);

  return data;
});

export const getMachineIds = createAsyncThunk(
  'machines/getMachineIds',
  async () => {
    const { data } = await api.get('/machines/ids');

    return data;
  },
);

const machines = createSlice({
  name: 'machines',

  initialState,

  reducers: {
    clearMachines: draft => {
      draft.machines = initialState.machines;
    },

    clearMachine: draft => {
      draft.machine = initialState.machine;
    },

    clearMachineIds: draft => {
      draft.machineIds = initialState.machineIds;
    },

    clearMachineFiles: draft => {
      draft.files = initialState.files;
    },
  },

  extraReducers: builder => {
    builder.addCase(getMachines.fulfilled, (draft, { payload }) => {
      draft.machines.list = payload.data.machines;
      draft.machines.page = payload.data.page;
      draft.machines.query = payload.query;
      draft.machines.totalMachines = payload.data.totalMachines;
      draft.machines.totalPages = payload.data.totalPages;
    });

    builder.addCase(getMachine.fulfilled, (draft, { payload }) => {
      draft.machine = payload;
    });

    builder.addCase(getMachineIds.fulfilled, (draft, { payload }) => {
      draft.machineIds = payload;
    });

    builder.addMatcher(
      isAnyOf(
        getMachines.rejected,
        getMachine.rejected,
        getMachineIds.rejected,
      ),
      (_Draft, { error }) => {
        errorNotification(error);
      },
    );
  },
});

export const { clearMachines, clearMachine, clearMachineIds } =
  machines.actions;

export default machines.reducer;
