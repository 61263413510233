import { createAsyncThunk, createSlice, isAnyOf } from '@reduxjs/toolkit';

import { errorNotification } from '../../components/notifications';
import api from '../../libs/api';

const initialState = {
  proformaInvoices: {
    list: [],
    page: 1,
    query: { page: 1 },
    totalPages: 1,
    totalProformaInvoices: 0,
  },
  proformaInvoice: { materials: [], products: [], charges: [] },
  proformaInvoiceIds: [],
  notPunchedPIProducts: [],
  files: [],
  reports: {
    userWiseOrders: [],
    yearlyPIFigures: { total: 0, parties: [] },
  },
};

export const getPIUserWiseOrders = createAsyncThunk(
  'proformaInvoices/getPIUserWiseOrders',
  async ({ from, to }) => {
    const { data } = await api.get(
      '/proforma-invoices/reports/userwiseorders',
      { from, to },
    );

    return data;
  },
);

export const getYearlyPIFigures = createAsyncThunk(
  'proformaInvoices/getYearlyPIFigures',
  async query => {
    const { data } = await api.get(
      `/proforma-invoices/reports/figures/yearly`,
      query,
    );

    return data;
  },
);

export const getProformaInvoices = createAsyncThunk(
  'proformaInvoices/getProformaInvoices',
  async (query, { getState }) => {
    const currentQuery = getState().proformaInvoices.proformaInvoices.query;

    const effectiveQuery = query || currentQuery;

    const { data } = await api.get('/proforma-invoices', effectiveQuery);

    return { data, query: effectiveQuery };
  },
);

export const getProformaInvoice = createAsyncThunk(
  'proformaInvoices/getProformaInvoice',
  async id => {
    const { data } = await api.get(`/proforma-invoices/${id}`);

    return data;
  },
);

export const deleteProformaInvoice = createAsyncThunk(
  'proformaInvoices/deleteProformaInvoice',
  async (id, { getState }) => {
    const userName = getState().loginInfo.user.name;

    await api.delete(`/proforma-invoices/${id}`);

    return { id, userName };
  },
);

export const getProformaInvoiceIds = createAsyncThunk(
  'proformaInvoices/getProformaInvoiceIds',
  async query => {
    const { data } = await api.get('/proforma-invoices/ids', query);

    return data;
  },
);

export const getProformaInvoiceFiles = createAsyncThunk(
  'proformaInvoices/getProformaInvoiceFiles',
  async proformaInvoiceId => {
    const { data } = await api.get(
      `/proforma-invoices/files/${proformaInvoiceId}`,
    );

    return data;
  },
);

export const deleteProformaInvoiceFile = createAsyncThunk(
  'proformaInvoices/deleteProformaInvoiceFiles',
  async ({ fileId, proformaInvoiceId }) => {
    await api.delete(`/proforma-invoices/files/${fileId}`);

    return { fileId, proformaInvoiceId };
  },
);

export const addProformaInvoiceFiles = createAsyncThunk(
  'proformaInvoices/addProformaInvoiceFiles',
  async ({ proformaInvoiceId, files }) => {
    if (!files || !files.length) {
      throw new Error('Please select files first');
    }

    const formData = new FormData();

    files.forEach((file, index) => {
      formData.append(`files[${index}]`, file);
    });

    const { data } = await api.post(
      `/proforma-invoices/files/${proformaInvoiceId}`,
      formData,
      {
        headers: {
          'content-type': 'multipart/form-data',
        },
      },
    );

    return { data, proformaInvoiceId };
  },
);

export const authorizeProformaInvoice = createAsyncThunk(
  'proformaInvoices/authorizeProformaInvoice',
  async id => {
    await api.put(`/proforma-invoices/authorize/${id}`);

    return { id };
  },
);

export const getNotPunchedPIProducts = createAsyncThunk(
  'proformaInvoices/getNotPunchedPIProducts',
  async () => {
    const { data } = await api.get('/proforma-invoices/products/notpunched');

    return data;
  },
);

const proformaInvoices = createSlice({
  name: 'proformaInvoices',

  initialState,

  reducers: {
    clearProformaInvoices: draft => {
      draft.proformaInvoices = initialState.proformaInvoices;
    },

    clearProformaInvoice: draft => {
      draft.proformaInvoice = initialState.proformaInvoice;
    },

    clearProformaInvoiceIds: draft => {
      draft.proformaInvoiceIds = initialState.proformaInvoiceIds;
    },

    clearProformaInvoiceFiles: draft => {
      draft.files = initialState.files;
    },

    clearNotPunchedPIProducts: draft => {
      draft.notPunchedPIProducts = initialState.notPunchedPIProducts;
    },

    clearPIUserWiseOrders: draft => {
      draft.reports.userWiseOrders = initialState.reports.userWiseOrders;
    },

    clearYearlyPIFigures: draft => {
      draft.reports.yearlyPIFigures = initialState.reports.yearlyPIFigures;
    },
  },

  extraReducers: builder => {
    builder.addCase(getProformaInvoices.fulfilled, (draft, { payload }) => {
      draft.proformaInvoices.list = payload.data.proformaInvoices;
      draft.proformaInvoices.page = payload.data.page;
      draft.proformaInvoices.query = payload.query;
      draft.proformaInvoices.totalProformaInvoices =
        payload.data.totalProformaInvoices;
      draft.proformaInvoices.totalPages = payload.data.totalPages;
    });

    builder.addCase(getProformaInvoice.fulfilled, (draft, { payload }) => {
      draft.proformaInvoice = payload;
    });

    builder.addCase(deleteProformaInvoice.fulfilled, (draft, { payload }) => {
      const index = draft.proformaInvoices.list.findIndex(
        el => el.id === payload.id,
      );

      if (index > -1) {
        draft.proformaInvoices.list.splice(index, 1);
      }
    });

    builder.addCase(getProformaInvoiceIds.fulfilled, (draft, { payload }) => {
      draft.proformaInvoiceIds = payload;
    });

    builder.addCase(getNotPunchedPIProducts.fulfilled, (draft, { payload }) => {
      draft.notPunchedPIProducts = payload;
    });

    builder.addCase(getProformaInvoiceFiles.fulfilled, (draft, { payload }) => {
      draft.files = payload;
    });

    builder.addCase(
      deleteProformaInvoiceFile.fulfilled,
      (draft, { payload }) => {
        draft.files = draft.files.filter(file => file.id !== payload.fileId);

        const proformaInvoice = draft.proformaInvoices.list.find(
          el => el.id === payload.proformaInvoiceId,
        );

        if (proformaInvoice) {
          proformaInvoice.noOfFiles = +proformaInvoice.noOfFiles - 1;
        }
      },
    );

    builder.addCase(addProformaInvoiceFiles.fulfilled, (draft, { payload }) => {
      draft.files.push(...payload.data.uploaded);

      const proformaInvoice = draft.proformaInvoices.list.find(
        el => el.id === payload.proformaInvoiceId,
      );

      if (proformaInvoice) {
        proformaInvoice.noOfFiles =
          +proformaInvoice.noOfFiles + payload.data.uploaded.length;
      }

      if (payload.data.failed.length) {
        errorNotification(
          new Error(`${payload.data.failed.join(', ')} failed to upload`),
        );
      }
    });

    builder.addCase(getPIUserWiseOrders.fulfilled, (draft, { payload }) => {
      draft.reports.userWiseOrders = payload;
    });

    builder.addCase(getYearlyPIFigures.fulfilled, (draft, { payload }) => {
      draft.reports.yearlyPIFigures = payload;
    });

    builder.addMatcher(
      isAnyOf(authorizeProformaInvoice.fulfilled),
      (draft, { payload }) => {
        const index = draft.proformaInvoices.list.findIndex(
          el => el.id === payload.id,
        );

        if (index > -1) {
          const newList = [...draft.proformaInvoices.list];
          newList.splice(index, 1);
          draft.proformaInvoices.list = newList;
          draft.proformaInvoices.totalProformaInvoices -= 1;
        }
      },
    );

    builder.addMatcher(
      isAnyOf(
        getProformaInvoices.rejected,
        getProformaInvoice.rejected,
        deleteProformaInvoice.rejected,
        getProformaInvoiceIds.rejected,
        getProformaInvoiceFiles.rejected,
        deleteProformaInvoiceFile.rejected,
        addProformaInvoiceFiles.rejected,
        authorizeProformaInvoice.rejected,
        getNotPunchedPIProducts.rejected,
        getPIUserWiseOrders.rejected,
        getYearlyPIFigures.rejected,
      ),
      (_Draft, { error }) => {
        errorNotification(error);
      },
    );
  },
});

export const {
  clearProformaInvoices,
  clearProformaInvoice,
  clearProformaInvoiceIds,
  clearNotPunchedPIProducts,
  clearPIUserWiseOrders,
  clearProformaInvoiceFiles,
  clearYearlyPIFigures,
} = proformaInvoices.actions;

export default proformaInvoices.reducer;
