import { createAsyncThunk, createSlice, isAnyOf } from '@reduxjs/toolkit';

import { errorNotification } from '../../components/notifications';
import api from '../../libs/api';

const initialState = {
  saleOrders: {
    list: [],
    page: 1,
    query: { page: 1 },
    totalPages: 1,
    totalSaleOrders: 0,
  },
  saleOrder: { materials: [], products: [], charges: [] },
  files: [],
  reports: {},
};

export const getSaleOrders = createAsyncThunk(
  'saleOrders/getSaleOrders',
  async (query, { getState }) => {
    const currentQuery = getState().saleOrders.saleOrders.query;

    const effectiveQuery = query || currentQuery;

    const { data } = await api.get('/sale-orders', effectiveQuery);

    return { data, query: effectiveQuery };
  },
);

export const getSaleOrder = createAsyncThunk(
  'saleOrders/getSaleOrder',
  async id => {
    const { data } = await api.get(`/sale-orders/${id}`);

    return data;
  },
);

export const deleteSaleOrder = createAsyncThunk(
  'saleOrders/deleteSaleOrder',
  async (id, { getState }) => {
    const userName = getState().loginInfo.user.name;

    await api.delete(`/sale-orders/${id}`);

    return { id, userName };
  },
);

export const getSaleOrderFiles = createAsyncThunk(
  'saleOrders/getSaleOrderFiles',
  async saleOrderId => {
    const { data } = await api.get(`/sale-orders/files/${saleOrderId}`);

    return data;
  },
);

export const deleteSaleOrderFile = createAsyncThunk(
  'saleOrders/deleteSaleOrderFiles',
  async ({ fileId, saleOrderId }) => {
    await api.delete(`/sale-orders/files/${fileId}`);

    return { fileId, saleOrderId };
  },
);

export const makeSOReadyForInvoice = createAsyncThunk(
  'saleOrders/makeSOReadyForInvoice',
  async soId => {
    await api.patch(`/sale-orders/${soId}/ready-for-sale`);

    return soId;
  },
);

export const addSaleOrderFiles = createAsyncThunk(
  'saleOrders/addSaleOrderFiles',
  async ({ saleOrderId, files }) => {
    if (!files || !files.length) {
      throw new Error('Please select files first');
    }

    const formData = new FormData();

    files.forEach((file, index) => {
      formData.append(`files[${index}]`, file);
    });

    const { data } = await api.post(
      `/sale-orders/files/${saleOrderId}`,
      formData,
      {
        headers: {
          'content-type': 'multipart/form-data',
        },
      },
    );

    return { data, saleOrderId };
  },
);

const saleOrders = createSlice({
  name: 'saleOrders',

  initialState,

  reducers: {
    clearSaleOrders: draft => {
      draft.saleOrders = initialState.saleOrders;
    },

    clearSaleOrder: draft => {
      draft.saleOrder = initialState.saleOrder;
    },

    clearSaleOrderFiles: draft => {
      draft.files = initialState.files;
    },
  },

  extraReducers: builder => {
    builder.addCase(getSaleOrders.fulfilled, (draft, { payload }) => {
      draft.saleOrders.list = payload.data.saleOrders;
      draft.saleOrders.page = payload.data.page;
      draft.saleOrders.query = payload.query;
      draft.saleOrders.totalSaleOrders = payload.data.totalSaleOrders;
      draft.saleOrders.totalPages = payload.data.totalPages;
    });

    builder.addCase(getSaleOrder.fulfilled, (draft, { payload }) => {
      draft.saleOrder = payload;
    });

    builder.addCase(deleteSaleOrder.fulfilled, (draft, { payload }) => {
      const index = draft.saleOrders.list.findIndex(el => el.id === payload.id);

      if (index > -1) {
        draft.saleOrders.list.splice(index, 1);
      }
    });

    builder.addCase(getSaleOrderFiles.fulfilled, (draft, { payload }) => {
      draft.files = payload;
    });

    builder.addCase(makeSOReadyForInvoice.fulfilled, (draft, { payload }) => {
      draft.saleOrders.list = draft.saleOrders.list.filter(
        el => el.id !== payload,
      );
    });

    builder.addCase(deleteSaleOrderFile.fulfilled, (draft, { payload }) => {
      draft.files = draft.files.filter(file => file.id !== payload.fileId);

      const saleOrder = draft.saleOrders.list.find(
        el => el.id === payload.saleOrderId,
      );

      if (saleOrder) {
        saleOrder.noOfFiles = +saleOrder.noOfFiles - 1;
      }
    });

    builder.addCase(addSaleOrderFiles.fulfilled, (draft, { payload }) => {
      draft.files.push(...payload.data.uploaded);

      const saleOrder = draft.saleOrders.list.find(
        el => el.id === payload.saleOrderId,
      );

      if (saleOrder) {
        saleOrder.noOfFiles =
          +saleOrder.noOfFiles + payload.data.uploaded.length;
      }

      if (payload.data.failed.length) {
        errorNotification(
          new Error(`${payload.data.failed.join(', ')} failed to upload`),
        );
      }
    });

    builder.addMatcher(
      isAnyOf(
        getSaleOrders.rejected,
        getSaleOrder.rejected,
        deleteSaleOrder.rejected,
        getSaleOrderFiles.rejected,
        deleteSaleOrderFile.rejected,
        addSaleOrderFiles.rejected,
      ),
      (_Draft, { error }) => {
        errorNotification(error);
      },
    );
  },
});

export const { clearSaleOrders, clearSaleOrder, clearSaleOrderFiles } =
  saleOrders.actions;

export default saleOrders.reducer;
