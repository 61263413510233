import { createAsyncThunk, createSlice, isAnyOf } from '@reduxjs/toolkit';

import { errorNotification } from '../../components/notifications';
import api from '../../libs/api';
import { appendProductFiles } from './products';

const initialState = {
  orders: {
    list: [],
    page: 1,
    query: { page: 1 },
    totalPages: 1,
    totalOrders: 0,
    totalAmount: 0,
    materialAmount: 0,
    date: Date.now(),
  },
  order: {},
  finishedIssue: {},
  orderIds: [],
  orderHoldings: {
    materials: { raw: [], others: [] },
    products: [],
    rawMaterialsDispensed: true,
    otherMaterialsDispensed: true,
    recipeVerifiedAt: true,
    recipeVerifiedBy: '',
  },
  recipe: [],
  reports: {
    fms: {
      list: [],
      page: 1,
      query: { page: 1 },
      totalPages: 1,
      totalOrders: 0,
    },
    mis: {
      weeks: [],
      data: [],
    },
    onTimeDelivery: [],
  },
};

export const updateOrderMrp = createAsyncThunk(
  'orders/updateOrderMrp',
  async ({ id, mrp }) => {
    await api.patch(`/orders/${id}/mrp/`, { mrp });

    return { id, mrp };
  },
);

export const updateOrderRemark = createAsyncThunk(
  'orders/updateOrderRemark',
  async ({ id, remark }) => {
    await api.patch(`/orders/${id}/remark/`, { remark });

    return { id, remark };
  },
);

export const getOnTimeDeliveryReport = createAsyncThunk(
  'orders/getOnTimeDeliveryReport',
  async query => {
    const { data } = await api.get('/orders/reports/on-time-delivery', query);

    return data;
  },
);

export const getOrderFMS = createAsyncThunk(
  'orders/getOrderFMS',
  async query => {
    const { data } = await api.get(`/orders/reports/fms`, query);

    return data;
  },
);

export const getOrderMIS = createAsyncThunk(
  'orders/getOrderMIS',
  async query => {
    const { data } = await api.get(`/orders/reports/mis`, query);

    return data;
  },
);

export const getOrders = createAsyncThunk(
  'orders/getOrders',
  async (query, { getState }) => {
    const currentQuery = getState().orders.orders.query;

    const effectiveQuery = query || currentQuery;

    const date = Date.now();

    const { data } = await api.get('/orders', effectiveQuery);

    return { data, query: effectiveQuery, date };
  },
);

export const getOrder = createAsyncThunk('orders/getOrder', async id => {
  const { data } = await api.get(`/orders/${id}`);

  return data;
});

export const getOrderRecipe = createAsyncThunk(
  'orders/getOrderRecipe',
  async id => {
    const { data } = await api.get(`/orders/${id}/recipe`);

    return data;
  },
);

export const getFinishedIssue = createAsyncThunk(
  'orders/getFinishedIssue',
  async id => {
    const { data } = await api.get(`/orders/finishedissue/${id}`);

    return data;
  },
);

export const deleteOrder = createAsyncThunk(
  'orders/deleteOrder',
  async orderId => {
    await api.delete(`/orders/${orderId}`);

    return { orderId };
  },
);

export const getOrderIds = createAsyncThunk(
  'orders/getOrderIds',
  async query => {
    const { data } = await api.get('/orders/ids', query);

    return data;
  },
);

export const addOrderFiles = createAsyncThunk(
  'orders/addOrderFiles',
  async ({ orderId, files }, { getState, dispatch }) => {
    const userName = getState().loginInfo.user.name;

    if (!files || !files.length) {
      throw new Error('Please select files first');
    }

    const formData = new FormData();

    files.forEach((file, index) => {
      formData.append(`files[${index}]`, file);
    });

    const { data } = await api.post(`/orders/files/${orderId}`, formData, {
      headers: {
        'content-type': 'multipart/form-data',
      },
    });

    dispatch(appendProductFiles(data.uploaded));

    return { data, orderId, userName };
  },
);

export const authorizeOrder = createAsyncThunk(
  'orders/authorizeOrder',
  async orderId => {
    await api.put(`/orders/authorize/${orderId}`);

    return { orderId };
  },
);

export const approveOrderDesignChanges = createAsyncThunk(
  'orders/approveOrderDesignChanges',
  async (orderId, { getState }) => {
    const userName = getState().loginInfo.user.name;

    await api.put(`/orders/approvedesign/${orderId}`);

    return { orderId, userName };
  },
);

export const rejectOrderDesignChanges = createAsyncThunk(
  'orders/rejectOrderDesignChanges',
  async (orderId, { getState }) => {
    const userName = getState().loginInfo.user.name;

    await api.put(`/orders/rejectdesign/${orderId}`);

    return { orderId, userName };
  },
);

export const moveToProductionPlanning = createAsyncThunk(
  'orders/moveToProductionPlanning',
  async ({ orderId, data }) => {
    await api.put(`/orders/movetoproductionplanning/${orderId}`, data);

    return { orderId };
  },
);

export const undoMoveFromProductionPlanning = createAsyncThunk(
  'orders/undoMoveFromProductionPlanning',
  async orderId => {
    await api.put(`/orders/undomovefromproductionplanning/${orderId}`);

    return { orderId };
  },
);

export const undoMoveFromProduction = createAsyncThunk(
  'orders/undoMoveFromProduction',
  async orderId => {
    await api.put(`/orders/undomovefromproduction/${orderId}`);

    return { orderId };
  },
);

export const moveToProduction = createAsyncThunk(
  'orders/moveToProduction',
  async ({ orderId, data }) => {
    await api.put(`/orders/movetoproduction/${orderId}`, data);

    return { orderId };
  },
);

export const getOrderHoldings = createAsyncThunk(
  'orders/getOrderHoldings',
  async orderId => {
    const { data } = await api.get(`/orders/orderholdings/${orderId}`);

    return data;
  },
);

const orders = createSlice({
  name: 'orders',

  initialState,

  reducers: {
    clearOrders: draft => {
      draft.orders = { ...initialState.orders, date: Date.now() };
    },

    clearOrder: draft => {
      draft.order = initialState.order;
    },

    clearOrderRecipe: draft => {
      draft.recipe = initialState.recipe;
    },

    clearFinishedIssue: draft => {
      draft.order = initialState.order;
    },

    clearOrderIds: draft => {
      draft.orderIds = initialState.orderIds;
    },

    clearOrderHoldings: draft => {
      draft.orderHoldings = initialState.orderHoldings;
    },

    clearOrderFMS: draft => {
      draft.reports.fms = initialState.reports.fms;
    },

    clearOrderMIS: draft => {
      draft.reports.mis = initialState.reports.mis;
    },

    clearOnTimeDeliveryReport: draft => {
      draft.reports.onTimeDelivery = initialState.reports.onTimeDelivery;
    },
  },

  extraReducers: builder => {
    builder.addCase(getOrders.fulfilled, (draft, { payload }) => {
      if (draft.orders.date < payload.date) {
        draft.orders.list = payload.data.orders;
        draft.orders.page = payload.data.page;
        draft.orders.query = payload.query;
        draft.orders.totalOrders = payload.data.totalOrders;
        draft.orders.totalPages = payload.data.totalPages;
        draft.orders.date = payload.date;
        draft.orders.totalAmount = payload.data.totalAmount;
        draft.orders.materialAmount = payload.data.materialAmount;
      }
    });

    builder.addCase(getOrder.fulfilled, (draft, { payload }) => {
      draft.order = payload;
    });

    builder.addCase(getOrderRecipe.fulfilled, (draft, { payload }) => {
      draft.recipe = payload;
    });

    builder.addCase(getFinishedIssue.fulfilled, (draft, { payload }) => {
      draft.finishedIssue = payload;
    });

    builder.addCase(getOrderIds.fulfilled, (draft, { payload }) => {
      draft.orderIds = payload;
    });

    builder.addCase(getOrderFMS.fulfilled, (draft, { payload }) => {
      draft.reports.fms = payload;
    });

    builder.addCase(getOrderMIS.fulfilled, (draft, { payload }) => {
      draft.reports.mis = payload;
    });

    builder.addCase(addOrderFiles.fulfilled, (draft, { payload }) => {
      const order = draft.orders.list.find(el => el.id === payload.orderId);

      if (order) {
        order.uploadedAt = new Date().toISOString();
        order.uploadedBy = payload.userName;
      }

      if (payload.data.failed.length) {
        errorNotification(
          new Error(`${payload.data.failed.join(', ')} failed to upload`),
        );
      }
    });

    builder.addCase(
      approveOrderDesignChanges.fulfilled,
      (draft, { payload }) => {
        const order = draft.orders.list.find(el => el.id === payload.orderId);

        if (order) {
          order.designApprovedBy = payload.userName;
          order.designApprovedAt = new Date().toISOString();
        }
      },
    );

    builder.addCase(
      rejectOrderDesignChanges.fulfilled,
      (draft, { payload }) => {
        const order = draft.orders.list.find(el => el.id === payload.orderId);

        if (order) {
          order.designApprovedBy = null;
          order.designApprovedAt = null;
        }
      },
    );

    builder.addCase(
      moveToProductionPlanning.fulfilled,
      (draft, { payload }) => {
        const index = draft.orders.list.findIndex(
          el => el.id === payload.orderId,
        );

        if (index > -1) {
          const newList = [...draft.orders.list];
          newList.splice(index, 1);
          draft.orders.list = newList;
          draft.orders.totalOrders -= 1;
        }
      },
    );

    builder.addCase(updateOrderMrp.fulfilled, (draft, { payload }) => {
      const index = draft.orders.list.findIndex(el => el.id === payload.id);

      if (index > -1) {
        const newList = [...draft.orders.list];
        newList[index] = { ...newList[index], mrp: payload.mrp };
        draft.orders.list = newList;
      }
    });

    builder.addCase(updateOrderRemark.fulfilled, (draft, { payload }) => {
      const index = draft.orders.list.findIndex(el => el.id === payload.id);

      if (index > -1) {
        const newList = [...draft.orders.list];
        newList[index] = { ...newList[index], mrp: payload.mrp };
        draft.orders.list = newList;
      }
    });

    builder.addCase(getOrderHoldings.fulfilled, (draft, { payload }) => {
      draft.orderHoldings = payload;
    });

    builder.addCase(getOnTimeDeliveryReport.fulfilled, (draft, { payload }) => {
      draft.reports.onTimeDelivery = payload;
    });

    builder.addMatcher(
      isAnyOf(
        undoMoveFromProductionPlanning.fulfilled,
        undoMoveFromProduction.fulfilled,
        authorizeOrder.fulfilled,
        deleteOrder.fulfilled,
        moveToProduction.fulfilled,
      ),
      (draft, { payload }) => {
        const index = draft.orders.list.findIndex(
          el => el.id === payload.orderId,
        );

        if (index > -1) {
          const newList = [...draft.orders.list];
          newList.splice(index, 1);
          draft.orders.list = newList;
          draft.orders.totalOrders -= 1;
        }
      },
    );

    builder.addMatcher(
      isAnyOf(
        getOrders.rejected,
        getOrder.rejected,
        getOrderRecipe.rejected,
        deleteOrder.rejected,
        getOrderIds.rejected,
        addOrderFiles.rejected,
        authorizeOrder.rejected,
        approveOrderDesignChanges.rejected,
        rejectOrderDesignChanges.rejected,
        moveToProductionPlanning.rejected,
        undoMoveFromProductionPlanning.rejected,
        undoMoveFromProduction.rejected,
        getOrderHoldings.rejected,
        moveToProduction.rejected,
        getFinishedIssue.rejected,
        updateOrderMrp.rejected,
        updateOrderRemark.rejected,
      ),
      (_Draft, { error }) => {
        errorNotification(error);
      },
    );
  },
});

export const {
  clearOrders,
  clearOrder,
  clearOrderIds,
  clearOrderHoldings,
  clearFinishedIssue,
  clearOrderRecipe,
  clearOrderFMS,
  clearOrderMIS,
  clearOnTimeDeliveryReport,
} = orders.actions;

export default orders.reducer;
