import { createAsyncThunk, createSlice, isAnyOf } from '@reduxjs/toolkit';

import { errorNotification } from '../../../components/notifications';
import api from '../../../libs/api';

const initialState = {
  consignors: [],
  consignor: {},
  consignorIds: [],
};

export const addConsignor = createAsyncThunk(
  'dropdowns/consignors/addConsignor',
  async data => {
    const { data: consignor } = await api.post('/dropdowns/consignors', data);

    return consignor;
  },
);

export const editConsignor = createAsyncThunk(
  'dropdowns/consignors/editConsignor',
  async ({ id, data }) => {
    const { data: consignor } = await api.put(
      `/dropdowns/consignors/${id}`,
      data,
    );

    return consignor;
  },
);

export const deleteConsignor = createAsyncThunk(
  'dropdowns/consignors/deleteConsignor',
  async id => {
    await api.delete(`/dropdowns/consignors/${id}`);

    return id;
  },
);

export const restoreConsignor = createAsyncThunk(
  'dropdowns/consignors/restoreConsignor',
  async id => {
    await api.put(`/dropdowns/consignors/restore/${id}`);

    return id;
  },
);

export const getConsignors = createAsyncThunk(
  'dropdowns/consignors/getConsignors',
  async () => {
    const { data } = await api.get(`/dropdowns/consignors`);

    return data;
  },
);

export const getConsignorIds = createAsyncThunk(
  'dropdowns/consignors/getConsignorIds',
  async () => {
    const { data } = await api.get(`/dropdowns/consignors/ids`);

    return data;
  },
);

export const getConsignor = createAsyncThunk(
  'dropdowns/consignors/getConsignor',
  async id => {
    const { data } = await api.get(`/dropdowns/consignors/${id}`);

    return data;
  },
);

const consignors = createSlice({
  name: 'consignors',

  initialState,

  reducers: {
    clearConsignors: draft => {
      draft.consignors = initialState.consignors;
    },

    clearConsignor: draft => {
      draft.consignor = initialState.consignor;
    },

    clearConsignorIds: draft => {
      draft.consignorIds = initialState.consignorIds;
    },
  },

  extraReducers: builder => {
    builder.addCase(addConsignor.fulfilled, (draft, { payload }) => {
      draft.consignors.push(payload);
    });

    builder.addCase(editConsignor.fulfilled, (draft, { payload }) => {
      const index = draft.consignors.findIndex(el => el.id === payload.id);

      draft.consignors[index] = payload;
    });

    builder.addCase(deleteConsignor.fulfilled, (draft, { payload }) => {
      const index = draft.consignors.findIndex(el => el.id === payload);

      draft.consignors[index].isDeleted = true;
    });

    builder.addCase(restoreConsignor.fulfilled, (draft, { payload }) => {
      const index = draft.consignors.findIndex(el => el.id === payload);

      draft.consignors[index].isDeleted = false;
    });

    builder.addCase(getConsignors.fulfilled, (draft, { payload }) => {
      draft.consignors = payload;
    });

    builder.addCase(getConsignor.fulfilled, (draft, { payload }) => {
      draft.consignor = payload;
    });

    builder.addCase(getConsignorIds.fulfilled, (draft, { payload }) => {
      draft.consignorIds = payload;
    });

    builder.addMatcher(
      isAnyOf(
        addConsignor.rejected,
        editConsignor.rejected,
        deleteConsignor.rejected,
        restoreConsignor.rejected,
        getConsignors.rejected,
        getConsignor.rejected,
        getConsignorIds.rejected,
      ),
      (_draft, { error }) => {
        errorNotification(error);
      },
    );
  },
});

export const { clearConsignor, clearConsignors, clearConsignorIds } =
  consignors.actions;

export default consignors.reducer;
