import socket from './socket';
import store from '../redux/store';
import { updateLoginInfo, updateRolePermissions } from '../redux/actions';

socket.on('update_role', data => {
  store.dispatch(updateRolePermissions(data));
});

socket.on('update_login_info', data => {
  store.dispatch(updateLoginInfo(data));
});

export default socket;
