import { createAsyncThunk, createSlice, isAnyOf } from '@reduxjs/toolkit';

import { errorNotification } from '../../components/notifications';
import api from '../../libs/api';

const initialState = {
  preSales: {
    list: [],
    page: 1,
    query: { page: 1 },
    totalPages: 1,
    totalPreSales: 0,
  },
  preSale: { charges: [], materials: [], products: [] },
  files: [],
};

export const getPreSales = createAsyncThunk(
  'preSales/getPreSales',
  async (query, { getState }) => {
    const currentQuery = getState().preSales.preSales.query;

    const effectiveQuery = query || currentQuery;

    const { data } = await api.get('/pre-sales', effectiveQuery);

    return { data, query: effectiveQuery };
  },
);

export const getPreSale = createAsyncThunk('preSales/getPreSale', async id => {
  const { data } = await api.get(`/pre-sales/${id}`);

  return data;
});

export const deletePreSale = createAsyncThunk(
  'preSales/deletePreSale',
  async (id, { getState }) => {
    const userName = getState().loginInfo.user.name;

    await api.delete(`/pre-sales/${id}`);

    return { id, userName };
  },
);

export const getPreSaleFiles = createAsyncThunk(
  'preSales/getPreSaleFiles',
  async preSaleId => {
    const { data } = await api.get(`/pre-sales/files/${preSaleId}`);

    return data;
  },
);

export const deletePreSaleFile = createAsyncThunk(
  'preSales/deletePreSaleFile',
  async ({ fileId, preSaleId }) => {
    await api.delete(`/pre-sales/files/${fileId}`);

    return { fileId, preSaleId };
  },
);

export const addPreSaleFiles = createAsyncThunk(
  'preSales/addPreSaleFiles',
  async ({ preSaleId, files, type }) => {
    if (!files || !files.length) {
      throw new Error('Please select files first');
    }

    const formData = new FormData();

    formData.append('type', type);

    files.forEach((file, index) => {
      formData.append(`files[${index}]`, file);
    });

    const { data } = await api.post(`/pre-sales/files/${preSaleId}`, formData, {
      headers: {
        'content-type': 'multipart/form-data',
      },
    });

    return { data, preSaleId };
  },
);

const preSales = createSlice({
  name: 'preSales',

  initialState,

  reducers: {
    clearPreSales: draft => {
      draft.preSales = initialState.preSales;
    },

    clearPreSale: draft => {
      draft.preSale = initialState.preSale;
    },

    clearPreSaleFiles: draft => {
      draft.files = initialState.files;
    },
  },

  extraReducers: builder => {
    builder.addCase(getPreSales.fulfilled, (draft, { payload }) => {
      draft.preSales.list = payload.data.preSales;
      draft.preSales.page = payload.data.page;
      draft.preSales.query = payload.query;
      draft.preSales.totalPreSales = payload.data.totalPreSales;
      draft.preSales.totalPages = payload.data.totalPages;
    });

    builder.addCase(getPreSale.fulfilled, (draft, { payload }) => {
      draft.preSale = payload;
    });

    builder.addCase(deletePreSale.fulfilled, (draft, { payload }) => {
      const preSale = draft.preSales.list.find(el => el.id === payload.id);

      if (preSale) {
        preSale.deletedAt = new Date().toISOString();
        preSale.deletedBy = payload.userName;
      }
    });

    builder.addCase(getPreSaleFiles.fulfilled, (draft, { payload }) => {
      draft.files = payload;
    });

    builder.addCase(deletePreSaleFile.fulfilled, (draft, { payload }) => {
      draft.files = draft.files.filter(file => file.id !== payload.fileId);

      const preSale = draft.preSales.list.find(
        el => el.id === payload.preSaleId,
      );

      if (preSale) {
        preSale.noOfFiles = +preSale.noOfFiles - 1;
      }
    });

    builder.addCase(addPreSaleFiles.fulfilled, (draft, { payload }) => {
      draft.files.push(...payload.data.uploaded);

      const preSale = draft.preSales.list.find(
        el => el.id === payload.preSaleId,
      );

      if (preSale) {
        preSale.noOfFiles = +preSale.noOfFiles + payload.data.uploaded.length;
      }

      if (payload.data.failed.length) {
        errorNotification(
          new Error(`${payload.data.failed.join(', ')} failed to upload`),
        );
      }
    });

    builder.addMatcher(
      isAnyOf(
        getPreSales.rejected,
        getPreSale.rejected,
        deletePreSale.rejected,
        getPreSaleFiles.rejected,
        deletePreSaleFile.rejected,
        addPreSaleFiles.rejected,
      ),
      (_Draft, { error }) => {
        errorNotification(error);
      },
    );
  },
});

export const { clearPreSales, clearPreSale, clearPReSaleFiles } =
  preSales.actions;

export default preSales.reducer;
