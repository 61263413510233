import { combineReducers } from '@reduxjs/toolkit';

import auth from './auth';
import users from './users';
import dropdowns from './dropdowns';
import parties from './parties';
import materials from './materials';
import products from './products';
import sops from './sops';
import machines from './machines';
import inventory from './inventory';
import purchaseOrders from './purchase-orders';
import purchases from './purchases';
import proformaInvoices from './proforma-invoices';
import orders from './orders';
import leads from './leads';
import preSales from './pre-sales';
import sales from './sales';
import cdns from './cdns';
import rfqs from './rfqs';
import banks from './banks';
import assets from './assets';
import narrations from './narrations';
import contacts from './contacts';
import transactions from './transactions';
import bills from './bills';
import saleOrders from './sale-orders';
import tasks from './tasks';
import financials from './financials';
import quotations from './quotations';
import helpTickets from './help-tickets';

export default combineReducers({
  loginInfo: auth,
  users,
  dropdowns,
  parties,
  materials,
  products,
  sops,
  machines,
  inventory,
  purchaseOrders,
  purchases,
  proformaInvoices,
  orders,
  leads,
  preSales,
  sales,
  cdns,
  rfqs,
  banks,
  assets,
  narrations,
  contacts,
  transactions,
  bills,
  saleOrders,
  tasks,
  financials,
  quotations,
  helpTickets,
});
