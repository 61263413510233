import { createAsyncThunk, createSlice, isAnyOf } from '@reduxjs/toolkit';

import { errorNotification } from '../../components/notifications';
import api from '../../libs/api';

// Initial State redux.leads
const initialState = {
  leads: {
    list: [],
    page: 1,
    query: {
      page: 1,
    },
    totalPages: 1,
    totalLeads: 0,
  },
  leadIds: [],
  lead: {},
  interactions: [],
  enquiries: [],
  enquiry: {},
  enquiryFiles: [],
  fms: [],
};

// Async thunk Actions
export const getLeads = createAsyncThunk(
  'leads/getLeads',
  async (query, { getState }) => {
    const currentQuery = getState().leads.leads.query;

    const effectiveQuery = query || currentQuery;

    const { data } = await api.get('/leads', effectiveQuery);

    return { data, query: effectiveQuery };
  },
);

export const getLeadIds = createAsyncThunk('leads/getLeadIds', async () => {
  const { data } = await api.get('/leads/ids');

  return data;
});

export const getLead = createAsyncThunk('leads/getLead', async id => {
  const { data } = await api.get(`/leads/${id}`);

  return data;
});

export const getInteractionCalendar = createAsyncThunk(
  'leads/getInteractionCalendar',
  async query => {
    const { data } = await api.get('/interactions', query);

    return data;
  },
);

export const getEnquiryIds = createAsyncThunk(
  'leads/getEnquiryIds',
  async query => {
    const { data } = await api.get('/leads/enquiries/ids', query);

    return data;
  },
);

export const getEnquiry = createAsyncThunk('leads/getEnquiry', async id => {
  const { data } = await api.get(`/leads/enquiries/${id}`);

  return data;
});

export const getEnquiryFiles = createAsyncThunk(
  'leads/getEnquiryFiles',
  async enquiryId => {
    const { data } = await api.get(`/leads/enquiries/files/${enquiryId}`);

    return data;
  },
);

export const deleteEnquiryFile = createAsyncThunk(
  'leads/deleteEnquiryFiles',
  async ({ fileId, enquiryId }) => {
    await api.delete(`/leads/enquiries/files/${fileId}`);

    return { fileId, enquiryId };
  },
);

export const addEnquiryFiles = createAsyncThunk(
  'leads/addEnquiryFiles',
  async ({ enquiryId, files }) => {
    if (!files || !files.length) {
      throw new Error('Please select files first');
    }

    const formData = new FormData();

    files.forEach((file, index) => {
      formData.append(`files[${index}]`, file);
    });

    const { data } = await api.post(
      `/leads/enquiries/files/${enquiryId}`,
      formData,
      {
        headers: {
          'content-type': 'multipart/form-data',
        },
      },
    );

    return { data, enquiryId };
  },
);

export const getEnquiryFMS = createAsyncThunk(
  'leads/getEnquiryFMS',
  async () => {
    const { data } = await api.get('/leads/enquiries/fms');

    return data;
  },
);

// Lead Redux-Toolkit Slice
const leads = createSlice({
  name: 'leads',
  initialState,

  reducers: {
    clearLeads: draft => {
      draft.leads = initialState.leads;
    },

    clearLeadIds: draft => {
      draft.leadIds = initialState.leadIds;
    },

    clearLead: draft => {
      draft.lead = initialState.lead;
    },

    clearInteractionCalendar: draft => {
      draft.interactions = initialState.interactions;
    },

    clearEnquiryIds: draft => {
      draft.enquiries = initialState.enquiries;
    },

    clearEnquiry: draft => {
      draft.enquiry = initialState.enquiry;
    },

    clearEnquiryFiles: draft => {
      draft.enquiryFiles = initialState.enquiryFiles;
    },

    clearEnquiryFMS: draft => {
      draft.fms = initialState.fms;
    },
  },

  extraReducers: builder => {
    builder.addCase(getLeads.fulfilled, (draft, { payload }) => {
      draft.leads.list = payload.data.leads;
      draft.leads.page = payload.data.page;
      draft.leads.query = payload.query;
      draft.leads.totalLeads = payload.data.totalLeads;
      draft.leads.totalPages = payload.data.totalPages;
    });

    builder.addCase(getLeadIds.fulfilled, (draft, { payload }) => {
      draft.leadIds = payload;
    });

    builder.addCase(getLead.fulfilled, (draft, { payload }) => {
      draft.lead = payload;
    });

    builder.addCase(getInteractionCalendar.fulfilled, (draft, { payload }) => {
      draft.interactions = payload;
    });

    builder.addCase(getEnquiryIds.fulfilled, (draft, { payload }) => {
      draft.enquiries = payload;
    });

    builder.addCase(getEnquiry.fulfilled, (draft, { payload }) => {
      draft.enquiry = payload;
    });

    builder.addCase(getEnquiryFiles.fulfilled, (draft, { payload }) => {
      draft.enquiryFiles = payload;
    });

    builder.addCase(deleteEnquiryFile.fulfilled, (draft, { payload }) => {
      draft.enquiryFiles = draft.enquiryFiles.filter(
        file => file.id !== payload.fileId,
      );
    });

    builder.addCase(addEnquiryFiles.fulfilled, (draft, { payload }) => {
      draft.enquiryFiles.push(...payload.data.uploaded);

      if (payload.data.failed.length) {
        errorNotification(
          new Error(`${payload.data.failed.join(', ')} failed to upload`),
        );
      }
    });

    builder.addCase(getEnquiryFMS.fulfilled, (draft, { payload }) => {
      draft.fms = payload;
    });

    builder.addMatcher(
      isAnyOf(
        getLeads.rejected,
        getLeadIds.rejected,
        getLead.rejected,
        getInteractionCalendar.rejected,
        getEnquiryIds.rejected,
        getEnquiry.rejected,
        getEnquiryFiles.rejected,
        deleteEnquiryFile.rejected,
        addEnquiryFiles.rejected,
        getEnquiryFMS.rejected,
      ),
      (_Proxy, { error }) => {
        errorNotification(error);
      },
    );
  },
});

export const {
  clearLeads,
  clearLeadIds,
  clearLead,
  clearInteractionCalendar,
  clearEnquiryIds,
  clearEnquiry,
  clearEnquiryFiles,
  clearEnquiryFMS,
} = leads.actions;

export default leads.reducer;
