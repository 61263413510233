import React, { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import withStyles from 'react-jss';
import { useSelector } from 'react-redux';
import { Layout, Menu, Drawer } from 'antd';
import { Link, useNavigate, useLocation } from 'react-router-dom';

import styles from './styles';
import { Modules, getDefaultSelectedKeys, getSubModules } from './helper';

const { Sider } = Layout;

const Sidebar = props => {
  const { classes, visible, setVisible } = props;

  const navigate = useNavigate();
  const location = useLocation();

  const loginInfo = useSelector(state => state.loginInfo);

  const [collapsed, setCollapsed] = useState(false);

  const {
    user,
    user: { role },
    permissionsArray: permissions,
  } = loginInfo;

  const renderItem = useCallback(
    ({ type, module, title, key, link, subModules, icon }) => {
      if (type === 'item') {
        if (role !== 'Owner' && !permissions.includes(module)) {
          return null;
        }
        return { label: title, key, icon, onClick: () => navigate(link) };
      }

      if (type === 'list') {
        if (
          role !== 'Owner' &&
          !getSubModules(subModules).filter(subModule =>
            permissions.includes(subModule),
          ).length
        ) {
          return null;
        }
        return {
          label: title,
          key,
          icon,
          children: subModules.map(subModule => renderItem(subModule)),
        };
      }
    },
    [permissions],
  );

  const defaultSelectedKeys = useMemo(
    () => [getDefaultSelectedKeys(location.pathname)],
    [location.pathname],
  );

  const menuItems = useMemo(() => {
    if (user) {
      return Modules.map(renderItem);
    }

    return [];
  }, [loginInfo]);

  return (
    <div>
      <Drawer
        className={classes.drawer}
        placement="left"
        closable={false}
        onClose={() => setVisible(!visible)}
        open={visible}
      >
        <Menu
          theme="light"
          mode="inline"
          defaultSelectedKeys={defaultSelectedKeys}
          items={menuItems}
        />
      </Drawer>
      <Sider
        className={classes.sidebar}
        collapsible
        theme="light"
        collapsed={collapsed}
        onCollapse={value => setCollapsed(value)}
        collapsedWidth={48}
      >
        <div className={classes.logoDivStyle}>
          <Link to="/">
            {collapsed ? (
              <img
                src="/logo.svg"
                alt="Sarthak Hive"
                className={classes.logo}
              />
            ) : (
              <img
                src="/hive.svg"
                alt="Sarthak Hive"
                className={classes.logo}
              />
            )}
          </Link>
        </div>
        <Menu
          theme="light"
          mode="inline"
          className={classes.menu}
          defaultSelectedKeys={defaultSelectedKeys}
          items={menuItems}
        />
      </Sider>
    </div>
  );
};

Sidebar.propTypes = {
  classes: PropTypes.object.isRequired,
  visible: PropTypes.bool,
  setVisible: PropTypes.func.isRequired,
};

const StyledSidebar = withStyles(styles)(Sidebar);

export default StyledSidebar;
