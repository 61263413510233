import { createAsyncThunk, createSlice, isAnyOf } from '@reduxjs/toolkit';

import { errorNotification } from '../../../components/notifications';
import api from '../../../libs/api';

const initialState = {
  charges: [],
  charge: {},
  chargeIds: [],
};

export const addCharge = createAsyncThunk(
  'dropdowns/charges/addCharge',
  async data => {
    const { data: charge } = await api.post('/dropdowns/charges', data);

    return charge;
  },
);

export const editCharge = createAsyncThunk(
  'dropdowns/charges/editCharge',
  async ({ id, data }) => {
    const { data: charge } = await api.put(`/dropdowns/charges/${id}`, data);

    return charge;
  },
);

export const deleteCharge = createAsyncThunk(
  'dropdowns/charges/deleteCharge',
  async (id, { getState }) => {
    const userName = getState().loginInfo.user.name;

    await api.delete(`/dropdowns/charges/${id}`);

    return { id, userName };
  },
);

export const restoreCharge = createAsyncThunk(
  'dropdowns/charges/restoreCharge',
  async id => {
    await api.put(`/dropdowns/charges/restore/${id}`);

    return id;
  },
);

export const getCharges = createAsyncThunk(
  'dropdowns/charges/getCharges',
  async () => {
    const { data } = await api.get(`/dropdowns/charges`);

    return data;
  },
);

export const getChargeIds = createAsyncThunk(
  'dropdowns/charges/getChargeIds',
  async () => {
    const { data } = await api.get(`/dropdowns/charges/ids`);

    return data;
  },
);

export const getCharge = createAsyncThunk(
  'dropdowns/charges/getCharge',
  async id => {
    const { data } = await api.get(`/dropdowns/charges/${id}`);

    return data;
  },
);

const charges = createSlice({
  name: 'charges',

  initialState,

  reducers: {
    clearCharges: draft => {
      draft.charges = initialState.charges;
    },

    clearCharge: draft => {
      draft.charge = initialState.charge;
    },

    clearChargeIds: draft => {
      draft.chargeIds = initialState.chargeIds;
    },
  },

  extraReducers: builder => {
    builder.addCase(addCharge.fulfilled, (draft, { payload }) => {
      draft.charges.push(payload);
    });

    builder.addCase(editCharge.fulfilled, (draft, { payload }) => {
      const index = draft.charges.findIndex(el => el.id === payload.id);

      draft.charges[index] = payload;
    });

    builder.addCase(deleteCharge.fulfilled, (draft, { payload }) => {
      const index = draft.charges.findIndex(el => el.id === payload.id);

      draft.charges[index].deletedAt = new Date().toISOString();
      draft.charges[index].deletedBy = payload.userName;
    });

    builder.addCase(restoreCharge.fulfilled, (draft, { payload }) => {
      const index = draft.charges.findIndex(el => el.id === payload);

      draft.charges[index].deletedAt = null;
      draft.charges[index].deletedBy = null;
    });

    builder.addCase(getCharges.fulfilled, (draft, { payload }) => {
      draft.charges = payload;
    });

    builder.addCase(getCharge.fulfilled, (draft, { payload }) => {
      draft.charge = payload;
    });

    builder.addCase(getChargeIds.fulfilled, (draft, { payload }) => {
      draft.chargeIds = payload;
    });

    builder.addMatcher(
      isAnyOf(
        addCharge.rejected,
        editCharge.rejected,
        deleteCharge.rejected,
        restoreCharge.rejected,
        getCharges.rejected,
        getCharge.rejected,
        getChargeIds.rejected,
      ),
      (_draft, { error }) => {
        errorNotification(error);
      },
    );
  },
});

export const { clearCharge, clearCharges, clearChargeIds } = charges.actions;

export default charges.reducer;
