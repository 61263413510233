import { createAsyncThunk, createSlice, isAnyOf } from '@reduxjs/toolkit';

import { errorNotification } from '../../../components/notifications';
import api from '../../../libs/api';

const initialState = {
  units: [],
  unit: {},
  unitIds: [],
};

export const getUnits = createAsyncThunk(
  'dropdowns/units/getUnits',
  async () => {
    const { data } = await api.get('/dropdowns/units');

    return data;
  },
);

export const getUnitIds = createAsyncThunk(
  'dropdowns/units/getUnitIds',
  async () => {
    const { data } = await api.get('/dropdowns/units/ids');

    return data;
  },
);

export const getUnit = createAsyncThunk('dropdowns/units/getUnit', async id => {
  const { data } = await api.get(`/dropdowns/units/${id}`);

  return data;
});

export const deleteUnit = createAsyncThunk(
  'dropdowns/units/deleteUnit',
  async id => {
    await api.delete(`/dropdowns/units/${id}`);

    return id;
  },
);

export const addUnit = createAsyncThunk(
  'dropdowns/units/addUnit',
  async data => {
    const { data: unit } = await api.post('/dropdowns/units', data);

    return unit;
  },
);

export const editUnit = createAsyncThunk(
  'dropdowns/units/editUnit',
  async ({ id, data }) => {
    const { data: unit } = await api.put(`/dropdowns/units/${id}`, data);

    return unit;
  },
);

const units = createSlice({
  name: 'units',

  initialState,

  reducers: {
    clearUnits: draft => {
      draft.units = initialState.units;
    },

    clearUnit: draft => {
      draft.unit = initialState.unit;
    },

    clearUnitIds: draft => {
      draft.unitIds = initialState.unitIds;
    },
  },

  extraReducers: builder => {
    builder.addCase(getUnits.fulfilled, (draft, { payload }) => {
      draft.units = payload;
    });

    builder.addCase(getUnit.fulfilled, (draft, { payload }) => {
      draft.unit = payload;
    });

    builder.addCase(deleteUnit.fulfilled, (draft, { payload }) => {
      draft.units = draft.units.filter(el => el.id !== payload);
    });

    builder.addCase(addUnit.fulfilled, (draft, { payload }) => {
      draft.units.push(payload);
    });

    builder.addCase(editUnit.fulfilled, (draft, { payload }) => {
      const index = draft.units.findIndex(el => el.id === payload.id);

      draft.units[index] = payload;
    });

    builder.addCase(getUnitIds.fulfilled, (draft, { payload }) => {
      draft.unitIds = payload;
    });

    builder.addMatcher(
      isAnyOf(
        getUnits.rejected,
        getUnit.rejected,
        deleteUnit.rejected,
        addUnit.rejected,
        editUnit.rejected,
        getUnitIds.rejected,
      ),
      (_Draft, { error }) => {
        errorNotification(error);
      },
    );
  },
});

export const { clearUnits, clearUnit, clearUnitIds } = units.actions;

export default units.reducer;
