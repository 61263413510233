import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import withStyles from 'react-jss';
import {
  Button,
  Input,
  Select,
  Row,
  Breadcrumb,
  InputNumber,
  DatePicker,
  Checkbox,
} from 'antd';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useForm, Controller } from 'react-hook-form';
import _ from 'lodash';
import moment from 'moment';

import styles from '../styles';
import GetField from '../common/GetField';
import LeftGrid from '../common/LeftGrid';
import RightGrid from '../common/RightGrid';
import api from '../../libs/api';
import CONSTANTS from '../../libs/constants';
import { errorNotification, successNotification } from '../notifications';
import PERMISSIONS from '../../libs/permissions';
import {
  clearConsignorIds,
  clearOrder,
  clearPartyIds,
  clearProductIds,
  clearProformaInvoiceIds,
  getConsignorIds,
  getOrder,
  getPartyIds,
  getProductIds,
  getProformaInvoiceIds,
} from '../../redux/actions';
import AvatarMenu from '../structure/AvatarMenu';
import PiProductPickModal from './PiProductPickModal';

const AntInput = GetField(Input);
const AntInputNumber = GetField(InputNumber);
const AntInputTextArea = GetField(Input.TextArea);
const AntDatePicker = GetField(DatePicker);
const AntSelect = GetField(Select);
const AntCheckBox = GetField(Checkbox);

const EditOrder = props => {
  const { classes } = props;

  const navigate = useNavigate();
  const params = useParams();
  const dispatch = useDispatch();

  const [ConfirmLoading, SetConfirmLoading] = useState(false);

  const { handleSubmit, control, watch, setValue, reset } = useForm();

  const permissions = useSelector(state => state.loginInfo.permissions);
  const productIds = useSelector(state => state.products.productIds);
  const consignors = useSelector(
    state => state.dropdowns.consignors.consignorIds,
  );
  const parties = useSelector(state => state.parties.partyIds);
  const piIds = useSelector(state => state.proformaInvoices.proformaInvoiceIds);
  const order = useSelector(state => state.orders.order);

  const [PiProducts, SetPiProducts] = useState([]);

  const validConsignorIds = useMemo(() => {
    const consignorIds = new Set(permissions[PERMISSIONS.editOrder]);

    return consignorIds;
  }, [permissions]);

  useEffect(() => {
    if (!permissions[PERMISSIONS.editOrder].length) {
      return;
    }

    dispatch(getOrder(params.id));

    document.title = `Edit Order - ${CONSTANTS.title}`;

    return () => {
      dispatch(clearOrder());
    };
  }, []);

  useEffect(() => {
    reset(order);
  }, [order]);

  useEffect(() => {
    if (!permissions[PERMISSIONS.editOrder].length) {
      navigate('/orders');
      return;
    }

    // dispatch(getProductIds());
    dispatch(getPartyIds());
    dispatch(getConsignorIds());

    return () => {
      dispatch(clearProductIds());
      dispatch(clearPartyIds());
      dispatch(clearConsignorIds());
    };
  }, [permissions]);

  useEffect(() => {
    const [consignorId, partyId] = [watch('consignorId'), watch('partyId')];

    if (consignorId && partyId) {
      dispatch(getProformaInvoiceIds({ consignorId, partyId }));
    } else {
      dispatch(clearProformaInvoiceIds());
    }
  }, [watch('consignorId'), watch('partyId')]);

  useEffect(() => {
    const [piId] = [watch('piId')];

    if (piId) {
      dispatch(getProductIds({ piId }));
    } else {
      dispatch(clearProformaInvoiceIds());
    }
  }, [watch('piId')]);

  useEffect(() => {
    const [piId, productId] = [watch('piId'), watch('productId')];
    let isCurrent = true;

    if (piId && productId) {
      if (piId !== order.piId && productId !== order.productId) {
        api
          .get(`/proforma-invoices/${piId}/products/${productId}`)
          .then(({ data }) => {
            if (!isCurrent) {
              return;
            }
            if (data.length === 1) {
              const [
                {
                  mrp,
                  quantity,
                  rate,
                  type,
                  isDesignChangesRequired = false,
                  leadTime,
                },
              ] = data;

              setValue('mrp', mrp);
              setValue('quantity', quantity);
              setValue('rate', rate);
              setValue('type', type);
              setValue('isDesignChangesRequired', isDesignChangesRequired);
              setValue(
                'expectedDeliveryDate',
                moment().add(leadTime, 'days').toISOString(),
              );
            } else {
              SetPiProducts(data);
            }
          })
          .catch(console.error);
      } else {
        //
      }
    } else {
      setValue('mrp', null);
      setValue('quantity', null);
      setValue('rate', null);
      setValue('type', null);
      setValue('isDesignChangesRequired', false);
      setValue('expectedDeliveryDate', null);
    }

    return () => {
      isCurrent = false;
    };
  }, [watch('piId'), watch('productId')]);

  const onSubmit = async formValues => {
    try {
      SetConfirmLoading(true);
      await api.put(`/orders/${params.id}`, formValues);
      successNotification('Order updated');
      navigate('/orders');
    } catch (error) {
      SetConfirmLoading(false);
      errorNotification(error);
    }
  };

  return (
    <>
      <div className={classes.breadcrumbStyle}>
        <Breadcrumb>
          <Breadcrumb.Item>
            <Link to="/">Home</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <Link to="/orders">Orders</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>Edit Order</Breadcrumb.Item>
        </Breadcrumb>
        <div className={classes.avatar}>
          <AvatarMenu />
        </div>
      </div>

      <form onSubmit={handleSubmit(onSubmit)}>
        <Row>
          <LeftGrid>
            <Controller
              name="consignorId"
              control={control}
              render={({ field }) => (
                <AntSelect
                  allowClear
                  label="Consignor"
                  placeholder="Select consignor"
                  {...field}
                >
                  {consignors.map(el => (
                    <Select.Option
                      key={el.id}
                      value={el.id}
                      disabled={!validConsignorIds.has(el.id)}
                    >
                      {el.consignor}
                    </Select.Option>
                  ))}
                </AntSelect>
              )}
            />
          </LeftGrid>
          <RightGrid>
            <Controller
              name="partyId"
              control={control}
              render={({ field }) => (
                <AntSelect
                  allowClear
                  label="Party"
                  placeholder="Select party"
                  {...field}
                  onChange={value => {
                    field.onChange(value);
                    const party = (parties || []).find(el => el.id === value);

                    setValue('paymentTerms', party.paymentTerms);
                    setValue('piId', null);
                    setValue('productId', null);
                    setValue('mrp', null);
                    setValue('quantity', null);
                    setValue('rate', null);
                    setValue('type', null);
                    setValue('isDesignChangesRequired', false);
                    setValue('expectedDeliveryDate', null);
                    setValue('remark', null);
                  }}
                >
                  {parties.map(party => (
                    <Select.Option key={party.id} value={party.id}>
                      {party.name}
                    </Select.Option>
                  ))}
                </AntSelect>
              )}
            />
          </RightGrid>
        </Row>

        <Row>
          <LeftGrid>
            <Controller
              name="bpoNo"
              control={control}
              render={({ field }) => (
                <AntInput
                  allowClear
                  label="Buyer PO #"
                  placeholder="Buyer PO # (if any)"
                  {...field}
                />
              )}
            />
          </LeftGrid>
          <RightGrid>
            <Controller
              name="piId"
              control={control}
              render={({ field }) => (
                <AntSelect
                  allowClear
                  label="Proforma Invoice #"
                  placeholder="PI # (if any)"
                  {...field}
                >
                  {piIds.map((el, index) => (
                    <Select.Option key={index} value={el.id}>
                      {el.piNo}
                    </Select.Option>
                  ))}
                </AntSelect>
              )}
            />
          </RightGrid>
        </Row>
        <Row>
          <LeftGrid>
            <Controller
              name="productId"
              control={control}
              render={({ field }) => (
                <AntSelect
                  allowClear
                  label="Product"
                  placeholder="Product"
                  required
                  {...field}
                  onChange={productId => {
                    field.onChange(productId);
                    if (watch('piId')) {
                      const product = productIds.find(
                        el => el.id === productId,
                      );

                      if (product && product.piRemark) {
                        setValue('remark', product.piRemark);
                      }
                    }
                  }}
                >
                  {productIds.map((el, index) => (
                    <Select.Option key={index} value={el.id}>
                      {el.name}
                    </Select.Option>
                  ))}
                </AntSelect>
              )}
            />
          </LeftGrid>
          <RightGrid>
            <Controller
              name="productId"
              control={control}
              render={({ field }) => (
                <AntSelect
                  allowClear
                  label="Category"
                  placeholder="Category"
                  disabled
                  required
                  {...field}
                >
                  {productIds.map((el, index) => (
                    <Select.Option key={index} value={el.id}>
                      {el.category}
                    </Select.Option>
                  ))}
                </AntSelect>
              )}
            />
          </RightGrid>
        </Row>
        <Row>
          <LeftGrid>
            <Controller
              name="productId"
              control={control}
              render={({ field }) => (
                <AntSelect
                  allowClear
                  label="Subcategory"
                  placeholder="Subcategory"
                  disabled
                  required
                  {...field}
                >
                  {productIds.map((el, index) => (
                    <Select.Option key={index} value={el.id}>
                      {el.subcategory}
                    </Select.Option>
                  ))}
                </AntSelect>
              )}
            />
          </LeftGrid>
          <RightGrid>
            <Controller
              name="productId"
              control={control}
              render={({ field }) => (
                <AntSelect
                  allowClear
                  label="Packing"
                  placeholder="Packing"
                  disabled
                  required
                  {...field}
                >
                  {productIds.map((el, index) => (
                    <Select.Option key={index} value={el.id}>
                      {el.packing}
                    </Select.Option>
                  ))}
                </AntSelect>
              )}
            />
          </RightGrid>
        </Row>
        <Row>
          <LeftGrid>
            <Controller
              name="productId"
              control={control}
              render={({ field }) => (
                <AntSelect
                  allowClear
                  label="Pack Size"
                  placeholder="Product Pack Size"
                  disabled
                  required
                  {...field}
                >
                  {productIds.map((el, index) => (
                    <Select.Option key={index} value={el.id}>
                      {el.packSize}
                    </Select.Option>
                  ))}
                </AntSelect>
              )}
            />
          </LeftGrid>
          <RightGrid>
            <Controller
              name="productId"
              control={control}
              render={({ field }) => (
                <AntSelect
                  allowClear
                  label="Is DPCO?"
                  disabled
                  required
                  {...field}
                >
                  {productIds.map((el, index) => (
                    <Select.Option key={index} value={el.id}>
                      {_.startCase(el.isDPCO)}
                    </Select.Option>
                  ))}
                </AntSelect>
              )}
            />
          </RightGrid>
        </Row>

        <Row>
          <LeftGrid>
            <Controller
              name="type"
              control={control}
              render={({ field }) => (
                <AntSelect
                  allowClear
                  disabled
                  label="Order Type"
                  placeholder="Select type"
                  {...field}
                >
                  {Object.values(CONSTANTS.order.types).map((el, index) => (
                    <Select.Option key={index} value={el}>
                      {el}
                    </Select.Option>
                  ))}
                </AntSelect>
              )}
            />
          </LeftGrid>
          <RightGrid>
            <Controller
              name="mrp"
              control={control}
              render={({ field }) => (
                <AntInputNumber
                  label="MRP"
                  placeholder="MRP"
                  {...field}
                  step={0.01}
                />
              )}
            />
          </RightGrid>
        </Row>
        <Row>
          <LeftGrid>
            <Controller
              name="quantity"
              control={control}
              render={({ field }) => (
                <AntInputNumber
                  label="Order Quantity"
                  placeholder="Quantity"
                  {...field}
                  step={1}
                />
              )}
            />
          </LeftGrid>
          <RightGrid>
            <Controller
              name="rate"
              control={control}
              render={({ field }) => (
                <AntInputNumber
                  label="Rate"
                  placeholder="Rate"
                  {...field}
                  step={0.001}
                />
              )}
            />
          </RightGrid>
        </Row>
        <Row>
          <LeftGrid>
            <Controller
              name="isDesignChangesRequired"
              control={control}
              render={({ field }) => (
                <AntCheckBox label="Design Changes Required" {...field} />
              )}
            />
          </LeftGrid>
          <RightGrid>
            <Controller
              name="expectedDeliveryDate"
              control={control}
              render={({ field }) => (
                <AntDatePicker
                  label="Expetected Delivery Date"
                  placeholder="Expetected Delivery Date"
                  {...field}
                />
              )}
            />
          </RightGrid>
        </Row>
        <Row>
          <LeftGrid>
            <Controller
              name="paymentTerms"
              control={control}
              render={({ field }) => (
                <AntInputNumber
                  label="Payment Terms"
                  placeholder="Payment Terms"
                  {...field}
                />
              )}
            />
          </LeftGrid>
          <RightGrid>
            <Controller
              name="remark"
              control={control}
              render={({ field }) => (
                <AntInputTextArea
                  allowClear
                  label="Remark"
                  placeholder="Remark"
                  {...field}
                  autoSize={{ minRows: 1, maxRows: 6 }}
                />
              )}
            />
          </RightGrid>
        </Row>

        <Row className={classes.buttonContainer}>
          <Button type="primary" loading={ConfirmLoading} htmlType="submit">
            Submit
          </Button>
        </Row>
      </form>
      {PiProducts.length ? (
        <PiProductPickModal
          setValue={setValue}
          piProducts={PiProducts}
          setPiProducts={SetPiProducts}
        />
      ) : null}
    </>
  );
};

EditOrder.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(EditOrder);
