import { createAsyncThunk, createSlice, isAnyOf } from '@reduxjs/toolkit';

import { errorNotification } from '../../components/notifications';
import api from '../../libs/api';

const initialState = {
  helpTickets: {
    list: [],
    page: 1,
    query: { page: 1 },
    totalPages: 1,
    totalHelpTickets: 0,
  },
  helpTicket: {},
  helpTicketIds: [],
  files: [],
};

export const getHelpTickets = createAsyncThunk(
  'helpTickets/getHelpTickets',
  async (query, { getState }) => {
    const currentQuery = getState().helpTickets.helpTickets.query;

    const effectiveQuery = query || currentQuery;

    const { data } = await api.get('/help-tickets', effectiveQuery);

    return { data, query: effectiveQuery };
  },
);

export const getHelpTicket = createAsyncThunk(
  'helpTickets/getHelpTicket',
  async id => {
    const { data } = await api.get(`/help-tickets/${id}`);

    return data;
  },
);

export const deleteHelpTicket = createAsyncThunk(
  'helpTickets/deleteHelpTicket',
  async (id, { getState }) => {
    const userName = getState().loginInfo.user.name;

    await api.delete(`/help-tickets/${id}`);

    return { id, userName };
  },
);

export const getHelpTicketIds = createAsyncThunk(
  'helpTickets/getHelpTicketIds',
  async query => {
    const { data } = await api.get('/help-tickets/ids', query);

    return data;
  },
);

export const getHelpTicketFiles = createAsyncThunk(
  'helpTickets/getHelpTicketFiles',
  async ticketId => {
    const { data } = await api.get(`/help-tickets/files/${ticketId}`);

    return data;
  },
);

export const deleteHelpTicketFile = createAsyncThunk(
  'helpTickets/deleteHelpTicketFiles',
  async ({ fileId, ticketId }) => {
    await api.delete(`/help-tickets/files/${fileId}`);

    return { fileId, ticketId };
  },
);

export const addHelpTicketFiles = createAsyncThunk(
  'helpTickets/addHelpTicketFiles',
  async ({ ticketId, files }) => {
    if (!files || !files.length) {
      throw new Error('Please select files first');
    }

    const formData = new FormData();

    files.forEach((file, index) => {
      formData.append(`files[${index}]`, file);
    });

    const { data } = await api.post(
      `/help-tickets/files/${ticketId}`,
      formData,
      {
        headers: {
          'content-type': 'multipart/form-data',
        },
      },
    );

    return { data, ticketId };
  },
);

const helpTickets = createSlice({
  name: 'helpTickets',

  initialState,

  reducers: {
    clearHelpTickets: draft => {
      draft.helpTickets = initialState.helpTickets;
    },

    clearHelpTicket: draft => {
      draft.helpTicket = initialState.helpTicket;
    },

    clearHelpTicketIds: draft => {
      draft.helpTicketIds = initialState.helpTicketIds;
    },

    clearHelpTicketFiles: draft => {
      draft.files = initialState.files;
    },
  },

  extraReducers: builder => {
    builder.addCase(getHelpTickets.fulfilled, (draft, { payload }) => {
      draft.helpTickets.list = payload.data.helpTickets;
      draft.helpTickets.page = payload.data.page;
      draft.helpTickets.query = payload.query;
      draft.helpTickets.totalHelpTickets = payload.data.totalHelpTickets;
      draft.helpTickets.totalPages = payload.data.totalPages;
    });

    builder.addCase(getHelpTicket.fulfilled, (draft, { payload }) => {
      draft.helpTicket = payload;
    });

    builder.addCase(deleteHelpTicket.fulfilled, (draft, { payload }) => {
      const index = draft.helpTickets.list.findIndex(
        el => el.id === payload.id,
      );

      if (index > -1) {
        draft.helpTickets.list.splice(index, 1);
      }
    });

    builder.addCase(getHelpTicketIds.fulfilled, (draft, { payload }) => {
      draft.helpTicketIds = payload;
    });

    builder.addCase(getHelpTicketFiles.fulfilled, (draft, { payload }) => {
      draft.files = payload;
    });

    builder.addCase(deleteHelpTicketFile.fulfilled, (draft, { payload }) => {
      draft.files = draft.files.filter(file => file.id !== payload.fileId);

      const helpTicket = draft.helpTickets.list.find(
        el => el.id === payload.ticketId,
      );

      if (helpTicket) {
        helpTicket.noOfFiles = +helpTicket.noOfFiles - 1;
      }
    });

    builder.addCase(addHelpTicketFiles.fulfilled, (draft, { payload }) => {
      draft.files.push(...payload.data.uploaded);

      const helpTicket = draft.helpTickets.list.find(
        el => el.id === payload.ticketId,
      );

      if (helpTicket) {
        helpTicket.noOfFiles =
          +helpTicket.noOfFiles + payload.data.uploaded.length;
      }

      if (payload.data.failed.length) {
        errorNotification(
          new Error(`${payload.data.failed.join(', ')} failed to upload`),
        );
      }
    });

    builder.addMatcher(
      isAnyOf(
        getHelpTickets.rejected,
        getHelpTicket.rejected,
        deleteHelpTicket.rejected,
        getHelpTicketIds.rejected,
        getHelpTicketFiles.rejected,
        deleteHelpTicketFile.rejected,
        addHelpTicketFiles.rejected,
      ),
      (_Draft, { error }) => {
        errorNotification(error);
      },
    );
  },
});

export const {
  clearHelpTickets,
  clearHelpTicket,
  clearHelpTicketIds,
  clearHelpTicketFiles,
} = helpTickets.actions;

export default helpTickets.reducer;
