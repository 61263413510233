import React from 'react';
import { notification } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';

/**
 * @param { String } message
 */
export const successNotification = message => {
  notification.success({ message });
};

/**
 * @param { String } message
 */
export const infoNotification = message => {
  notification.info({ message });
};

/**
 * @param { String } message
 */
export const warningNotification = message => {
  notification.warning({ message });
};

/**
 * @param { Error } error
 */
export const errorNotification = error => {
  notification.error({
    message: error?.message || error || 'Unknown Error',
    icon: <ExclamationCircleOutlined style={{ color: 'red' }} />,
  });
};
