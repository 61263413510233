import { createAsyncThunk, createSlice, isAnyOf } from '@reduxjs/toolkit';

import { errorNotification } from '../../components/notifications';
import api from '../../libs/api';

const initialState = {
  bills: {
    list: [],
    page: 1,
    query: { page: 1 },
    totalPages: 1,
    totalBills: 0,
  },
  bill: { products: [] },
  files: [],
  reports: {},
};

export const getBills = createAsyncThunk(
  'bills/getBills',
  async (query, { getState }) => {
    const currentQuery = getState().bills.bills.query;

    const effectiveQuery = query || currentQuery;

    const { data } = await api.get('/bills', effectiveQuery);

    return { data, query: effectiveQuery };
  },
);

export const getBill = createAsyncThunk('bills/getBill', async id => {
  const { data } = await api.get(`/bills/${id}`);

  return data;
});

export const deleteBill = createAsyncThunk(
  'bills/deleteBill',
  async (id, { getState }) => {
    const userName = getState().loginInfo.user.name;

    await api.delete(`/bills/${id}`);

    return { id, userName };
  },
);

export const getBillFiles = createAsyncThunk(
  'bills/getBillFiles',
  async billId => {
    const { data } = await api.get(`/bills/files/${billId}`);

    return data;
  },
);

export const deleteBillFile = createAsyncThunk(
  'bills/deleteBillFiles',
  async ({ fileId, billId }) => {
    await api.delete(`/bills/files/${fileId}`);

    return { fileId, billId };
  },
);

export const addBillFiles = createAsyncThunk(
  'bills/addBillFiles',
  async ({ billId, files }) => {
    if (!files || !files.length) {
      throw new Error('Please select files first');
    }

    const formData = new FormData();

    files.forEach((file, index) => {
      formData.append(`files[${index}]`, file);
    });

    const { data } = await api.post(`/bills/files/${billId}`, formData, {
      headers: {
        'content-type': 'multipart/form-data',
      },
    });

    return { data, billId };
  },
);

const bills = createSlice({
  name: 'bills',

  initialState,

  reducers: {
    clearBills: draft => {
      draft.bills = initialState.bills;
    },

    clearBill: draft => {
      draft.bill = initialState.bill;
    },

    clearBillFiles: draft => {
      draft.files = initialState.files;
    },
  },

  extraReducers: builder => {
    builder.addCase(getBills.fulfilled, (draft, { payload }) => {
      draft.bills.list = payload.data.bills;
      draft.bills.page = payload.data.page;
      draft.bills.query = payload.query;
      draft.bills.totalBills = payload.data.totalBills;
      draft.bills.totalPages = payload.data.totalPages;
    });

    builder.addCase(getBill.fulfilled, (draft, { payload }) => {
      draft.bill = payload;
    });

    builder.addCase(deleteBill.fulfilled, (draft, { payload }) => {
      const index = draft.bills.list.findIndex(el => el.id === payload.id);

      if (index > -1) {
        draft.bills.list.splice(index, 1);
      }
    });

    builder.addCase(getBillFiles.fulfilled, (draft, { payload }) => {
      draft.files = payload;
    });

    builder.addCase(deleteBillFile.fulfilled, (draft, { payload }) => {
      draft.files = draft.files.filter(file => file.id !== payload.fileId);

      const bill = draft.bills.list.find(el => el.id === payload.billId);

      if (bill) {
        bill.noOfFiles = +bill.noOfFiles - 1;
      }
    });

    builder.addCase(addBillFiles.fulfilled, (draft, { payload }) => {
      draft.files.push(...payload.data.uploaded);

      const bill = draft.bills.list.find(el => el.id === payload.billId);

      if (bill) {
        bill.noOfFiles = +bill.noOfFiles + payload.data.uploaded.length;
      }

      if (payload.data.failed.length) {
        errorNotification(
          new Error(`${payload.data.failed.join(', ')} failed to upload`),
        );
      }
    });

    builder.addMatcher(
      isAnyOf(
        getBills.rejected,
        getBill.rejected,
        deleteBill.rejected,
        getBillFiles.rejected,
        deleteBillFile.rejected,
        addBillFiles.rejected,
      ),
      (_Draft, { error }) => {
        errorNotification(error);
      },
    );
  },
});

export const { clearBills, clearBill, clearBillFiles } = bills.actions;

export default bills.reducer;
