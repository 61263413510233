import React from 'react';
import {
  UnorderedListOutlined,
  PlusCircleOutlined,
  SettingOutlined,
  DeploymentUnitOutlined,
  ContactsOutlined,
  ExperimentOutlined,
  ControlOutlined,
  TeamOutlined,
  DatabaseOutlined,
  SafetyCertificateOutlined,
  ShoppingOutlined,
  ShoppingCartOutlined,
  FileSearchOutlined,
  UserAddOutlined,
  ProjectOutlined,
  FileUnknownOutlined,
  ShopOutlined,
  BulbOutlined,
  CalendarOutlined,
  WalletOutlined,
} from '@ant-design/icons';

import PERMISSIONS from '../../libs/permissions';

export const Modules = [
  {
    type: 'list',
    title: 'Task',
    key: 'Task.Module',
    icon: <ProjectOutlined />,
    subModules: [
      {
        type: 'item',
        module: PERMISSIONS.getChecklists,
        title: 'Checklists',
        key: 'Checklists',
        link: '/tasks/checklists',
        icon: <UnorderedListOutlined />,
      },
    ],
  },
  {
    type: 'list',
    title: 'Dashboard',
    key: 'Dashboard.Module',
    icon: <ProjectOutlined />,
    subModules: [
      {
        type: 'item',
        module: PERMISSIONS.orderDashboard,
        title: 'Not punched',
        key: 'Products Not punched as Orders',
        link: '/dashboard/order',
        icon: <UnorderedListOutlined />,
      },
    ],
  },
  {
    type: 'list',
    title: 'Financials',
    key: 'Financials.Module',
    icon: <ProjectOutlined />,
    subModules: [
      {
        type: 'item',
        module: PERMISSIONS.financeReports,
        title: 'Financial Summary',
        key: 'Financial Summary',
        link: '/financials/financial-summary',
        icon: <UnorderedListOutlined />,
      },
      {
        type: 'item',
        module: PERMISSIONS.financeReports,
        title: 'Balance Sheet',
        key: 'Balance Sheet',
        link: '/financials/balance-sheet',
        icon: <UnorderedListOutlined />,
      },
    ],
  },
  {
    type: 'list',
    title: 'Reports',
    key: 'Reports.Module',
    icon: <ProjectOutlined />,
    subModules: [
      {
        type: 'item',
        module: 'userWiseOrderReport',
        title: 'User Wise Orders',
        key: 'User Wise Orders',
        link: '/reports/userwiseorders',
        icon: <UnorderedListOutlined />,
      },
      {
        type: 'item',
        module: 'userWiseOrderReport',
        title: 'Yearly PI Figures',
        key: 'Yearly PI Figures',
        link: '/reports/pi-figures/yearly',
        icon: <UnorderedListOutlined />,
      },
      {
        type: 'item',
        module: 'saleFigures',
        title: 'Sale Figures',
        key: 'Sale Figures',
        link: '/reports/sale-figures',
        icon: <UnorderedListOutlined />,
      },
      {
        type: 'item',
        module: 'salesMis',
        title: 'Sale MIS',
        key: 'Sale MIS',
        link: '/reports/sale-mis',
        icon: <UnorderedListOutlined />,
      },
      {
        type: 'item',
        module: 'yealySaleFigures',
        title: 'Yearly Sale Figures',
        key: 'Yearly Sale Figures',
        link: '/reports/sale-figures/yearly',
        icon: <UnorderedListOutlined />,
      },
      {
        type: 'item',
        module: 'yealySaleFigures',
        title: 'Yearly Sale Chart',
        key: 'Yearly Sale Figures Chart',
        link: '/reports/sale-figures/yearly/chart',
        icon: <UnorderedListOutlined />,
      },
      {
        type: 'item',
        module: 'lastSellingProductGpValues',
        title: 'Product GP Values',
        key: 'Product GP Values',
        link: '/reports/lastsellinggp',
        icon: <UnorderedListOutlined />,
      },
      {
        type: 'item',
        module: 'lastSellingProductGpValues',
        title: 'Composition GP Values',
        key: 'Composition GP Values',
        link: '/reports/composition-gp',
        icon: <UnorderedListOutlined />,
      },
      {
        type: 'item',
        module: 'orderFMS',
        title: 'Order FMS',
        key: 'Order FMS',
        link: '/reports/orderfms',
        icon: <UnorderedListOutlined />,
      },
      {
        type: 'item',
        module: 'orderMIS',
        title: 'Order MIS',
        key: 'Order MIS',
        link: '/reports/ordermis',
        icon: <UnorderedListOutlined />,
      },
      {
        type: 'item',
        module: 'onTimeDeliveryReport',
        title: 'On Time Delivery',
        key: 'On Time Delivery',
        link: '/reports/on-time-delivery',
        icon: <UnorderedListOutlined />,
      },
      {
        type: 'item',
        module: 'onTimeReceivedReport',
        title: 'On Time Received',
        key: 'On Time Received',
        link: '/reports/on-time-received',
        icon: <UnorderedListOutlined />,
      },
      {
        type: 'item',
        module: 'yealyPurchaseFigures',
        title: 'Yearly Purchase Figures',
        key: 'Yearly Purchase Figures',
        link: '/reports/purchase-figures/yearly',
        icon: <UnorderedListOutlined />,
      },
    ],
  },
  {
    type: 'list',
    title: 'Accounts',
    key: 'Accounts.Module',
    icon: <ProjectOutlined />,
    subModules: [
      {
        type: 'item',
        module: PERMISSIONS.viewPartyLedger,
        title: 'Party Ledger',
        key: 'Party Ledger',
        link: '/parties/ledger',
        icon: <WalletOutlined />,
      },
      {
        type: 'item',
        module: PERMISSIONS.getTransactions,
        title: 'Transactions',
        key: 'Transactions',
        link: '/accounts/transactions',
        icon: <UnorderedListOutlined />,
      },
      {
        type: 'item',
        module: PERMISSIONS.getNarrations,
        title: 'Narrations',
        key: 'Narrations',
        link: '/accounts/narrations',
        icon: <UnorderedListOutlined />,
      },
      {
        type: 'item',
        module: PERMISSIONS.getBanks,
        title: 'Banks',
        key: 'Banks',
        link: '/accounts/banks',
        icon: <UnorderedListOutlined />,
      },
      {
        type: 'item',
        module: PERMISSIONS.getContacts,
        title: 'Contacts',
        key: 'Contacts',
        link: '/accounts/contacts',
        icon: <UnorderedListOutlined />,
      },
      {
        type: 'item',
        module: PERMISSIONS.getAssets,
        title: 'Assets',
        key: 'Assets',
        link: '/accounts/assets',
        icon: <UnorderedListOutlined />,
      },
      {
        type: 'item',
        module: PERMISSIONS.getAssets,
        title: 'Asset Adjust',
        key: 'Asset Adjust',
        link: '/accounts/assets/adjusts',
        icon: <UnorderedListOutlined />,
      },
      {
        type: 'list',
        title: 'Bill',
        key: 'Bill',
        icon: <UnorderedListOutlined />,
        subModules: [
          {
            type: 'item',
            module: PERMISSIONS.getBills,
            title: 'Bills',
            key: 'Bills',
            link: '/accounts/bills',
            icon: <UnorderedListOutlined />,
          },
          {
            type: 'item',
            module: PERMISSIONS.addBill,
            title: 'Add Bill',
            key: 'Add Bill',
            link: '/accounts/bills/add',
            icon: <PlusCircleOutlined />,
          },
        ],
      },
    ],
  },
  {
    type: 'list',
    title: 'Help Tickets',
    key: 'HelpTickets.Module',
    icon: <UnorderedListOutlined />,
    subModules: [
      {
        type: 'item',
        module: PERMISSIONS.getHelpTickets,
        title: 'Tickets',
        key: 'Tickets',
        link: '/help-tickets',
        icon: <UnorderedListOutlined />,
      },
      {
        type: 'item',
        module: PERMISSIONS.addHelpTicket,
        title: 'Add Ticket',
        key: 'Add Ticket',
        link: '/help-tickets/add',
        icon: <PlusCircleOutlined />,
      },
    ],
  },
  {
    type: 'list',
    title: 'CRM',
    key: 'CRM.Module',
    icon: <UnorderedListOutlined />,
    subModules: [
      {
        type: 'item',
        module: PERMISSIONS.getLeads,
        title: 'Enquiry FMS',
        key: 'Enquiry FMS',
        link: '/crm/enquiry/fms',
        icon: <UnorderedListOutlined />,
      },
      {
        type: 'item',
        module: PERMISSIONS.getLeads,
        title: 'Leads',
        key: 'Leads',
        link: '/crm/leads',
        icon: <UnorderedListOutlined />,
      },
      {
        type: 'item',
        module: PERMISSIONS.addLead,
        title: 'Add Lead',
        key: 'Add Lead',
        link: '/crm/leads/add',
        icon: <PlusCircleOutlined />,
      },
      {
        type: 'item',
        module: PERMISSIONS.getLeads,
        title: 'Calendar',
        key: 'Calendar',
        link: '/crm/interactions/calendar',
        icon: <CalendarOutlined />,
      },
      {
        type: 'item',
        module: PERMISSIONS.getLeadOrigins,
        title: 'Origins',
        key: 'Origins',
        link: '/dropdowns/origins',
        icon: <UnorderedListOutlined />,
      },
    ],
  },
  {
    type: 'list',
    title: 'Quotations',
    key: 'Quotation.Module',
    icon: <FileSearchOutlined />,
    subModules: [
      {
        type: 'item',
        module: PERMISSIONS.getQuotations,
        title: 'Quotations',
        key: 'Quotations',
        link: '/quotations',
        icon: <UnorderedListOutlined />,
      },
      {
        type: 'item',
        module: PERMISSIONS.addQuotation,
        title: 'Add Quotation',
        key: 'Add Quotation',
        link: '/quotations/add',
        icon: <PlusCircleOutlined />,
      },
    ],
  },
  {
    type: 'list',
    title: 'Proforma Invoices',
    key: 'ProformaInvoice.Module',
    icon: <FileSearchOutlined />,
    subModules: [
      {
        type: 'item',
        module: PERMISSIONS.getProformaInvoices,
        title: 'Proforma Invoices',
        key: 'Proforma Invoices',
        link: '/proforma-invoices',
        icon: <UnorderedListOutlined />,
      },
      {
        type: 'item',
        module: PERMISSIONS.addProformaInvoice,
        title: 'Add PI',
        key: 'Add PI',
        link: '/proforma-invoices/add',
        icon: <PlusCircleOutlined />,
      },
    ],
  },
  {
    type: 'list',
    title: 'Sale Orders',
    key: 'SaleOrder.Module',
    icon: <FileSearchOutlined />,
    subModules: [
      {
        type: 'item',
        module: PERMISSIONS.getSaleOrders,
        title: 'Sale Orders',
        key: 'Sale Orders',
        link: '/sale-orders',
        icon: <UnorderedListOutlined />,
      },
      {
        type: 'item',
        module: PERMISSIONS.addSaleOrder,
        title: 'Add SO',
        key: 'Add SO',
        link: '/sale-orders/add',
        icon: <PlusCircleOutlined />,
      },
    ],
  },
  {
    type: 'list',
    title: 'Orders',
    key: 'Orders.Module',
    icon: <ProjectOutlined />,
    subModules: [
      {
        type: 'item',
        module: PERMISSIONS.getOrders,
        title: 'Orders',
        key: 'Orders',
        link: '/orders',
        icon: <UnorderedListOutlined />,
      },
      {
        type: 'item',
        module: PERMISSIONS.addOrder,
        title: 'Add Order',
        key: 'Add Order',
        link: '/orders/add',
        icon: <PlusCircleOutlined />,
      },
    ],
  },
  {
    type: 'item',
    module: PERMISSIONS.production,
    title: 'Production',
    key: 'Production',
    link: '/orders/production',
    icon: <BulbOutlined />,
  },
  {
    type: 'list',
    title: 'Dispatch',
    key: 'Dispatch',
    icon: <BulbOutlined />,
    subModules: [
      {
        type: 'item',
        module: PERMISSIONS.getSales,
        title: 'Packing Pending',
        key: 'Packing Pending',
        link: '/dispatch/packing-pending',
        icon: <UnorderedListOutlined />,
      },
    ],
  },
  {
    type: 'list',
    title: 'Purchase Orders',
    key: 'PurchaseOrders.Module',
    icon: <ShoppingCartOutlined />,
    subModules: [
      {
        type: 'item',
        module: PERMISSIONS.getPurchaseOrders,
        title: 'Purchase Orders',
        key: 'Purchase Orders',
        link: '/purchase-orders',
        icon: <UnorderedListOutlined />,
      },
      {
        type: 'item',
        module: PERMISSIONS.addPurchaseOrder,
        title: 'Add PO',
        key: 'Add PO',
        link: '/purchase-orders/add',
        icon: <PlusCircleOutlined />,
      },
      {
        type: 'item',
        module: PERMISSIONS.getPurchaseOrders,
        title: 'Calendar',
        key: 'PO Calendar',
        link: '/purchase-orders/calendar',
        icon: <CalendarOutlined />,
      },
    ],
  },
  {
    type: 'list',
    title: 'Purchases (Inward)',
    key: 'Purchases.Module',
    icon: <ShoppingOutlined />,
    subModules: [
      {
        type: 'item',
        module: PERMISSIONS.getPurchases,
        title: 'Purchases (Inward)',
        key: 'Purchases',
        link: '/purchases',
        icon: <UnorderedListOutlined />,
      },
      {
        type: 'item',
        module: PERMISSIONS.addPurchase,
        title: 'Add Purchase (Inward)',
        key: 'Add Purchase',
        link: '/purchases/add',
        icon: <PlusCircleOutlined />,
      },
      {
        type: 'item',
        module: PERMISSIONS.getPurchases,
        title: 'Purchases Without Tax',
        key: 'Purchases Without Tax',
        link: '/reports/purchases-without-tax',
        icon: <UnorderedListOutlined />,
      },
    ],
  },
  {
    type: 'list',
    title: 'Invoices / Challans',
    key: 'Sale.Module',
    icon: <ShopOutlined />,
    subModules: [
      {
        type: 'item',
        module: PERMISSIONS.getSales,
        title: 'Invoices',
        key: 'Sale Invoices',
        link: '/sales',
        icon: <UnorderedListOutlined />,
      },
      {
        type: 'item',
        module: PERMISSIONS.addSale,
        title: 'Add Invoice',
        key: 'Add Sale / Challan',
        link: '/sales/add',
        icon: <PlusCircleOutlined />,
      },
      {
        type: 'item',
        module: PERMISSIONS.getSales,
        title: 'Sales Without Tax',
        key: 'Sales Without Tax',
        link: '/reports/sales-without-tax',
        icon: <UnorderedListOutlined />,
      },
    ],
  },
  {
    type: 'list',
    title: 'Pre Invoices',
    key: 'PreInvoice.Module',
    icon: <ShopOutlined />,
    subModules: [
      {
        type: 'item',
        module: PERMISSIONS.getPreSales,
        title: 'Pre Invoices',
        key: 'Pre Invoices',
        link: '/pre-sales',
        icon: <UnorderedListOutlined />,
      },
      {
        type: 'item',
        module: PERMISSIONS.addPreSale,
        title: 'Add Pre Invoice',
        key: 'Add Pre Invoice',
        link: '/pre-sales/add',
        icon: <PlusCircleOutlined />,
      },
    ],
  },
  {
    type: 'list',
    title: 'Credit/Debit Note',
    key: 'CDNS.Module',
    icon: <ShopOutlined />,
    subModules: [
      {
        type: 'item',
        module: PERMISSIONS.getCDNs,
        title: 'Credit/Debit Notes',
        key: 'Credit/Debit Notes',
        link: '/cdns',
        icon: <UnorderedListOutlined />,
      },
      {
        type: 'item',
        module: PERMISSIONS.addCDN,
        title: 'Add Note',
        key: 'Add Note',
        link: '/cdns/add',
        icon: <PlusCircleOutlined />,
      },
    ],
  },
  {
    type: 'list',
    title: 'Inventory',
    key: 'Inventory.Module',
    icon: <DatabaseOutlined />,
    subModules: [
      {
        type: 'list',
        title: 'Material/Item',
        key: 'Material.Inventory.Module',
        icon: <DatabaseOutlined />,
        subModules: [
          {
            type: 'item',
            module: PERMISSIONS.getInventory,
            title: 'Stock',
            key: 'Material Inventory',
            link: '/inventory/materials',
            icon: <UnorderedListOutlined />,
          },
          {
            type: 'item',
            module: PERMISSIONS.getMaterialsToOrder,
            title: 'To Order',
            key: 'Materials To Order',
            link: '/inventory/materialstoorder',
            icon: <UnorderedListOutlined />,
          },
          {
            type: 'item',
            module: PERMISSIONS.adjustInventory,
            title: 'Adjust Form',
            key: 'Adjust Form',
            link: '/inventory/materials/adjustment-form',
            icon: <UnorderedListOutlined />,
          },
          {
            type: 'item',
            module: PERMISSIONS.adjustInventory,
            title: 'Adjustments',
            key: 'Adjustments',
            link: '/inventory/materials/adjustments',
            icon: <UnorderedListOutlined />,
          },
          {
            type: 'item',
            module: PERMISSIONS.transferInventory,
            title: 'Transfer Form',
            key: 'Material Stock Transfer Form',
            link: '/inventory/materials/transfer-form',
            icon: <UnorderedListOutlined />,
          },
          {
            type: 'item',
            module: PERMISSIONS.transferInventory,
            title: 'Transfers',
            key: 'Material Stock Transfers',
            link: '/inventory/materials/transfers',
            icon: <UnorderedListOutlined />,
          },
        ],
      },
      {
        type: 'list',
        title: 'Product/Finished',
        key: 'Product.Inventory.Module',
        icon: <DatabaseOutlined />,
        subModules: [
          {
            type: 'item',
            module: PERMISSIONS.getInventory,
            title: 'Stock',
            key: 'Product Inventory',
            link: '/inventory/products',
            icon: <UnorderedListOutlined />,
          },
          {
            type: 'item',
            module: PERMISSIONS.getProductsToOrder,
            title: 'To Order',
            key: 'Products To Order',
            link: '/inventory/products-to-order',
            icon: <UnorderedListOutlined />,
          },
          {
            type: 'item',
            module: PERMISSIONS.adjustInventory,
            title: 'Adjust Form',
            key: 'Product Stock Adjust Form',
            link: '/inventory/products/adjustment-form',
            icon: <UnorderedListOutlined />,
          },
          {
            type: 'item',
            module: PERMISSIONS.adjustInventory,
            title: 'Adjustments',
            key: 'Product Stock Adjustments',
            link: '/inventory/products/adjustments',
            icon: <UnorderedListOutlined />,
          },
          {
            type: 'item',
            module: PERMISSIONS.transferInventory,
            title: 'Transfer Form',
            key: 'Product Stock Transfer Form',
            link: '/inventory/products/transfer-form',
            icon: <UnorderedListOutlined />,
          },
          {
            type: 'item',
            module: PERMISSIONS.transferInventory,
            title: 'Transfers',
            key: 'Product Stock Transfers',
            link: '/inventory/products/transfers',
            icon: <UnorderedListOutlined />,
          },
        ],
      },
    ],
  },
  {
    type: 'item',
    module: PERMISSIONS.getRfqs,
    title: 'RFQs',
    key: 'Rfqs.Module',
    icon: <DatabaseOutlined />,
    link: '/rfqs',
  },
  {
    type: 'list',
    title: 'Products/Finished',
    key: 'Products.Module',
    icon: <ExperimentOutlined />,
    subModules: [
      {
        type: 'item',
        module: PERMISSIONS.getProducts,
        title: 'Products/Finished',
        key: 'Products',
        link: '/products',
        icon: <UnorderedListOutlined />,
      },
      {
        type: 'item',
        module: PERMISSIONS.addProduct,
        title: 'Add Product/Finished',
        key: 'Add Product',
        link: '/products/add',
        icon: <PlusCircleOutlined />,
      },
      {
        type: 'item',
        module: PERMISSIONS.productCosting,
        title: 'Costing',
        key: 'Product Costing',
        link: '/products/costing',
        icon: <FileUnknownOutlined />,
      },
    ],
  },
  {
    type: 'list',
    title: 'Materials/Items',
    key: 'Materials.Module',
    icon: <DeploymentUnitOutlined />,
    subModules: [
      {
        type: 'item',
        module: PERMISSIONS.getMaterials,
        title: 'Materials/Items',
        key: 'Materials',
        link: '/materials',
        icon: <UnorderedListOutlined />,
      },
      {
        type: 'item',
        module: PERMISSIONS.addMaterial,
        title: 'Add Material/Item',
        key: 'Add Material',
        link: '/materials/add',
        icon: <PlusCircleOutlined />,
      },
    ],
  },
  {
    type: 'list',
    title: 'Parties',
    key: 'Parties.Module',
    icon: <ContactsOutlined />,
    subModules: [
      {
        type: 'item',
        module: PERMISSIONS.getParties,
        title: 'Parties',
        key: 'Parties',
        link: '/Parties',
        icon: <UnorderedListOutlined />,
      },
      {
        type: 'item',
        module: PERMISSIONS.addParty,
        title: 'Add Party',
        key: 'Add Party',
        link: '/parties/add',
        icon: <PlusCircleOutlined />,
      },
    ],
  },
  {
    type: 'list',
    title: 'Machines',
    key: 'Machines.Module',
    icon: <ControlOutlined />,
    subModules: [
      {
        type: 'item',
        module: PERMISSIONS.getMachines,
        title: 'Machines',
        key: 'Machines',
        link: '/machines',
        icon: <UnorderedListOutlined />,
      },
      {
        type: 'item',
        module: PERMISSIONS.addMachine,
        title: 'Add Machine',
        key: 'Add Machine',
        link: '/machines/add',
        icon: <PlusCircleOutlined />,
      },
    ],
  },
  {
    type: 'item',
    module: PERMISSIONS.getSops,
    title: 'SOPs',
    key: 'Sops.Module',
    link: '/sops',
    icon: <SafetyCertificateOutlined />,
  },
  {
    type: 'list',
    title: 'Dropdowns',
    key: 'Dropdowns',
    icon: <SettingOutlined />,
    subModules: [
      {
        type: 'item',
        module: PERMISSIONS.getPackings,
        title: 'Packings',
        key: 'Packings',
        link: '/dropdowns/packings',
        icon: <UnorderedListOutlined />,
      },
      {
        type: 'item',
        module: PERMISSIONS.getUnits,
        title: 'Units',
        key: 'Units',
        link: '/dropdowns/units',
        icon: <UnorderedListOutlined />,
      },
      {
        type: 'item',
        module: PERMISSIONS.getHsns,
        title: 'HSN',
        key: 'HSN',
        link: '/dropdowns/hsns',
        icon: <UnorderedListOutlined />,
      },
      {
        type: 'item',
        module: PERMISSIONS.getCharges,
        title: 'Charges',
        key: 'Charges',
        link: '/dropdowns/charges',
        icon: <UnorderedListOutlined />,
      },
      {
        type: 'item',
        module: PERMISSIONS.getConsignors,
        title: 'Consignors',
        key: 'Consignors',
        link: '/dropdowns/consignors',
        icon: <UnorderedListOutlined />,
      },
    ],
  },
  {
    type: 'list',
    title: 'Users',
    key: 'Users.Module',
    icon: <TeamOutlined />,
    subModules: [
      {
        type: 'item',
        module: PERMISSIONS.getUsers,
        title: 'Users',
        key: 'Users',
        link: '/users',
        icon: <UnorderedListOutlined />,
      },
      {
        type: 'item',
        module: PERMISSIONS.addUser,
        title: 'Add User',
        key: 'Add User',
        link: '/users/add',
        icon: <UserAddOutlined />,
      },
      {
        type: 'item',
        module: PERMISSIONS.getRoles,
        title: 'Roles',
        key: 'User Roles',
        link: 'users/roles',
        icon: <UnorderedListOutlined />,
      },
      {
        type: 'item',
        module: PERMISSIONS.addRole,
        title: 'Add Role',
        key: 'Add Role',
        link: 'users/roles/add',
        icon: <PlusCircleOutlined />,
      },
    ],
  },
];

export const getSubModules = modules => {
  const list = [];

  modules.map(({ type, module, subModules }) => {
    if (type === 'item') {
      list.push(module);
    } else if (type === 'list') {
      list.push(...getSubModules(subModules));
    }
  });

  return list;
};

const moduleLinkings = {};

Modules.map(el => {
  if (el.subModules && el.subModules.length) {
    el.subModules.map(({ key, link }) => {
      moduleLinkings[link] = key;
    });
  }
});

export const getDefaultSelectedKeys = (link = '') => moduleLinkings[link];
