import Axios from 'axios';

const axios = Axios.create({
  baseURL:
    process.env.REACT_APP_BACKEND_URL || `http://${window.location.host}/api/`,
});

/**
 * @param { string } url - Url Endpoint of api
 * - Example /api/list
 * @param { Object.<string, (string | string[])> } query - Query Parameters to pass in URL
 * @returns { Promise<import('axios').AxiosResponse> }
 */
const get = async (url, query) => {
  try {
    const response = await axios.get(url, {
      headers: {
        authorization: localStorage.getItem('token') || '',
      },
      params: query,
    });

    return response;
  } catch (error) {
    throw new Error(error.response?.data?.message || error.message);
  }
};

/**
 * @param { string } url - Url Endpoint of api
 * - Example /api/file/something.pdf
 * @param { Object.<string, (string | string[])> } query - Query Parameters to pass in URL
 * @returns { Promise<import('axios').AxiosResponse> }
 */
const getFile = async (url, params) => {
  try {
    const response = await axios.get(url, {
      headers: {
        authorization: localStorage.getItem('token') || '',
      },
      responseType: 'blob',
      params,
    });

    return response;
  } catch (error) {
    throw new Error(JSON.parse(await error.response.data.text()).message);
  }
};

/**
 * @param { string } url - Url Endpoint of api
 * - Example /api/add
 * @param { Any } [body] - Query Parameters to pass in URL
 * @param { import('axios').AxiosRequestConfig } options
 * @returns { Promise<import('axios').AxiosResponse> }
 */
const post = async (url, body, options = {}) => {
  try {
    const response = await axios.post(url, body, {
      ...options,
      headers: {
        ...(options.headers || {}),
        authorization: localStorage.getItem('token') || '',
      },
    });

    return response;
  } catch (error) {
    throw new Error(new Error(error.response?.data?.message || error.message));
  }
};

/**
 * @param { string } url - Url Endpoint of api
 * - Example /api/add
 * @param { Any } [body] - Query Parameters to pass in URL
 * @param { import('axios').AxiosRequestConfig } options
 * @returns { Promise<import('axios').AxiosResponse> }
 */
const postFile = async (url, body, options = {}) => {
  try {
    const response = await axios.post(url, body, {
      ...options,
      headers: {
        ...(options.headers || {}),
        authorization: localStorage.getItem('token') || '',
      },
      responseType: 'blob',
    });

    return response;
  } catch (error) {
    throw new Error(JSON.parse(await error.response.data.text()).message);
  }
};

/**
 * @param { string } url - Url Endpoint of api
 * - Example /api/edit
 * @param { Any } body - Query Parameters to pass in URL
 * @param { import('axios').AxiosRequestConfig } options
 * @returns { Promise<import('axios').AxiosResponse> }
 */
const put = async (url, body, options = {}) => {
  try {
    const response = await axios.put(url, body, {
      ...options,
      headers: {
        ...(options.headers || {}),
        authorization: localStorage.getItem('token') || '',
      },
    });

    return response;
  } catch (error) {
    throw new Error(error.response?.data?.message || error.message);
  }
};

/**
 * @param { string } url - Url Endpoint of api
 * - Example /api/edit
 * @param { Any } body - Query Parameters to pass in URL
 * @param { import('axios').AxiosRequestConfig } options
 * @returns { Promise<import('axios').AxiosResponse> }
 */
const patch = async (url, body, options = {}) => {
  try {
    const response = await axios.patch(url, body, {
      ...options,
      headers: {
        ...(options.headers || {}),
        authorization: localStorage.getItem('token') || '',
      },
    });

    return response;
  } catch (error) {
    throw new Error(error.response?.data?.message || error.message);
  }
};

/**
 * @param { string } url - Url Endpoint of api
 * - Example /api/delete
 * @param { Any } [body] - Query Parameters to pass in URL
 * @param { import('axios').AxiosRequestConfig } [options]
 * @returns { Promise<import('axios').AxiosResponse> }
 */
const _delete = async (url, body, options = {}) => {
  try {
    const response = await axios.delete(url, {
      ...options,
      headers: {
        ...(options.headers || {}),
        authorization: localStorage.getItem('token') || '',
      },
      data: body,
    });

    return response;
  } catch (error) {
    throw new Error(new Error(error.response?.data?.message || error.message));
  }
};

export default {
  get,
  getFile,
  post,
  put,
  patch,
  delete: _delete,
  postFile,
};
