import { createAsyncThunk, createSlice, isAnyOf } from '@reduxjs/toolkit';

import { errorNotification } from '../../components/notifications';
import api from '../../libs/api';

const initialState = {
  narrations: [],
  narration: {},
  narrationIds: [],
};

export const getNarrations = createAsyncThunk(
  'narrations/getNarrations',
  async () => {
    const { data } = await api.get('/narrations');

    return data;
  },
);

export const getNarrationIds = createAsyncThunk(
  'narrations/getNarrationIds',
  async () => {
    const { data } = await api.get('/narrations/ids');

    return data;
  },
);

export const getNarration = createAsyncThunk(
  'narrations/getNarration',
  async id => {
    const { data } = await api.get(`/narrations/${id}`);

    return data;
  },
);

export const addNarration = createAsyncThunk(
  'narrations/addNarration',
  async data => {
    const { data: narration } = await api.post('/narrations', data);

    return narration;
  },
);

export const editNarration = createAsyncThunk(
  'narrations/editNarration',
  async ({ id, data }) => {
    const { data: narration } = await api.put(`/narrations/${id}`, data);

    return narration;
  },
);

const narrations = createSlice({
  name: 'narrations',

  initialState,

  reducers: {
    clearNarrations: draft => {
      draft.narrations = initialState.narrations;
    },

    clearNarration: draft => {
      draft.narration = initialState.narration;
    },

    clearNarrationIds: draft => {
      draft.narrationIds = initialState.narrationIds;
    },
  },

  extraReducers: builder => {
    builder.addCase(getNarrations.fulfilled, (draft, { payload }) => {
      draft.narrations = payload;
    });

    builder.addCase(getNarration.fulfilled, (draft, { payload }) => {
      draft.narration = payload;
    });

    builder.addCase(addNarration.fulfilled, (draft, { payload }) => {
      draft.narrations.push(payload);
    });

    builder.addCase(editNarration.fulfilled, (draft, { payload }) => {
      const index = draft.narrations.findIndex(el => el.id === payload.id);

      draft.narrations[index] = payload;
    });

    builder.addCase(getNarrationIds.fulfilled, (draft, { payload }) => {
      draft.narrationIds = payload;
    });

    builder.addMatcher(
      isAnyOf(
        getNarrations.rejected,
        getNarration.rejected,
        addNarration.rejected,
        editNarration.rejected,
        getNarrationIds.rejected,
      ),
      (_Draft, { error }) => {
        errorNotification(error);
      },
    );
  },
});

export const { clearNarrations, clearNarration, clearNarrationIds } =
  narrations.actions;

export default narrations.reducer;
