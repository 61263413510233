import { createAsyncThunk, createSlice, isAnyOf } from '@reduxjs/toolkit';

import { errorNotification } from '../../components/notifications';
import api from '../../libs/api';

const initialState = {
  financialSummary: {
    date: Date.now(),
    otherIncome: [],
    directExpenseNarrations: [],
    inDirectExpenseNarrations: [],
    banks: [],
    sales: 0,
    purchases: 0,
    creditNotes: 0,
    debitNotes: 0,
    totalPurchases: 0,
    totalSales: 0,
    openingMaterialInventory: 0,
    closingMaterialInventory: 0,
    openingProductInventory: 0,
    closingProductInventory: 0,
    openingFlowMaterialInventory: 0,
    closingFlowMaterialInventory: 0,
    totalOpeningInventory: 0,
    totalClosingInventory: 0,
    totalDirectExpenses: 0,
    tvc: 0,
    totalOtherIncome: 0,
    grossProfit: 0,
    grossProfitPercentage: 0,
    totalDepriciation: 0,
    totalIndirectExpenses: 0,
    netProfitMargin: 0,
    netProfitMarginPercentage: 0,
  },
  balanceSheet: {
    date: Date.now(),
    initialCapitalAccount: 0,
    closingCapitalAccount: 0,
    initialCapitalAccounts: [],
    closingCapitalAccounts: [],
    initialLoan: 0,
    initialLoans: [],
    closingLoan: 0,
    closingLoans: [],
    initialOtherLoan: 0,
    initialOtherLoans: [],
    closingOtherLoan: 0,
    closingOtherLoans: [],
    initialCurrentLiability: 0,
    initialCurrentLiabilities: [],
    closingCurrentLiability: 0,
    closingCurrentLiabilities: [],
    initialAssetValue: 0,
    closingAssetValue: 0,
    initialBankBalances: [],
    closingBankBalances: [],
    openingPartyReceivable: [],
    totalOpeningPartyReceivable: 0,
    openingPartyPayable: [],
    totalOpeningPartyPayable: 0,
    closingPartyReceivable: [],
    totalClosingPartyReceivable: 0,
    closingPartyPayable: [],
    totalClosingPartyPayable: 0,
    openingMaterialInventory: 0,
    closingMaterialInventory: 0,
    openingProductInventory: 0,
    closingProductInventory: 0,
    openingFlowMaterialInventory: 0,
    closingFlowMaterialInventory: 0,
    totalOpeningInventory: 0,
    totalClosingInventory: 0,
    openingCurrentAssets: [],
    openingCurrentAssetsTotal: 0,
    closingCurrentAssets: [],
    closingCurrentAssetsTotal: 0,
  },
};

export const getFinancialSummary = createAsyncThunk(
  'financials/getFinancialSummary',
  async ({ from, to, consignorId }) => {
    const date = Date.now();

    const { data } = await api.post('/financials/summary', {
      from,
      to,
      consignorId,
    });

    return { ...data, date };
  },
);

export const getBalanceSheet = createAsyncThunk(
  'financials/getBalanceSheet',
  async ({ from, to, consignorId }) => {
    const date = Date.now();

    const { data } = await api.post('/financials/balance-sheet', {
      from,
      to,
      consignorId,
    });

    return { ...data, date };
  },
);

const financials = createSlice({
  name: 'financials',

  initialState,

  reducers: {
    clearFinancialSummary: draft => {
      draft.financialSummary = {
        ...initialState.financialSummary,
        date: Date.now(),
      };
    },

    clearBalanceSheet: draft => {
      draft.balanceSheet = {
        ...initialState.balanceSheet,
        date: Date.now(),
      };
    },
  },

  extraReducers: builder => {
    builder.addCase(getFinancialSummary.fulfilled, (draft, { payload }) => {
      if (draft.financialSummary.date <= payload.date) {
        draft.financialSummary = payload;
      }
    });

    builder.addCase(getBalanceSheet.fulfilled, (draft, { payload }) => {
      if (draft.balanceSheet.date <= payload.date) {
        draft.balanceSheet = payload;
      }
    });

    builder.addMatcher(
      isAnyOf(getFinancialSummary.rejected, getBalanceSheet.rejected),
      (_Draft, { error }) => {
        errorNotification(error);
      },
    );
  },
});

export const { clearFinancialSummary, clearBalanceSheet } = financials.actions;

export default financials.reducer;
