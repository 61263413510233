import { createAsyncThunk, createSlice, isAnyOf } from '@reduxjs/toolkit';

import { errorNotification } from '../../components/notifications';
import api from '../../libs/api';

const initialState = {
  purchaseOrders: {
    list: [],
    page: 1,
    query: { page: 1 },
    totalPages: 1,
    totalPurchaseOrders: 0,
  },
  purchaseOrder: { materials: [], products: [] },
  purchaseOrderIds: [],
  calendarEvents: [],
  files: [],
};

export const getPurchaseOrders = createAsyncThunk(
  'purchaseOrders/getPurchaseOrders',
  async (query, { getState }) => {
    const currentQuery = getState().purchaseOrders.purchaseOrders.query;

    const effectiveQuery = query || currentQuery;

    const { data } = await api.get('/purchase-orders', effectiveQuery);

    return { data, query: effectiveQuery };
  },
);

export const getPurchaseOrder = createAsyncThunk(
  'purchaseOrders/getPurchaseOrder',
  async id => {
    const { data } = await api.get(`/purchase-orders/${id}`);

    return data;
  },
);

export const deletePurchaseOrder = createAsyncThunk(
  'purchaseOrders/deletePurchaseOrder',
  async (id, { getState }) => {
    const userName = getState().loginInfo.user.name;

    await api.delete(`/purchase-orders/${id}`);

    return { id, userName };
  },
);

export const getPurchaseOrderIds = createAsyncThunk(
  'purchaseOrders/getPurchaseOrderIds',
  async query => {
    const { data } = await api.get('/purchase-orders/ids', query);

    return data;
  },
);

export const getPurchaseOrderFiles = createAsyncThunk(
  'purchaseOrders/getPurchaseOrderFiles',
  async purchaseOrderId => {
    const { data } = await api.get(`/purchase-orders/files/${purchaseOrderId}`);

    return data;
  },
);

export const deletePurchaseOrderFile = createAsyncThunk(
  'purchaseOrders/deletePurchaseOrderFiles',
  async ({ fileId, purchaseOrderId }) => {
    await api.delete(`/purchase-orders/files/${fileId}`);

    return { fileId, purchaseOrderId };
  },
);

export const addPurchaseOrderFiles = createAsyncThunk(
  'purchaseOrders/addPurchaseOrderFiles',
  async ({ purchaseOrderId, files }) => {
    if (!files || !files.length) {
      throw new Error('Please select files first');
    }

    const formData = new FormData();

    files.forEach((file, index) => {
      formData.append(`files[${index}]`, file);
    });

    const { data } = await api.post(
      `/purchase-orders/files/${purchaseOrderId}`,
      formData,
      {
        headers: {
          'content-type': 'multipart/form-data',
        },
      },
    );

    return { data, purchaseOrderId };
  },
);

export const getPOCalendarEvents = createAsyncThunk(
  'purchaseOrders/getPOCalendarEvents',
  async query => {
    const { data } = await api.get('/purchase-orders/calendar/pending', query);

    return data;
  },
);

export const updateRevisedDate = createAsyncThunk(
  'purchaseOrders/updateRevisedDate',
  async ({ id, type, revisedExpectedDate }) => {
    await api.patch(`/purchase-orders/calendar/revised-date/${id}`, {
      type,
      revisedExpectedDate,
    });

    return { id, type, revisedExpectedDate };
  },
);

const purchaseOrders = createSlice({
  name: 'purchaseOrders',

  initialState,

  reducers: {
    clearPurchaseOrders: draft => {
      draft.purchaseOrders = initialState.purchaseOrders;
    },

    clearPurchaseOrder: draft => {
      draft.purchaseOrder = initialState.purchaseOrder;
    },

    clearPurchaseOrderIds: draft => {
      draft.purchaseOrderIds = initialState.purchaseOrderIds;
    },

    clearPurchaseOrderFiles: draft => {
      draft.files = initialState.files;
    },

    clearPOCalendarEvents: draft => {
      draft.calendarEvents = initialState.calendarEvents;
    },
  },

  extraReducers: builder => {
    builder.addCase(getPurchaseOrders.fulfilled, (draft, { payload }) => {
      draft.purchaseOrders.list = payload.data.purchaseOrders;
      draft.purchaseOrders.page = payload.data.page;
      draft.purchaseOrders.query = payload.query;
      draft.purchaseOrders.totalPurchaseOrders =
        payload.data.totalPurchaseOrders;
      draft.purchaseOrders.totalPages = payload.data.totalPages;
    });

    builder.addCase(getPurchaseOrder.fulfilled, (draft, { payload }) => {
      draft.purchaseOrder = payload;
    });

    builder.addCase(deletePurchaseOrder.fulfilled, (draft, { payload }) => {
      const index = draft.purchaseOrders.list.findIndex(
        el => el.id === payload.id,
      );

      if (index > -1) {
        draft.purchaseOrders.list.splice(index, 1);
      }
    });

    builder.addCase(getPurchaseOrderIds.fulfilled, (draft, { payload }) => {
      draft.purchaseOrderIds = payload;
    });

    builder.addCase(getPOCalendarEvents.fulfilled, (draft, { payload }) => {
      draft.calendarEvents = payload;
    });

    builder.addCase(updateRevisedDate.fulfilled, (draft, { payload }) => {
      const calendarEvents = [...draft.calendarEvents];

      const selectedEventIndex = calendarEvents.findIndex(
        el => el.id === payload.id && el.type === payload.type,
      );

      if (selectedEventIndex > -1) {
        calendarEvents[selectedEventIndex] = {
          ...calendarEvents[selectedEventIndex],
          start: payload.revisedExpectedDate,
          end: payload.revisedExpectedDate,
          revisedExpectedDate: payload.revisedExpectedDate,
        };
      }

      draft.calendarEvents = calendarEvents;
    });

    builder.addCase(getPurchaseOrderFiles.fulfilled, (draft, { payload }) => {
      draft.files = payload;
    });

    builder.addCase(deletePurchaseOrderFile.fulfilled, (draft, { payload }) => {
      draft.files = draft.files.filter(file => file.id !== payload.fileId);

      const purchaseOrder = draft.purchaseOrders.list.find(
        el => el.id === payload.purchaseOrderId,
      );

      if (purchaseOrder) {
        purchaseOrder.noOfFiles = +purchaseOrder.noOfFiles - 1;
      }
    });

    builder.addCase(addPurchaseOrderFiles.fulfilled, (draft, { payload }) => {
      draft.files.push(...payload.data.uploaded);

      const purchaseOrder = draft.purchaseOrders.list.find(
        el => el.id === payload.purchaseOrderId,
      );

      if (purchaseOrder) {
        purchaseOrder.noOfFiles =
          +purchaseOrder.noOfFiles + payload.data.uploaded.length;
      }

      if (payload.data.failed.length) {
        errorNotification(
          new Error(`${payload.data.failed.join(', ')} failed to upload`),
        );
      }
    });

    builder.addMatcher(
      isAnyOf(
        getPurchaseOrders.rejected,
        getPurchaseOrder.rejected,
        deletePurchaseOrder.rejected,
        getPurchaseOrderIds.rejected,
        getPOCalendarEvents.rejected,
        getPurchaseOrderFiles.rejected,
        deletePurchaseOrderFile.rejected,
        addPurchaseOrderFiles.rejected,
        updateRevisedDate.rejected,
      ),
      (_Draft, { error }) => {
        errorNotification(error);
      },
    );
  },
});

export const {
  clearPurchaseOrders,
  clearPurchaseOrder,
  clearPurchaseOrderIds,
  clearPOCalendarEvents,
} = purchaseOrders.actions;

export default purchaseOrders.reducer;
