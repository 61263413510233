import { createAsyncThunk, createSlice, isAnyOf } from '@reduxjs/toolkit';

import { errorNotification } from '../../components/notifications';
import api from '../../libs/api';
import CONSTANTS from '../../libs/constants';

const initialState = {
  materials: {
    list: [],
    page: 1,
    query: { page: 1 },
    totalPages: 1,
    totalMaterials: 0,
  },
  material: {},
  materialIds: [],
  typedMaterialIds: {},
  coa: { tests: [] },
  ledger: [],
};

Object.values(CONSTANTS.material.types).forEach(type => {
  initialState.typedMaterialIds[type] = [];
});

export const getMaterials = createAsyncThunk(
  'materials/getMaterials',
  async (query, { getState }) => {
    const currentQuery = getState().materials.materials.query;

    const effectiveQuery = query || currentQuery;

    const { data } = await api.get('/materials', effectiveQuery);

    return { data, query: effectiveQuery };
  },
);

export const getMaterial = createAsyncThunk(
  'materials/getMaterial',
  async id => {
    const { data } = await api.get(`/materials/${id}`);

    return data;
  },
);

export const getMaterialLedger = createAsyncThunk(
  'materials/getMaterialLedger',
  async ({ id, consignorId, ...query }) => {
    const { data } = await api.get(
      `/materials/ledger/${id}/${consignorId}`,
      query,
    );

    return data;
  },
);

export const deleteMaterial = createAsyncThunk(
  'materials/deleteMaterial',
  async (id, { getState }) => {
    const userName = getState().loginInfo.user.name;

    await api.delete(`/materials/${id}`);

    return { id, userName };
  },
);

export const restoreMaterial = createAsyncThunk(
  'materials/restoreMaterial',
  async (id, { getState }) => {
    const userName = getState().loginInfo.user.name;

    await api.put(`/materials/${id}/restore`);

    return { id, userName };
  },
);

export const getMaterialIds = createAsyncThunk(
  'materials/getMaterialIds',
  async query => {
    const { data } = await api.get('/materials/ids', query);

    return data;
  },
);

export const getMaterialCOA = createAsyncThunk(
  'materials/getMaterialCOA',
  async id => {
    const { data } = await api.get(`/materials/${id}/coa`);

    return data;
  },
);

const materials = createSlice({
  name: 'materials',

  initialState,

  reducers: {
    clearMaterials: draft => {
      draft.materials = initialState.materials;
    },

    clearMaterial: draft => {
      draft.material = initialState.material;
    },

    clearMaterialIds: draft => {
      draft.materialIds = initialState.materialIds;
      draft.typedMaterialIds = initialState.typedMaterialIds;
    },

    clearMaterialCOA: draft => {
      draft.coa = initialState.coa;
    },

    clearMaterialLedger: draft => {
      draft.ledger = initialState.ledger;
    },
  },

  extraReducers: builder => {
    builder.addCase(getMaterials.fulfilled, (draft, { payload }) => {
      draft.materials.list = payload.data.materials;
      draft.materials.page = payload.data.page;
      draft.materials.query = payload.query;
      draft.materials.totalMaterials = payload.data.totalMaterials;
      draft.materials.totalPages = payload.data.totalPages;
    });

    builder.addCase(getMaterial.fulfilled, (draft, { payload }) => {
      draft.material = payload;
    });

    builder.addCase(deleteMaterial.fulfilled, (draft, { payload }) => {
      const material = draft.materials.list.find(el => el.id === payload.id);

      if (material) {
        material.deletedAt = new Date().toISOString();
        material.deletedBy = payload.userName;
      }
    });

    builder.addCase(restoreMaterial.fulfilled, (draft, { payload }) => {
      const material = draft.materials.list.find(el => el.id === payload.id);

      if (material) {
        material.deletedAt = null;
        material.deletedBy = null;
      }
    });

    builder.addCase(getMaterialIds.fulfilled, (draft, { payload }) => {
      draft.materialIds = payload;
      const typedMaterialIds = {};

      Object.values(CONSTANTS.material.types).forEach(type => {
        typedMaterialIds[type] = draft.materialIds.filter(
          el => el.type === type,
        );
      });

      draft.typedMaterialIds = typedMaterialIds;
    });

    builder.addCase(getMaterialCOA.fulfilled, (draft, { payload }) => {
      draft.coa = payload;
    });

    builder.addCase(getMaterialLedger.fulfilled, (draft, { payload }) => {
      draft.ledger = payload;
    });

    builder.addMatcher(
      isAnyOf(
        getMaterials.rejected,
        getMaterial.rejected,
        deleteMaterial.rejected,
        restoreMaterial.rejected,
        getMaterialIds.rejected,
        getMaterialCOA.rejected,
        getMaterialLedger.rejected,
      ),
      (_Draft, { error }) => {
        errorNotification(error);
      },
    );
  },
});

export const {
  clearMaterials,
  clearMaterial,
  clearMaterialIds,
  clearMaterialCOA,
  clearMaterialLedger,
} = materials.actions;

export default materials.reducer;
