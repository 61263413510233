import { configureStore } from '@reduxjs/toolkit';

// import { errorNotification } from '../components/notifications';

import reducer from './reducers';

const store = configureStore({
  reducer,
  devTools: true,
  // TODO later as it is not required for now
  // middleware: [
  //   ({ getState, dispatch }) =>
  //     next =>
  //     async action => {
  //       try {
  //         if (typeof action === 'function') {
  //           console.log(action);
  //           return await action(dispatch, getState);
  //         }
  //         next(action);
  //       } catch (error) {
  //         errorNotification(error);
  //         throw error;
  //       }
  //     },
  // ],
});

export default store;
