import { createAsyncThunk, createSlice, isAnyOf } from '@reduxjs/toolkit';

import { errorNotification } from '../../components/notifications';
import api from '../../libs/api';

const initialState = {
  contacts: [],
  contact: {},
  contactIds: [],
};

export const getContacts = createAsyncThunk(
  'contacts/getContacts',
  async () => {
    const { data } = await api.get('/contacts');

    return data;
  },
);

export const getContactIds = createAsyncThunk(
  'contacts/getContactIds',
  async () => {
    const { data } = await api.get('/contacts/ids');

    return data;
  },
);

export const getContact = createAsyncThunk('contacts/getContact', async id => {
  const { data } = await api.get(`/contacts/${id}`);

  return data;
});

export const deleteContact = createAsyncThunk(
  'contacts/deleteContact',
  async id => {
    await api.delete(`/contacts/${id}`);

    return id;
  },
);

export const addContact = createAsyncThunk(
  'contacts/addContact',
  async data => {
    const { data: contact } = await api.post('/contacts', data);

    return contact;
  },
);

export const editContact = createAsyncThunk(
  'contacts/editContact',
  async ({ id, data }) => {
    const { data: contact } = await api.put(`/contacts/${id}`, data);

    return contact;
  },
);

const contacts = createSlice({
  name: 'contacts',

  initialState,

  reducers: {
    clearContacts: draft => {
      draft.contacts = initialState.contacts;
    },

    clearContact: draft => {
      draft.contact = initialState.contact;
    },

    clearContactIds: draft => {
      draft.contactIds = initialState.contactIds;
    },
  },

  extraReducers: builder => {
    builder.addCase(getContacts.fulfilled, (draft, { payload }) => {
      draft.contacts = payload;
    });

    builder.addCase(getContact.fulfilled, (draft, { payload }) => {
      draft.contact = payload;
    });

    builder.addCase(deleteContact.fulfilled, (draft, { payload }) => {
      draft.contacts = draft.contacts.filter(el => el.id !== payload);
    });

    builder.addCase(addContact.fulfilled, (draft, { payload }) => {
      draft.contacts.push(payload);
    });

    builder.addCase(editContact.fulfilled, (draft, { payload }) => {
      const index = draft.contacts.findIndex(el => el.id === payload.id);

      draft.contacts[index] = payload;
    });

    builder.addCase(getContactIds.fulfilled, (draft, { payload }) => {
      draft.contactIds = payload;
    });

    builder.addMatcher(
      isAnyOf(
        getContacts.rejected,
        getContact.rejected,
        deleteContact.rejected,
        addContact.rejected,
        editContact.rejected,
        getContactIds.rejected,
      ),
      (_Draft, { error }) => {
        errorNotification(error);
      },
    );
  },
});

export const { clearContacts, clearContact, clearContactIds } =
  contacts.actions;

export default contacts.reducer;
