import { createAsyncThunk, createSlice, isAnyOf } from '@reduxjs/toolkit';

import { errorNotification } from '../../../components/notifications';
import api from '../../../libs/api';

const initialState = {
  origins: [],
  origin: {},
  originIds: [],
};

export const getOrigins = createAsyncThunk(
  'dropdowns/origins/getOrigins',
  async () => {
    const { data } = await api.get('/dropdowns/lead-origins');

    return data;
  },
);

export const getOriginIds = createAsyncThunk(
  'dropdowns/origins/getOriginIds',
  async () => {
    const { data } = await api.get('/dropdowns/lead-origins/ids');

    return data;
  },
);

export const getOrigin = createAsyncThunk(
  'dropdowns/origins/getOrigin',
  async id => {
    const { data } = await api.get(`/dropdowns/lead-origins/${id}`);

    return data;
  },
);

export const deleteOrigin = createAsyncThunk(
  'dropdowns/origins/deleteOrigin',
  async (id, { getState }) => {
    const userName = getState().loginInfo.user.name;

    await api.delete(`/dropdowns/lead-origins/${id}`);

    return { id, userName };
  },
);

export const restoreOrigin = createAsyncThunk(
  'dropdowns/origins/restoreOrigin',
  async id => {
    await api.put(`/dropdowns/lead-origins/restore/${id}`);

    return id;
  },
);

export const addOrigin = createAsyncThunk(
  'dropdowns/origins/addOrigin',
  async data => {
    const { data: origin } = await api.post('/dropdowns/lead-origins', data);

    return origin;
  },
);

export const editOrigin = createAsyncThunk(
  'dropdowns/origins/editOrigin',
  async ({ id, data }) => {
    const { data: origin } = await api.put(
      `/dropdowns/lead-origins/${id}`,
      data,
    );

    return origin;
  },
);

const origins = createSlice({
  name: 'origins',

  initialState,

  reducers: {
    clearOrigins: draft => {
      draft.origins = initialState.origins;
    },

    clearOrigin: draft => {
      draft.origin = initialState.origin;
    },

    clearOriginIds: draft => {
      draft.originIds = initialState.originIds;
    },
  },

  extraReducers: builder => {
    builder.addCase(getOrigins.fulfilled, (draft, { payload }) => {
      draft.origins = payload;
    });

    builder.addCase(getOrigin.fulfilled, (draft, { payload }) => {
      draft.origin = payload;
    });

    builder.addCase(deleteOrigin.fulfilled, (draft, { payload }) => {
      const index = draft.origins.findIndex(el => el.id === payload.id);

      draft.origins[index].deletedAt = new Date().toISOString();
      draft.origins[index].deletedBy = payload.userName;
    });

    builder.addCase(restoreOrigin.fulfilled, (draft, { payload }) => {
      const index = draft.origins.findIndex(el => el.id === payload);

      draft.origins[index].deletedAt = null;
      draft.origins[index].deletedBy = null;
    });

    builder.addCase(addOrigin.fulfilled, (draft, { payload }) => {
      draft.origins.push(payload);
    });

    builder.addCase(editOrigin.fulfilled, (draft, { payload }) => {
      const index = draft.origins.findIndex(el => el.id === payload.id);

      draft.origins[index] = payload;
    });

    builder.addCase(getOriginIds.fulfilled, (draft, { payload }) => {
      draft.originIds = payload;
    });

    builder.addMatcher(
      isAnyOf(
        getOrigins.rejected,
        getOrigin.rejected,
        deleteOrigin.rejected,
        restoreOrigin.rejected,
        addOrigin.rejected,
        editOrigin.rejected,
        getOriginIds.rejected,
      ),
      (_Draft, { error }) => {
        errorNotification(error);
      },
    );
  },
});

export const { clearOrigins, clearOrigin, clearOriginIds } = origins.actions;

export default origins.reducer;
