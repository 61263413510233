import { createAsyncThunk, createSlice, isAnyOf } from '@reduxjs/toolkit';

import { errorNotification } from '../../components/notifications';
import api from '../../libs/api';

const initialState = {
  kras: [],
  kra: {},
  tasks: [],
  task: {},
  extensions: [],
  files: [],
};

export const getChecklists = createAsyncThunk(
  'tasks/getChecklists',
  async () => {
    const { data } = await api.get('/tasks/kras');

    return data;
  },
);

export const getChecklist = createAsyncThunk('tasks/getChecklist', async id => {
  const { data } = await api.get(`/tasks/kras/${id}`);

  return data;
});

export const deleteChecklist = createAsyncThunk(
  'tasks/deleteChecklist',
  async id => {
    await api.delete(`/tasks/kras/${id}`);

    return id;
  },
);

export const addChecklist = createAsyncThunk(
  'tasks/addChecklist',
  async data => {
    const { data: kra } = await api.post('/tasks/kras', data);

    console.log(kra);

    return kra;
  },
);

export const editChecklist = createAsyncThunk(
  'tasks/editChecklist',
  async ({ id, data }) => {
    const { data: kra } = await api.put(`/tasks/kras/${id}`, data);

    return kra;
  },
);

export const getTaskList = createAsyncThunk(
  'tasks/getTaskList',
  async query => {
    const { data } = await api.get('/tasks', query);

    return data;
  },
);

export const getTask = createAsyncThunk('tasks/getTask', async id => {
  const { data } = await api.get(`/tasks/${id}`);

  return data;
});

export const getExtensionRequests = createAsyncThunk(
  'tasks/getExtensionRequests',
  async () => {
    const { data } = await api.get('/tasks/extensions');

    return data;
  },
);

export const getTaskFiles = createAsyncThunk(
  'tasks/getTaskFiles',
  async taskId => {
    const { data } = await api.get(`/tasks/files/${taskId}`);

    return data;
  },
);

export const deleteTaskFile = createAsyncThunk(
  'tasks/deleteTaskFiles',
  async ({ fileId, taskId }) => {
    await api.delete(`/tasks/files/${fileId}`);

    return { fileId, taskId };
  },
);

export const addTaskFiles = createAsyncThunk(
  'tasks/addTaskFiles',
  async ({ taskId, files }) => {
    if (!files || !files.length) {
      throw new Error('Please select files first');
    }

    const formData = new FormData();

    files.forEach((file, index) => {
      formData.append(`files[${index}]`, file);
    });

    const { data } = await api.post(`/tasks/files/${taskId}`, formData, {
      headers: {
        'content-type': 'multipart/form-data',
      },
    });

    return { data, taskId };
  },
);

const tasks = createSlice({
  name: 'tasks',

  initialState,

  reducers: {
    clearChecklists: draft => {
      draft.kras = initialState.kras;
    },

    clearChecklist: draft => {
      draft.kra = initialState.kra;
    },

    clearTaskList: draft => {
      draft.tasks = initialState.tasks;
    },

    clearTask: draft => {
      draft.task = initialState.task;
    },

    clearExtensionRequests: draft => {
      draft.extensions = initialState.extensions;
    },

    clearTaskFiles: draft => {
      draft.files = initialState.files;
    },
  },

  extraReducers: builder => {
    builder.addCase(getChecklists.fulfilled, (draft, { payload }) => {
      draft.kras = payload;
    });

    builder.addCase(getChecklist.fulfilled, (draft, { payload }) => {
      draft.kra = payload;
    });

    builder.addCase(deleteChecklist.fulfilled, (draft, { payload }) => {
      draft.tasks = draft.tasks.filter(el => el.id !== payload);
    });

    builder.addCase(addChecklist.fulfilled, (draft, { payload }) => {
      draft.tasks.push(payload);
    });

    builder.addCase(editChecklist.fulfilled, (draft, { payload }) => {
      const index = draft.tasks.findIndex(el => el.id === payload.id);

      draft.tasks[index] = payload;
    });

    builder.addCase(getTaskList.fulfilled, (draft, { payload }) => {
      draft.tasks = payload;
    });

    builder.addCase(getTask.fulfilled, (draft, { payload }) => {
      draft.task = payload;
    });

    builder.addCase(getExtensionRequests.fulfilled, (draft, { payload }) => {
      draft.extensions = payload;
    });

    builder.addCase(getTaskFiles.fulfilled, (draft, { payload }) => {
      draft.files = payload;
    });

    builder.addCase(deleteTaskFile.fulfilled, (draft, { payload }) => {
      draft.files = draft.files.filter(file => file.id !== payload.fileId);

      const task = draft.tasks.find(el => el.id === payload.taskId);

      if (task) {
        task.noOfFiles = +task.noOfFiles - 1;
      }
    });

    builder.addCase(addTaskFiles.fulfilled, (draft, { payload }) => {
      draft.files.push(...payload.data.uploaded);

      const task = draft.tasks.find(el => el.id === payload.taskId);

      if (task) {
        task.noOfFiles = +task.noOfFiles + payload.data.uploaded.length;
      }

      if (payload.data.failed.length) {
        errorNotification(
          new Error(`${payload.data.failed.join(', ')} failed to upload`),
        );
      }
    });

    builder.addMatcher(
      isAnyOf(
        getChecklists.rejected,
        getChecklist.rejected,
        deleteChecklist.rejected,
        addChecklist.rejected,
        editChecklist.rejected,
        getTaskList.rejected,
        getTask.rejected,
        getExtensionRequests.rejected,
        getTaskFiles.rejected,
        deleteTaskFile.rejected,
        addTaskFiles.rejected,
      ),
      (_Draft, { error }) => {
        errorNotification(error);
      },
    );
  },
});

export const {
  clearChecklists,
  clearChecklist,
  clearTask,
  clearTaskList,
  clearExtensionRequests,
  clearTaskFiles,
} = tasks.actions;

export default tasks.reducer;
