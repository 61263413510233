import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Layout } from 'antd';
import withStyles from 'react-jss';
import { useNavigate } from 'react-router-dom';

import Sidebar from './Sidebar';
import Navbar from './Navbar';
import styles from './styles';

const { Content } = Layout;

const Structure = props => {
  const { classes } = props;

  const navigate = useNavigate();

  const [Visible, SetVisible] = useState(false);

  return (
    <Layout className={classes.layout}>
      <Navbar visible={Visible} setVisible={SetVisible} navigate={navigate} />
      <Layout>
        <Sidebar
          visible={Visible}
          setVisible={SetVisible}
          navigate={navigate}
        />
        <Layout className={classes.content}>
          <Content>
            <div className={classes.contentContainer}>{props.children}</div>
          </Content>
        </Layout>
      </Layout>
    </Layout>
  );
};

Structure.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.array]),
  classes: PropTypes.object,
};

export default withStyles(styles)(Structure);
